(function(module) {
try {
  module = angular.module('app');
} catch (e) {
  module = angular.module('app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('common/personne-input.html',
    '<div class=has-feedback><input type=hidden name="{{ name }}Id" ng-disabled="! model.id" ng-attr-form="{{ form }}" data-mediatheque-default-value="{{ ::model.id }}" value="{{ model.id }}"> <input type=text name="{{ name }}Tmp" id="{{ id }}" class=form-control autocomplete=off placeholder="{{ placeholder }}" ng-readonly=disabled() data-ignorable-value ng-model=model ng-change=inputCtrl.onChange() typeahead="suggestion as suggestion.libelle for suggestion in inputCtrl.getSuggestions($viewValue)" typeahead-template-url=common/entity-suggest.html typeahead-show-on-click=true typeahead-wait-ms=300 typeahead-loading=inputCtrl.loading typeahead-on-select=inputCtrl.onChange() mediatheque-autofocus=applyFocus()> <span class="fa suggest-feedback" ng-class="{\'fa-caret-down\': !inputCtrl.loading, \'fa-gear\': inputCtrl.loading, \'text-danger\': !inputCtrl.hasValidValue() }"></span></div>');
}]);
})();
