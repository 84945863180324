(function(module) {
try {
  module = angular.module('app');
} catch (e) {
  module = angular.module('app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('common/reference-documentaire-field-input.html',
    '<div class=has-feedback><input type=hidden name="{{ name }}.referenceDocumentaireId" value="{{ model.referenceDocumentaireId }}" data-mediatheque-default-value="{{ ::model.referenceDocumentaireId }}" ng-attr-form="{{ form }}"> <input type=text name="{{ name }}.libelle" id="{{ id }}" class=form-control autocomplete=off ng-readonly=disabled() ng-attr-form="{{ form }}" placeholder="{{ placeholder }}" data-mediatheque-default-value="{{ ::model.libelle }}" ng-model=model ng-change=inputCtrl.onChange() typeahead="suggestion as suggestion.libelle for suggestion in inputCtrl.getSuggestions($viewValue)" typeahead-template-url=common/entity-suggest.html typeahead-on-select=inputCtrl.onChange() typeahead-show-on-click=true typeahead-wait-ms=300 typeahead-loading=inputCtrl.loading mediatheque-autofocus=applyFocus()> <span class="fa suggest-feedback" ng-class="{\'fa-caret-down\': !inputCtrl.loading, \'fa-gear\': inputCtrl.loading, \'text-danger\': !inputCtrl.hasValidValue() }"></span></div>');
}]);
})();
