angular.module('app')
  .directive('mediathequeConfirmUncheck', ConfirmUncheckDirective);

/*@ngInject*/
function ConfirmUncheckDirective($window) {
  return {
    restrict: 'A',
    link: function(scope, element, attrs) {
      element.on('click', function(event) {
        var checked = event.target.checked;
        var msg = attrs.ngConfirm;
        if(!msg){
          msg = "Voulez-vous vraiment décocher cette option ?"
        }
        if (!checked && !$window.confirm(msg)) {
          event.stopImmediatePropagation();
          event.preventDefault();
        }
      });
    }
  };
}
