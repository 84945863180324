angular.module('app')
  .directive('mediathequeConsultationOeuvreRepresentee', ConsultationOeuvreRepresenteeDirective);

function ConsultationOeuvreRepresenteeDirective() {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      editable: '&mediathequeEditable'
    },
    controller: ConsultationOeuvreRepresenteeController,
    controllerAs: 'consultationOeuvreRepresenteeCtrl',
    templateUrl: 'illustration/consultation-oeuvre-representee.html'
  };
}

/*@ngInject*/
function ConsultationOeuvreRepresenteeController($scope, $rootScope, ConsultationIllustrationService, $timeout) {
  var consultationOeuvreRepresenteeCtrl = this;

  consultationOeuvreRepresenteeCtrl.editable = $scope.editable();

  consultationOeuvreRepresenteeCtrl.onChange = onChange;

  initFromService();

  $scope.$on('illustration.changed', initFromService);

  function onChange() {
    $timeout(() => {
      var illustration = _.cloneDeep(consultationOeuvreRepresenteeCtrl.illustration);
      illustration.tags = _.map(illustration.tags, (tag) => {
        return _.isString(tag) ? tag : tag.libelle;
      });

      $rootScope.$emit('illustration.oeuvre-representee.updated', illustration);
    }, 0);
  }

  function initFromService() {
    consultationOeuvreRepresenteeCtrl.illustration = ConsultationIllustrationService.getIllustration();

    consultationOeuvreRepresenteeCtrl.illustration.tags = _.map(consultationOeuvreRepresenteeCtrl.illustration.tags, (tag) => {
      return { libelle: tag };
    });

    consultationOeuvreRepresenteeCtrl.auteursOeuvre = ConsultationIllustrationService.getAuteursOeuvre();
  }
}
