angular.module('app')
  .directive('mediathequeMultivaluedBiblioFileInput', MultivaluedBiblioFileInputDirective);

function MultivaluedBiblioFileInputDirective() {
  return {
    restrict: 'E',
    scope: {
      name: '@mediathequeName',
      id: '@mediathequeId',
      form: '@mediathequeForm',
      disabled: '&mediathequeDisabled',
      initialValues: '&mediathequeInitialValues',
      nbMax: '@mediathequeNbMax',
      sizeMax: '@mediathequeSizeMax'
    },
    replace: true,
    controller: MultivaluedBiblioFileInputController,
    controllerAs: 'inputsCtrl',
    templateUrl: 'common/multivalued-biblio-file-input.html'
  };
}

/*@ngInject*/
function MultivaluedBiblioFileInputController($scope) {
  var inputsCtrl = this;

  inputsCtrl.id = $scope.id;
  inputsCtrl.name = $scope.name;
  inputsCtrl.form = $scope.form;
  inputsCtrl.nbMax = $scope.nbMax;
  inputsCtrl.sizeMax = $scope.sizeMax;

  inputsCtrl.removeFile = removeFile;
  inputsCtrl.addFile = addFile;
  inputsCtrl.isDisabled = $scope.disabled;

  init();

  // TODO: Ajouter un watcher sur $scope.model (couplé à un $timeout pour s'assurer que le scope est bien à jour)
  // si le model initial est modifié par le composant parent

  function addFile() {
    if (inputsCtrl.files.length < inputsCtrl.nbMax) {
      inputsCtrl.files.push({ name: '' });
      inputsCtrl.dirty = true;
    }
  }

  function removeFile(fileToRemove) {
    inputsCtrl.files = _.without(inputsCtrl.files, fileToRemove);

    // Il faut au moins un champs affiché
    if (inputsCtrl.files.length === 0) {
      inputsCtrl.files = [{ name: '' }];
    }
    inputsCtrl.dirty = true;
  }

  function init() {
    inputsCtrl.files = ($scope.initialValues() && $scope.initialValues().length !== 0) ? $scope.initialValues() : [{ name: '' }];
    inputsCtrl.dirty = false;
  }
}
