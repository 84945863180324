angular.module('app')
  .directive('mediathequeMultivaluedContributionBiblioInput', MultivaluedContributionBiblioInputDirective);

function MultivaluedContributionBiblioInputDirective() {
  return {
    restrict: 'E',
    scope: {
      name: '@mediathequeName',
      id: '@mediathequeId',
      form: '@mediathequeForm',
      disabled: '&mediathequeDisabled',
      initialValues: '&mediathequeInitialValues'
    },
    replace: true,
    controller: MultivaluedContributionBiblioInputController,
    controllerAs: 'inputsCtrl',
    templateUrl: 'common/multivalued-contribution-biblio-input.html'
  };
}

/*@ngInject*/
function MultivaluedContributionBiblioInputController($scope) {
  var inputsCtrl = this;

  inputsCtrl.addContribution = addContribution;
  inputsCtrl.removeContribution = removeContribution;

  init();

  // TODO: Ajouter un watcher sur $scope.model (couplé à un $timeout pour s'assurer que le scope est bien à jour)
  // si le model initial est modifié par le composant parent

  function addContribution() {
    inputsCtrl.contributions.push({ libelle: "" });
    inputsCtrl.dirty = true;
    inputsCtrl.newlyAdded = true;
  }

  function removeContribution(contributionToRemove) {
    inputsCtrl.contributions = _.without(inputsCtrl.contributions, contributionToRemove);

    // Il faut au moins un champs affiché
    if (inputsCtrl.contributions.length === 0) {
      inputsCtrl.contributions = [{ role: { libelle: ""}, auteurId: null }];
    }
    inputsCtrl.dirty = true;
    inputsCtrl.newlyAdded = false;
  }

  function init() {
    inputsCtrl.contributions = ($scope.initialValues() && $scope.initialValues().length !== 0) ? $scope.initialValues() : [{ role: { libelle: ""}, auteurId: null }];
    inputsCtrl.dirty = false;
    inputsCtrl.newlyAdded = false;
  }
}
