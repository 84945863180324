angular.module('app')
  .directive('mediathequeModificationEnMasse', ModificationEnMasseDirective);

function ModificationEnMasseDirective() {
  return {
    restrict: 'E',
    replace: true,
    controller: ModificationEnMasseController,
    controllerAs: 'modificationEnMasseCtrl',
    templateUrl: 'common/modification-en-masse.html'
  };
}

/*@ngInject*/
function ModificationEnMasseController($scope, ModificationEnMasseModalService) {
  var modificationEnMasseCtrl = this;

  modificationEnMasseCtrl.displayRechercherRemplacerForm = displayRechercherRemplacerForm;
  modificationEnMasseCtrl.displayValoriserForm = displayValoriserForm;

  function displayRechercherRemplacerForm() {
    ModificationEnMasseModalService.openRechercherRemplacerModal();
  }

  function displayValoriserForm() {
    ModificationEnMasseModalService.openValoriserModal();
  }
}
