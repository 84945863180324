angular.module('app')
  .directive('mediathequeTextInput', TextInputDirective);

function TextInputDirective() {
  return {
    restrict: 'E',
    scope: {
      name: '@mediathequeName',
      id: '@mediathequeId',
      form: '@mediathequeForm',
      inputClass: '@mediathequeInputClass',
      disabled: '&mediathequeDisabled',
      placeholder: '@mediathequePlaceholder',
      fieldPath: '@mediathequeFieldPath',
      entityType: '@mediathequeEntityType',
      value: '@mediathequeValue',
      model: '=ngModel',
      onChange: '&mediathequeOnChange',
      applyFocus: '&mediathequeApplyFocus'
    },
    replace: true,
    controller: TextInputController,
    controllerAs: 'inputCtrl',
    templateUrl: 'common/text-input.html'
  };
}

/*@ngInject*/
function TextInputController($scope, StringFromPathSuggest) {
  var inputCtrl = this;

  inputCtrl.getSuggestions = getSuggestions;
  inputCtrl.withSuggestion = withSuggestion;
  inputCtrl.onChange = $scope.onChange;

  init();

  // TODO: Ajouter un watcher sur $scope.model (couplé à un $timeout pour s'assurer que le scope est bien à jour)
  // si le model initial est modifié par le composant parent

  inputCtrl.loading = false;
  function getSuggestions(currentValue) {
    if (!withSuggestion() || $scope.disabled()) {
      return;
    }
    inputCtrl.loading = true;
    return StringFromPathSuggest.search(currentValue, $scope.entityType, $scope.fieldPath)
      .then((result) => {
        inputCtrl.loading = false;
        return result.data;
      });
  }
  function withSuggestion() {
    return $scope.fieldPath && $scope.entityType;
  }

  function init() {
    if ($scope.model) {
      return;
    }
    $scope.model = $scope.value;
  }
}
