(function(module) {
try {
  module = angular.module('app');
} catch (e) {
  module = angular.module('app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('common/file-input.html',
    '<div><input type=hidden name="{{ name }}" value="{{ inputCtrl.resourceId }}" ng-attr-form="{{ ::form }}" data-mediatheque-default-value="{{ ::initialValue }}"><div class=btn-group><button type=button class="btn btn-xs btn-default {{ btnSelectClass }}" ngf-select ng-disabled="disabled() || inputCtrl.loading" ngf-multiple=false ngf-change="inputCtrl.upload($files, $file, $event, $rejectedFiles)" title="Charger un autre fichier"><span class="fa fa-upload"></span></button> <button type=button class="btn btn-xs btn-default {{ btnRemoveClass }}" ng-show="inputCtrl.hasImage() && !disabled()" ng-click=inputCtrl.remove() title=Supprimer><span class="fa fa-times-circle"></span></button> <button type=button class="btn btn-xs btn-default" ng-disasbled=inputCtrl.loading ng-show=inputCtrl.canGenerateFormats() ng-click=inputCtrl.generate() title="{{ ::generateTooltip }}"><span class="fa fa-gears"></span></button> <button type=button class="btn btn-xs btn-default {{ btnAbortClass }}" ng-show=inputCtrl.loading ng-click=inputCtrl.abort() title=Annuler><span class="fa fa-fw fa-circle-o-notch fa-spin"></span><span class="fa fa-fw fa-times text-warning"></span></button></div></div>');
}]);
})();
