angular.module('app')
  .directive('mediathequeLockStatus', LockStatusDirective);

function LockStatusDirective() {
  return {
    restrict: 'E',
    scope: {
      status: '@mediathequeStatus'
    },
    replace: true,
    templateUrl: 'common/lock-status.html'
  };
}
