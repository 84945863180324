(function(module) {
try {
  module = angular.module('app');
} catch (e) {
  module = angular.module('app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('common/multivalued-lien-input.html',
    '<div class=multivalued-input><input type=hidden data-mediatheque-default-value=false value="{{ inputsCtrl.dirty }}" ng-attr-form="{{ form }}"><div ng-repeat="lien in inputsCtrl.liens track by $index"><div class=row--flex><mediatheque-lien-input class=col-sm-10 mediatheque-id="{{ inputsCtrl.id }}" mediatheque-name="{{ inputsCtrl.name }}" mediatheque-form="{{ inputsCtrl.form }}" mediatheque-disabled=inputsCtrl.isDisabled() mediatheque-initial-value="{{ ::lien }}" mediatheque-apply-focus="$last && inputsCtrl.newlyAdded"></mediatheque-lien-input><div ng-if="! inputsCtrl.isDisabled()"><button type=button class="btn btn-transparent btn-input-actions" ng-click=inputsCtrl.removeLien(lien) title="Supprimer un lien"><span class="fa fa-times-circle"></span></button></div><div ng-if="! inputsCtrl.isDisabled() && $last"><button type=button class="btn btn-transparent btn-input-actions" ng-click=inputsCtrl.addLien() title="Ajouter un lien"><span class="fa fa-plus"></span></button></div></div></div></div>');
}]);
})();
