angular.module('app')
  .directive('mediathequeConsultationRefdocReprise', ConsultationRefdocRepriseDirective);

function ConsultationRefdocRepriseDirective() {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      refdoc: '&mediathequeRefdocReprise',
      disabled: '&mediathequeDisabled'
    },
    controller: ConsultationRefdocRepriseController,
    controllerAs: 'consultationRefdocRepriseCtrl',
    templateUrl: 'illustration/consultation-refdoc-reprise.html'
  };
}

/*@ngInject*/
function ConsultationRefdocRepriseController($scope, $window) {
  var consultationRefdocRepriseCtrl = this;

  consultationRefdocRepriseCtrl.dirty = false;
  consultationRefdocRepriseCtrl.disabled = $scope.disabled();
  consultationRefdocRepriseCtrl.refdoc = $scope.refdoc();
  consultationRefdocRepriseCtrl.removeRefdoc = removeRefdoc;

  function removeRefdoc() {
    if ($window.confirm("Voulez-vous vraiment effectuer cette opération ?")) {
      consultationRefdocRepriseCtrl.refdoc = null;
      consultationRefdocRepriseCtrl.dirty = true;
    }
  }
}
