(function(module) {
try {
  module = angular.module('app');
} catch (e) {
  module = angular.module('app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('common/vignette-from-id.html',
    '<div><img ng-src="{{ ::vignetteUrl }}" title="{{ ::titre }}" class=vignette ng-class=styles() ng-if=vignetteUrl> <span class="vignette-empty text-center fa {{ ::emptyIcon }}" ng-class=styles() ng-if=!vignetteUrl title="{{ ::titre }}"></span></div>');
}]);
})();
