angular.module('app')
  .directive('mediathequeModificationEnMasseValue', ModificationEnMasseValueDirective);

function ModificationEnMasseValueDirective() {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      id: '@mediathequeId',
      name: '@mediathequeName',
      champ: '&mediathequeChamp',
      model: '=ngModel'
    },
    controller: ModificationEnMasseValueController,
    controllerAs: 'modificationEnMasseValueCtrl',
    templateUrl: 'common/modification-en-masse-value.html'
  };
}

/*@ngInject*/
function ModificationEnMasseValueController($scope, $timeout) {
  var modificationEnMasseValueCtrl = this;

  modificationEnMasseValueCtrl.id = $scope.id;
  modificationEnMasseValueCtrl.name = $scope.name;

  modificationEnMasseValueCtrl.isDateField = isDateField;
  modificationEnMasseValueCtrl.isStringField = isStringField;
  modificationEnMasseValueCtrl.isStringWithSuggestField = isStringWithSuggestField;
  modificationEnMasseValueCtrl.isIntegerField = isIntegerField;
  modificationEnMasseValueCtrl.isBooleanField = isBooleanField;
  modificationEnMasseValueCtrl.isThesaurusField = isThesaurusField;
  modificationEnMasseValueCtrl.isAdresseField = isAdresseField;
  modificationEnMasseValueCtrl.isLieuDitField = isLieuDitField;
  modificationEnMasseValueCtrl.isVersementBaseNationalField = isVersementBaseNationalField;
  modificationEnMasseValueCtrl.isDossierField = isDossierField;
  modificationEnMasseValueCtrl.isCadreEtudeAffixeField = isCadreEtudeAffixeField;
  modificationEnMasseValueCtrl.onChange = onChange;

  $scope.$watch(() => {
    // On ne watch pas que sur le champ car la référence de l'objet peut ne pas être changé ce qui ne provoquera pas l'événement.
    var champ = $scope.champ();
    return champ && champ.nomChamp;
  }, (nomChamp) => {
    if (!nomChamp) {
      return;
    }

    modificationEnMasseValueCtrl.champ = $scope.champ();
    modificationEnMasseValueCtrl.rechercheValeur = null;
  });

  function isDateField() {
    switch (modificationEnMasseValueCtrl.champ.typeChamp) {
      case 'Date':
      case 'DateInSubs':
        return true;
    }
    return false;
  }

  function isStringField() {
    switch (modificationEnMasseValueCtrl.champ.typeChamp) {
      case 'String':
      case 'Strings':
        return true;
    }
    return false;
  }

  function isStringWithSuggestField() {
    switch (modificationEnMasseValueCtrl.champ.typeChamp) {
      case 'StringWithSuggest':
      case 'StringsWithSuggest':
      case 'StringWithSuggestInSubs':
        return true;
    }
    return false;
  }

  function isIntegerField() {
    return modificationEnMasseValueCtrl.champ.typeChamp === 'Integer';
  }

  function isBooleanField() {
    return modificationEnMasseValueCtrl.champ.typeChamp === 'Boolean';
  }

  function isThesaurusField() {
    switch (modificationEnMasseValueCtrl.champ.typeChamp) {
      case 'ThesaurusField':
      case 'ThesaurusFields':
      case 'ThesaurusFieldInSubs':
      case 'Commune':
        return true;
    }
    return false;
  }

  function isAdresseField() {
    return modificationEnMasseValueCtrl.champ.typeChamp === 'Adresse';
  }

  function isLieuDitField() {
    return modificationEnMasseValueCtrl.champ.typeChamp === 'LieuDit';
  }

  function isVersementBaseNationalField() {
    return modificationEnMasseValueCtrl.champ.typeChamp === 'VersementBaseNational';
  }

  function isDossierField() {
    return modificationEnMasseValueCtrl.champ.typeChamp === 'Dossiers';
  }

  function isCadreEtudeAffixeField() {
    return modificationEnMasseValueCtrl.champ.typeChamp === 'CadreEtudeAffixe';
  }

  function onChange() {
    if (!modificationEnMasseValueCtrl.champ || !modificationEnMasseValueCtrl.champ.nomChamp) {
      return;
    }

    $timeout(() => {
      switch (modificationEnMasseValueCtrl.champ.typeChamp) {
        case 'String':
        case 'Strings':
        case 'StringWithSuggest':
        case 'StringsWithSuggest':
        case 'StringWithSuggestInSubs':
          var stringValueUn;
          if (_.isObject(modificationEnMasseValueCtrl.rechercheValeur)) {
            stringValueUn = modificationEnMasseValueCtrl.rechercheValeur.label;
          } else {
            stringValueUn = modificationEnMasseValueCtrl.rechercheValeur;
          }
          _.assign($scope.model, { stringValueUn: stringValueUn || '' });
          break;
        case 'Date':
        case 'DateInSubs':
          let dateValueUn;
          if (modificationEnMasseValueCtrl.rechercheValeur != null) {
            dateValueUn = modificationEnMasseValueCtrl.rechercheValeur.toLocaleDateString();
          }
          _.assign($scope.model, {dateValueUn: dateValueUn || ''});
          break;
        case 'Integer':
          _.assign($scope.model, { integerValue: modificationEnMasseValueCtrl.rechercheValeur });
          break;
        case 'Boolean':
          _.assign($scope.model, { booleanValue: modificationEnMasseValueCtrl.rechercheValeur });
          break;
        case 'ThesaurusField':
        case 'ThesaurusFields':
        case 'ThesaurusFieldInSubs':
        case 'Commune':
          _.assign($scope.model, { thesaurusFieldValueUn: generateThesaurusFieldValue(modificationEnMasseValueCtrl.rechercheValeur) });
          break;
        case 'Adresse':
          _.assign($scope.model, {
            thesaurusFieldValueUn: generateThesaurusFieldValue(modificationEnMasseValueCtrl.rechercheValeur.nom),
            thesaurusFieldValueDeux: generateThesaurusFieldValue(modificationEnMasseValueCtrl.rechercheValeur.typeVoie),
            stringValueUn: modificationEnMasseValueCtrl.rechercheValeur.numero,
          });
          break;
        case 'LieuDit':
          _.assign($scope.model, {
            thesaurusFieldValueUn: generateThesaurusFieldValue(modificationEnMasseValueCtrl.rechercheValeur.nom),
            thesaurusFieldValueDeux: generateThesaurusFieldValue(modificationEnMasseValueCtrl.rechercheValeur.precision),
          });
          break;
        case 'VersementBaseNational':
          _.assign($scope.model, {
            stringValueUn: modificationEnMasseValueCtrl.rechercheValeur.versementBaseNationale,
            dateValueUn: modificationEnMasseValueCtrl.rechercheValeur.dateVersement &&
              modificationEnMasseValueCtrl.rechercheValeur.dateVersement.toLocaleDateString(),
          });
          break;
        case 'Dossiers':
          _.assign($scope.model, {
            dossierFieldValueUn: modificationEnMasseValueCtrl.rechercheValeur.id
          });
          break;
        case 'CadreEtudeAffixe':
          _.assign($scope.model, {
            thesaurusFieldValueUn: generateThesaurusFieldValue(modificationEnMasseValueCtrl.rechercheValeur.type),
            thesaurusFieldValueDeux: generateThesaurusFieldValue(modificationEnMasseValueCtrl.rechercheValeur.affixe),
          });
          break;
        default:
          throw new Error(`Type de champs '${ modificationEnMasseValueCtrl.champ.typeChamp }' non supporté.`);
      }
    }, 0);
  }

  function generateThesaurusFieldValue(thesaurusField) {
    var thesaurusId;
    var libelle;
    if (_.isObject(thesaurusField)) {
      thesaurusId = thesaurusField.libelle && thesaurusField.thesaurusId;
      libelle = thesaurusField.libelle;
    } else {
      libelle = thesaurusField;
    }
    return { thesaurusId, libelle };
  }
}
