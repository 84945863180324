angular.module('app')
  .factory('DossiersRattachesService', DossiersRattachesService);

/*@ngInject*/
function DossiersRattachesService($rootScope, $http, XWwwFormUrlEncoded, DossierApiConfiguration, ConsultationIllustrationService, initData) {
  var dossiers = initData.initialDossiersRattaches;
  var illustration = ConsultationIllustrationService.getIllustration();

  $rootScope.$on('illustration.changed', () => illustration = ConsultationIllustrationService.getIllustration());

  return { getDossiers, rattacher, lockAndGet, edit, remove, cancel };

  function getDossiers() {
    return _.cloneDeep(dossiers);
  }

  function rattacher(illustrationId, dossierId, updateIllustration) {
    return $http({
      cache: false,
      method: 'POST',
      url: DossierApiConfiguration.rattacherUri,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      transformRequest: XWwwFormUrlEncoded.transform,
      data: {
        'illustrationId': illustrationId,
        'dossierId': dossierId,
        'legendeGenerique': illustration.mediathequeIllustration.legendeGenerique
      }
    }).then((response) => {
      dossiers.push(response.data);

      if (updateIllustration) {
        $rootScope.$emit('illustration.localisation.updateFromDossier', response.data);
      }

      $rootScope.$broadcast('illustration.dossier.changed');
    }, (error) => {
      throw error.data.model;
    });
  }

  function lockAndGet(dossierId, illustrationId) {
    return $http({
      cache: false,
      method: 'POST',
      url: DossierApiConfiguration.lockAndGetUri,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      transformRequest: XWwwFormUrlEncoded.transform,
      data: { dossierId, illustrationId }
    }).then((response) => {
      return response.data;
    }, (error) => {
      throw error.data.model;
    });
  }

  function edit(dossier, illustrationId) {
    return $http({
      cache: false,
      method: 'POST',
      url: DossierApiConfiguration.saveUri,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      transformRequest: XWwwFormUrlEncoded.transform,
      data: {
        illustrationId: illustrationId,
        dossierId: dossier.id,
        legende: dossier.legende,
        significatif: dossier.significatif,
        version: dossier.version
      }
    }).then((response) => {
      dossiers = _.map(dossiers, (oldDossier) => {
        if (oldDossier.id !== dossier.id) {
          return oldDossier;
        }
        return response.data;
      });

      $rootScope.$broadcast('illustration.dossier.changed');
    }, (error) => {
      throw error.data.model;
    });
  }

  function remove(dossierId, illustrationId, version) {
    return $http({
      cache: false,
      method: 'POST',
      url: DossierApiConfiguration.removeUri,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      transformRequest: XWwwFormUrlEncoded.transform,
      data: { dossierId, illustrationId, version }
    }).then((response) => {
      dossiers = _.reject(dossiers, { id: dossierId });

      $rootScope.$broadcast('illustration.dossier.changed');
    }, (error) => {
      throw error.data.model;
    });
  }

  function cancel(dossierId) {
    return $http({
      cache: false,
      method: 'POST',
      url: DossierApiConfiguration.cancelUri,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      transformRequest: XWwwFormUrlEncoded.transform,
      data: { dossierId }
    }).then(null, (error) => {
      throw error.data.model;
    });
  }
}
