angular.module('app')
  .directive('mediathequeThesaurusInput', ThesaurusInputDirective);

function ThesaurusInputDirective() {
  return {
    restrict: 'E',
    scope: {
      name: '@mediathequeName',
      id: '@mediathequeId',
      form: '@mediathequeForm',
      disabled: '&mediathequeDisabled',
      model: '=ngModel',
      onChange: '&mediathequeOnChange',
      placeholder: '@mediathequePlaceholder',
      initialValue: '&mediathequeInitialValue',
      typesThesaurus: '&mediathequeTypesThesaurus',
      applyFocus: '&mediathequeApplyFocus',
      disableSuggest: '&mediathequeDisableSuggest',
      idRequired: '&mediathequeIdRequired'
    },
    replace: true,
    controller: ThesaurusInputController,
    controllerAs: 'inputCtrl',
    templateUrl: 'common/thesaurus-input.html'
  };
}

/*@ngInject*/
function ThesaurusInputController($scope, ThesaurusSuggest, $timeout) {
  var inputCtrl = this;

  inputCtrl.getSuggestions = getSuggestions;
  inputCtrl.hasValidValue = hasValidValue;
  inputCtrl.onChange = onChange;

  init();

  $scope.$watch(() => $scope.model, () => $timeout(init, 0));

  function init() {
    if ($scope.model) {
      inputCtrl.model = $scope.model;
      return;
    }
    // { libelle: "" } par défaut pour satisfaire UiBootstrap#Select
    inputCtrl.model = $scope.model = $scope.initialValue() || { libelle: "" };
  }

  function hasValidValue() {
    if (inputCtrl.model === '') {
      return true;
    }
    if (inputCtrl.model.thesaurusId) {
      return true;
    }
    return inputCtrl.model.libelle === '' || inputCtrl.model.libelle === null;
  }

  inputCtrl.loading = false;
  function getSuggestions(currentValue) {
    if ($scope.disabled()) {
      return;
    }
    inputCtrl.loading = true;
    return ThesaurusSuggest.search(currentValue, $scope.typesThesaurus())
      .then((result) => {
        inputCtrl.loading = false;
        return result.data;
      });
  }

  function onChange() {
    _.assign($scope.model, _.isString(inputCtrl.model) ? { libelle: inputCtrl.model, thesaurusId: undefined, code: undefined } : inputCtrl.model);

    $scope.onChange();
  }
}
