angular.module('app')
  .directive('mediathequeExpertSearchOptions', ExpertSearchOptionsDirective);

function ExpertSearchOptionsDirective() {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      currentSearch: '&mediathequeCurrentSearch'
    },
    controller: ExpertSearchOptionsController,
    controllerAs: 'expertSearchOptionsCtrl',
    templateUrl: 'common/expert-search-options.html'
  };
}

/*@ngInject*/
function ExpertSearchOptionsController($scope, $rootScope, $filter, $timeout, initData, StringComparator) {
  const collator = new Intl.Collator('fr');
  var expertSearchOptionsCtrl = this;

  expertSearchOptionsCtrl.champTri = { libelle: '' };
  expertSearchOptionsCtrl.ordreTri = null;
  expertSearchOptionsCtrl.visibleColumns = [];
  expertSearchOptionsCtrl.currentNomChamps = [];
  expertSearchOptionsCtrl.temporaryChamps = null;

  $scope.$watch($scope.currentSearch, (value) => {
    if (!value) {
      return;
    }

    if (value.champTri) {
      expertSearchOptionsCtrl.champTri = _.clone(_.find(initData.definitionRechercheExperte.champs, { nomChamp:  value.champTri }));
      expertSearchOptionsCtrl.champTri.libelle = $filter('messageFormat')(expertSearchOptionsCtrl.champTri.libelleChamp, 'field');
    } else {
      expertSearchOptionsCtrl.champTri = { libelle: '' };
    }

    expertSearchOptionsCtrl.ordreTri = value.ordreTri;
    expertSearchOptionsCtrl.currentNomChamps = value.champs;
    expertSearchOptionsCtrl.visibleColumns = expertSearchOptionsCtrl.temporaryChamps || _.map(value.champs, (nomChamp) => {
      var champ = _.find(initData.definitionRechercheExperte.champs, { nomChamp });
      return _.merge(champ, { libelle: $filter('messageFormat')(champ.libelleChamp, 'field') });
    });
  });

  expertSearchOptionsCtrl.getChampsTriables = _.memoize(getChampsTriables);
  expertSearchOptionsCtrl.getChampsAffichables = _.memoize(getChampsAffichables, ($viewValue) => {
    return JSON.stringify({
      viewValue: $viewValue,
      visibleColumns: expertSearchOptionsCtrl.visibleColumns
    });
  });
  expertSearchOptionsCtrl.updateChampTri = updateChampTri;
  expertSearchOptionsCtrl.updateChampsAffichables = updateChampsAffichables;

  expertSearchOptionsCtrl.selectAll = selectAll;
  expertSearchOptionsCtrl.unselectAll = unselectAll;

  function getChampsTriables($viewValue) {
    return _.chain(initData.definitionRechercheExperte.champs)
      .filter('triable')
      .map((champ) => {
        return _.merge(champ, { libelle: $filter('messageFormat')(champ.libelleChamp, 'field') });
      })
      .filter((champ) => {
        return StringComparator.startsWith(champ.libelle, ($viewValue || ''));
      })
      .value()
      // sort() n'est pas fonctionnel car il ne prend pas en compte les accents.
      // en utilisant le Collator pour la langue française, on s'assure que l'ordre naturel est conservé
      .sort((c1, c2) => {
        if (c1.groupe != c2.groupe) {
          return c1.groupe - c2.groupe;
        }
        return collator.compare(c1.libelle, c2.libelle);
      });
  }

  function selectAll() {
    var visibleColumnNames = _.map(expertSearchOptionsCtrl.visibleColumns, 'nomChamp');
    // Calcul des colonnes à ajouter à la liste des champs à afficher
    var columnsToAdd = _.chain(initData.definitionRechercheExperte.champs)
      .filter('affichable')
      .reject((champ) => {
        return _.includes(visibleColumnNames, champ.nomChamp);
      })
      .map((champ) => {
        return _.merge(champ, { libelle: $filter('messageFormat')(champ.libelleChamp, 'field') });
      })
      .value()
      // sort() n'est pas fonctionnel car il ne prend pas en compte les accents.
      // en utilisant le Collator pour la langue française, on s'assure que l'ordre naturel est conservé
      .sort((c1, c2) => {
        if (c1.groupe != c2.groupe) {
          return c1.groupe - c2.groupe;
        }
        return collator.compare(c1.libelle, c2.libelle);
      });
    expertSearchOptionsCtrl.visibleColumns = _.filter(expertSearchOptionsCtrl.visibleColumns, 'nomChamp').concat(columnsToAdd);
    updateChampsAffichables();
  }

  function unselectAll() {
    expertSearchOptionsCtrl.visibleColumns = [];
    updateChampsAffichables();
  }

  function getChampsAffichables($viewValue) {
    var visibleColumnNames = _.map(expertSearchOptionsCtrl.visibleColumns, 'nomChamp');

    return _.chain(initData.definitionRechercheExperte.champs)
      .filter('affichable')
      .reject((champ) => {
        return _.includes(visibleColumnNames, champ.nomChamp);
      })
      .map((champ) => {
        return _.merge(champ, { libelle: $filter('messageFormat')(champ.libelleChamp, 'field') });
      })
      .filter((champ) => {
        return StringComparator.startsWith(champ.libelle, ($viewValue || ''));
      })
      .value()
      // sort() n'est pas fonctionnel car il ne prend pas en compte les accents.
      // en utilisant le Collator pour la langue française, on s'assure que l'ordre naturel est conservé
      .sort((c1, c2) => {
        if (c1.groupe != c2.groupe) {
          return c1.groupe - c2.groupe;
        }
        return collator.compare(c1.libelle, c2.libelle);
      });
  }

  function updateChampTri() {
    $timeout(() => {
      $rootScope.$emit('expert-search-tri-updated', expertSearchOptionsCtrl.champTri && expertSearchOptionsCtrl.champTri.nomChamp,
          expertSearchOptionsCtrl.ordreTri);
    }, 0);
  }

  function updateChampsAffichables() {
    $timeout(() => {
      var newNomChamps = _.chain(expertSearchOptionsCtrl.visibleColumns)
        .map('nomChamp')
        .filter()
        .value();
      var oldNomChamps = expertSearchOptionsCtrl.currentNomChamps;
      if (_.difference(newNomChamps, oldNomChamps).length === 0 && _.difference(oldNomChamps, newNomChamps).length === 0) {
        return;
      }
      expertSearchOptionsCtrl.temporaryChamps = expertSearchOptionsCtrl.visibleColumns;

      $rootScope.$emit('expert-search-update-champs-affichables', expertSearchOptionsCtrl.visibleColumns);
    }, 0);
  }
}
