angular.module('app')
  .directive('mediathequeCadastreInput', CadastreInputDirective);

function CadastreInputDirective() {
  return {
    restrict: 'E',
    scope: {
      name: '@mediathequeName',
      id: '@mediathequeId',
      form: '@mediathequeForm',
      disabled: '&mediathequeDisabled',
      model: '=ngModel',
      onChange: '&mediathequeOnChange',
      initialValue: '&mediathequeInitialValue',
      applyFocus: '&mediathequeApplyFocus'
    },
    replace: true,
    controller: CadastreInputController,
    controllerAs: 'inputCtrl',
    templateUrl: 'common/cadastre-input.html'
  };
}

/*@ngInject*/
function CadastreInputController($scope) {
  var inputCtrl = this;

  inputCtrl.onChange = $scope.onChange;

  init();

  // TODO: Ajouter un watcher sur $scope.model (couplé à un $timeout pour s'assurer que le scope est bien à jour)
  // si le model initial est modifié par le composant parent

  function init() {
    if ($scope.model) {
      inputCtrl.cadastre = $scope.model;
      return;
    }

    inputCtrl.cadastre = $scope.initialValue() || { annee: "", section: "", parcelle: "", commentaire: ""};
  }
}
