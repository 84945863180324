(function(module) {
try {
  module = angular.module('app');
} catch (e) {
  module = angular.module('app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('common/vignette-from-lot.html',
    '<div class=vignette-lot><mediatheque-vignette-from-id ng-repeat="resourceUrl in vignetteFromLotCtrl.getResourceUrls() track by $index" data-mediatheque-vignette-url="{{ ::resourceUrl }}" data-mediatheque-titre="{{ ::titre }}" data-mediatheque-styles=styles() data-mediatheque-empty-icon="{{ ::emptyIcon }}"></mediatheque-vignette-from-id></div>');
}]);
})();
