angular.module('app')
  .factory('BiblioResultsService', BiblioResultsService);

/*@ngInject*/
function BiblioResultsService($rootScope, $http, SimpleSearchApiConfiguration, initData, NoticeNavigationService) {
  var results = [];
  results[0] = angular.copy(initData.results);
  storeContext();
  var currentPage = 1;
  var totalResults = initData.totalResults;
  var moreResults = initData.hasMoreResults;
  var queryParams = initData.queryParams;

  $rootScope.$on('biblio-result-select', handleSelectedResult);
  $rootScope.$on('biblio-result-unselect', handleUnselectedResult);
  $rootScope.$on('biblio-results-select-all', handleAllSelectedResults);
  $rootScope.$on('biblio-results-unselect-all', handleAllUnselectedResults);

  $rootScope.$on('biblio-results-next', onSearchNextPage);
  $rootScope.$on('biblio-results-all', onSearchAll);

  return { getResults, getNumberDisplayedResults, getTotalResults, hasMoreResults, areAllSelected, isAtLeastOneSelected };


  function handleSelectedResult(event, resultId) {
    var result = _.find(_.flatten(results), { id: resultId });
    result.selected = true;

    $rootScope.$broadcast('biblio-results-changed');
  }

  function handleUnselectedResult(event, resultId) {
    var result = _.find(_.flatten(results), { id: resultId });
    result.selected = false;

    $rootScope.$broadcast('biblio-results-changed');
  }

  function handleAllSelectedResults(event) {
    _.flatten(results).forEach((result) => result.selected = true);

    $rootScope.$broadcast('biblio-results-changed');
  }

  function handleAllUnselectedResults(event) {
    _.flatten(results).forEach((result) => result.selected = false);

    $rootScope.$broadcast('biblio-results-changed');
  }

  function getNumberDisplayedResults() {
    return _.flatten(results).length;
  }

  function getTotalResults() {
    return totalResults;
  }

  function hasMoreResults() {
    return moreResults;
  }

  function getResults() {
    return _.flatten(_.cloneDeep(results));
  }

  function areAllSelected() {
    return _.every(_.flatten(_.cloneDeep(results)), 'selected');
  }

  function isAtLeastOneSelected() {
    return _.some(_.flatten(_.cloneDeep(results)), 'selected');
  }


  function onSearchNextPage(event) {
    currentPage++;

    search(false);
  }

  function onSearchAll(event) {
    currentPage++;

    search(true);
  }

  function search(all) {
    var page = currentPage;

    $rootScope.$broadcast('biblio-search-loading');
    $http.get(SimpleSearchApiConfiguration.searchBiblioUri,
      { params: _.merge({ page: page,
                          all: all }, queryParams) }
    ).then((response) => {
      results[page - 1] = response.data.results;
      totalResults = response.data.totalResults;
      moreResults = response.data.hasMoreResults;
      $rootScope.$broadcast('biblio-search-successful');
      storeContext();
      $rootScope.$broadcast('biblio-results-changed');
    }, () => {
      $rootScope.$broadcast('biblio-search-error');
    });
  }

  function storeContext() {
    let context = _.map(_.filter(_.flatten(results), r => !r.lot), r => {
      return {
        id: r.id,
        titre: r.titre,
        uri: r.uri
      }
    });
    NoticeNavigationService.setContext(context);
  }
}
