(function(module) {
try {
  module = angular.module('app');
} catch (e) {
  module = angular.module('app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('common/app-notification-error.html',
    '<strong><span class=icon-warning-sign></span></strong> <span ng-if=notification().scope.data.error>Erreur {{notification().scope.status}} : {{notification().scope.data.error}}</span> <span ng-switch=notification().scope.status ng-if=!notification().scope.data.error><span ng-switch-when=0>Le serveur n\'est pas joignable.</span> <span ng-switch-when=403>Vous n\'avez pas accès à la ressource demandée.</span> <span ng-switch-when=404>La ressource demandée est inconnue.</span> <span ng-switch-when=500>Erreur interne du serveur.</span> <span ng-switch-default>Erreur {{notification().scope.status}}.</span></span>');
}]);
})();
