(function(module) {
try {
  module = angular.module('app');
} catch (e) {
  module = angular.module('app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('common/select-input.html',
    '<div class=has-feedback><input type=text class="form-control {{ inputClass }}" id="{{ id }}" name="{{ name }}" autocomplete=off placeholder="{{ placeholder }}" ng-model=inputCtrl.model ng-change=inputCtrl.onChange() typeahead="suggestion as (suggestion.libelle === undefined ? suggestion : suggestion.libelle) for suggestion in inputCtrl.getSuggestions($viewValue)" typeahead-on-select=inputCtrl.onChange() typeahead-show-on-click=true typeahead-popup-template-url=common/select-popup.html mediatheque-autofocus=applyFocus()> <span class="fa suggest-feedback" ng-class="{\'fa-caret-down\': !inputCtrl.loading, \'fa-gear\': inputCtrl.loading}"></span></div>');
}]);
})();
