angular.module('app')
  .directive('mediathequeConsultationLocalisation', ConsultationLocalisationDirective);

function ConsultationLocalisationDirective() {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      editable: '&mediathequeEditable'
    },
    controller: ConsultationLocalisationController,
    controllerAs: 'consultationLocalisationCtrl',
    templateUrl: 'illustration/consultation-localisation.html'
  };
}

/*@ngInject*/
function ConsultationLocalisationController($scope, $rootScope, ConsultationIllustrationService, $timeout) {
  var consultationLocalisationCtrl = this;

  consultationLocalisationCtrl.editable = $scope.editable();

  consultationLocalisationCtrl.onChange = onChange;

  initFromService();

  $scope.$on('illustration.changed', initFromService);

  function onChange() {
    $timeout(() => {
      $rootScope.$emit('illustration.localisation.updated', _.cloneDeep(consultationLocalisationCtrl.illustration));
    }, 0);
  }

  function initFromService() {
    consultationLocalisationCtrl.illustration = ConsultationIllustrationService.getIllustration();
  }
}
