angular.module('app')
  .directive('mediathequeIllustrationWithError', IllustrationWithErrorDirective);

function IllustrationWithErrorDirective() {
  return {
    restrict: 'E',
    replace: true,
    controller: IllustrationWithErrorController,
    controllerAs: 'illustrationWithErrorCtrl',
    scope: {
      sortOrder: '@mediathequeSortOrder',
      sortColumn: '@mediathequeSortColumn',
      sortNumiUri: '@mediathequeSortNumiUri',
      sortCreatedByUri: '@mediathequeSortCreatedByUri'
    },
    templateUrl: 'illustration/illustration-with-error.html'
  };
}

/*@ngInject*/
function IllustrationWithErrorController($scope, $rootScope, IllustrationWithErrorService) {
  var illustrationWithErrorCtrl = this;
  illustrationWithErrorCtrl.sortOrder = $scope.sortOrder;
  illustrationWithErrorCtrl.sortColumn = $scope.sortColumn;
  illustrationWithErrorCtrl.sortNumiUri = $scope.sortNumiUri;
  illustrationWithErrorCtrl.sortCreatedByUri = $scope.sortCreatedByUri;

  $scope.$on('illustration-with-error-changed', handleResultsChanged);

  init();

  illustrationWithErrorCtrl.selectResult = selectResult;

  function selectResult(result) {
    if (result.selected) {
      $rootScope.$emit('illustration-with-error-unselect', result.illustration.id);
    } else {
      $rootScope.$emit('illustration-with-error-select', result.illustration.id);
    }
  }

  function init() {
    handleResultsChanged();
  }

  function handleResultsChanged() {
    illustrationWithErrorCtrl.resultats = IllustrationWithErrorService.getResults();
  }

}
