(function(module) {
try {
  module = angular.module('app');
} catch (e) {
  module = angular.module('app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('common/entity-suggest.html',
    '<a href=# ng-click=$event.preventDefault() tabindex=-1><mediatheque-lock-status data-mediatheque-status="{{ ::match.model.lockStatus }}" ng-if=match.model.lockStatus class="fa-fw text-left"></mediatheque-lock-status><mediatheque-bookmark-status data-mediatheque-bookmark=match.model.bookmark class="fa-fw text-left"></mediatheque-bookmark-status><span bind-html-unsafe="match.label | typeaheadHighlight:query"></span></a>');
}]);
})();
