angular.module('app')
  .directive('mediathequeMultivaluedAdresseInput', MultivaluedAdresseInputDirective);

function MultivaluedAdresseInputDirective() {
  return {
    restrict: 'E',
    scope: {
      name: '@mediathequeName',
      id: '@mediathequeId',
      form: '@mediathequeForm',
      disabled: '&mediathequeDisabled',
      initialValues: '&mediathequeInitialValues',
      model: '=ngModel',
      onChange: '&mediathequeOnChange'
    },
    replace: true,
    controller: MultivaluedAdresseInputController,
    controllerAs: 'inputsCtrl',
    templateUrl: 'common/multivalued-adresse-input.html'
  };
}

/*@ngInject*/
function MultivaluedAdresseInputController($scope, $timeout) {
  var inputsCtrl = this;

  inputsCtrl.dirty = false;

  inputsCtrl.onChange = $scope.onChange;

  inputsCtrl.removeAdresse = removeAdresse;
  inputsCtrl.addAdresse = addAdresse;
  inputsCtrl.onChange = $scope.onChange;

  init();

  $scope.$watch(() => $scope.model, () => $timeout(init, 0));

  function addAdresse() {
    inputsCtrl.adresses.push({ nom: { libelle: "" }, typeVoie: { libelle: "" }, numero: null });
    inputsCtrl.dirty = true;
    inputsCtrl.newlyAdded = true;

    inputsCtrl.onChange && inputsCtrl.onChange();
  }

  function removeAdresse(adresseToRemove) {
    inputsCtrl.adresses = $scope.model = _.without(inputsCtrl.adresses, adresseToRemove);

    // Il faut au moins un champs affiché
    if (inputsCtrl.adresses.length === 0) {
      inputsCtrl.adresses = $scope.model = [{ nom: { libelle: "" }, typeVoie: { libelle: "" }, numero: null }];
    }
    inputsCtrl.dirty = true;
    inputsCtrl.newlyAdded = false;

    inputsCtrl.onChange && inputsCtrl.onChange();
  }

  function init() {
    inputsCtrl.newlyAdded = false;
    if ($scope.model) {
      inputsCtrl.adresses = $scope.model = $scope.model.length > 0 ? $scope.model : [{ nom: { libelle: "" }, typeVoie: { libelle: "" }, numero: null }];
      return;
    }
    inputsCtrl.adresses = $scope.model = ($scope.initialValues() && $scope.initialValues().length !== 0) ? $scope.initialValues() : [{ nom: { libelle: "" }, typeVoie: { libelle: "" }, numero: null }];
  }
}
