angular.module('app')
  .directive('mediathequeVignetteFromId', VignetteFromIdDirective);

function VignetteFromIdDirective() {
  return {
    restrict: 'E',
    scope: {
      vignetteUrl: '@mediathequeVignetteUrl',
      titre: '@mediathequeTitre',
      styles: '&mediathequeStyles',
      emptyIcon: '@mediathequeEmptyIcon'
    },
    replace: true,
    templateUrl: 'common/vignette-from-id.html'
  };
}
