angular.module('app')
  .directive('mediathequeNoticeNavigation', NoticeNavigationDirective);

function NoticeNavigationDirective() {
  return {
    restrict: 'E',
    scope: {
      entityId: '@mediathequeEntityId'
    },
    replace: true,
    controller: NoticeNavigationController,
    controllerAs: 'noticeNavigationCtrl',
    templateUrl: 'common/notice-navigation.html'
  };
}

/*@ngInject*/
function NoticeNavigationController($scope, NoticeNavigationService) {
  var noticeNavigationCtrl = this;
  noticeNavigationCtrl.disabled = true;

  noticeNavigationCtrl.notices = NoticeNavigationService.getNotices($scope.entityId);

  if (noticeNavigationCtrl.notices) {
    noticeNavigationCtrl.disabled = false;
  }
}
