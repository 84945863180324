angular.module('app')
  .factory('AuteurbiblioEditModal', AuteurbiblioEditModal);

/*@ngInject*/
function AuteurbiblioEditModal($q, $rootScope, $modal, $window, AuteurbiblioService) {
  return { createAuteurbiblio, updateAuteurbiblio };

  function createAuteurbiblio(uniqueId) {
    openModal(null)
      .then((auteurbiblioId) => {
        // TODO: A remplacer par un événement géré par AuteurbiblioService dans le cas où le template englobant est Angular et non Handlebars
        $rootScope.$broadcast('auteurbiblio.edit.success', uniqueId, auteurbiblioId);
      });
  }

  function updateAuteurbiblio(uniqueId, auteurbiblio) {
    openModal(auteurbiblio.id)
      .then((auteurbiblioId) => {
        // TODO: A remplacer par un événement géré par AuteurbiblioService dans le cas où le template englobant est Angular et non Handlebars
        $rootScope.$broadcast('auteurbiblio.edit.success', uniqueId, auteurbiblioId);
      });
  }

  function openModal(auteurbiblioId) {
    return $modal.open({
      animation: true,
      templateUrl: 'biblio/auteurbiblio-edit-modal.html',
      controller: AuteurbiblioEditModalController,
      controllerAs: 'auteurbiblioEditModalCtrl',
      resolve: {
        initialAuteurbiblio: () => {
          if (auteurbiblioId) {
            return AuteurbiblioService.load(auteurbiblioId);
          }
          return { editable: true };
        }
      }
    }).result;
  }
}

function AuteurbiblioEditModalController($scope, $modalInstance, $window, AuteurbiblioService, initialAuteurbiblio) {
  var auteurbiblioEditModalCtrl = this;

  auteurbiblioEditModalCtrl.submit = submit;
  auteurbiblioEditModalCtrl.cancel = cancel;

  init();

  $scope.$on('modal.closing', (event, reason, closed) => {
    if (closed || auteurbiblioEditModalCtrl.fullyCancelled) {
      return;
    }

    // Il faut veiller à ce que l'auteur soit correctement annulée
    event.preventDefault();

    cancel();
  });

  function submit() {
    if (!$scope.auteurBiblioForm.$valid) {
      event.preventDefault();
      auteurbiblioEditModalCtrl.error = { message: "Le formulaire n'est pas valide." };
      return;
    }

    auteurbiblioEditModalCtrl.closing = true;
    auteurbiblioEditModalCtrl.error = null;

    AuteurbiblioService.save(auteurbiblioEditModalCtrl.auteurbiblio)
      .then((newAuteurbiblio) => {
        auteurbiblioEditModalCtrl.closing = false;
        $modalInstance.close(newAuteurbiblio.id);
      }, (error) => {
        auteurbiblioEditModalCtrl.closing = false;
        auteurbiblioEditModalCtrl.error = error;
      });
  }

  function cancel() {
    if ($scope.auteurBiblioForm.$dirty && !$window.confirm("Des données ont été modifiées et ne sont pas sauvegardées. Voulez-vous continuer ?")) {
      return;
    }

    auteurbiblioEditModalCtrl.fullyCancelled = true;
    $modalInstance.dismiss();
  }

  function init() {
    auteurbiblioEditModalCtrl.auteurbiblio = _.cloneDeep(initialAuteurbiblio.auteurbiblio);
    auteurbiblioEditModalCtrl.editable = _.cloneDeep(initialAuteurbiblio.editable);
    auteurbiblioEditModalCtrl.nbBiblio = initialAuteurbiblio.nbBiblio;
    auteurbiblioEditModalCtrl.errorMessage = null;
  }
}




/*
function AuteurbiblioEditModal($q, $rootScope, $modal, $window, AuteurbiblioLoad, AuteurbiblioSave) {
  $rootScope.$on('auteurbiblio.edit.start', function(event, uniqueId, auteurbiblio) {
    if (auteurbiblio) {
      edit(auteurbiblio, uniqueId);
    } else {
      create(uniqueId);
    }
  });

  var auteurs = {};

  return { get };

  function get(id) {
    return _.get(auteurs, id);
  }
  function put(auteurbiblio) {
    _.set(auteurs, auteurbiblio.id, auteurbiblio);
  }

  function edit(auteurbiblio, uniqueId) {
    AuteurbiblioLoad.load(auteurbiblio)
      .then(function (result) {
        var modalInstance = $modal.open({
          animation: true,
          templateUrl: 'biblio/auteur-biblio.html',
          controller: EditAuteurBiblioController,
          controllerAs: 'editCtrl',
          resolve: { data: function () { return result.data; } }
        });
        modalInstance.result.then(function (result) {
          AuteurbiblioSave.save(result)
            .then(function (result) {
              put({id: result.data.id, libelle: result.data.libelle});
              $rootScope.$broadcast('auteurbiblio.edit.success', uniqueId, result.data.id);
            }, function (reason) {
              $window.alert(reason.data.model.message);
              $rootScope.$broadcast('auteurbiblio.edit.error', uniqueId, reason.data.model.message);
            });
        });
      }, function (reason) {
        $window.alert(reason.data.model.message);
        $rootScope.$broadcast('auteurbiblio.edit.error', uniqueId, reason.data.model.message);
      });
  }

  function create(uniqueId) {
    var modalInstance = $modal.open({
      animation: true,
      templateUrl: 'biblio/auteur-biblio.html',
      controller: AuteurbiblioEditModalController,
      controllerAs: 'auteurbiblioEditModalCtrl',
      resolve: { data: function () { return { auteurbiblio: {}, editable: true}; } }
    });
    modalInstance.result.then(function (result) {
      AuteurbiblioSave.save(result)
        .then(function (result) {
          put({id: result.data.id, libelle: result.data.libelle});
          $rootScope.$broadcast('auteurbiblio.edit.success', uniqueId, result.data.id);
        }, function (reason) {
          $window.alert(reason.data.model.message);
          $rootScope.$broadcast('auteurbiblio.edit.error', uniqueId, reason.data.model.message);
        });
    });
  }
}


function AuteurBiblioEditModalController($scope, $modalInstance, $window, AuteurbiblioSave, data) {
  var editCtrl = this;

  editCtrl.submit = submit;
  editCtrl.cancel = cancel;

  init();

  function init() {
    editCtrl.data = data;
    editCtrl.errorMessage = null;
    $scope.$on('modal.closing', checkDirty);
  }

  function checkDirty(event, result, close) {
    // submit
    if (close) {
      return;
    }
    // cancel
    if ($scope.auteurBiblioForm.$dirty && ! $window.confirm("Des données ont été modifiées et ne sont pas sauvegardées. Voulez-vous continuer ?")) {
      event.preventDefault();
    }
  }
  function submit() {
    if (!$scope.auteurBiblioForm.$valid) {
      event.preventDefault();
      editCtrl.errorMessage = "Le formulaire n'est pas valide.";
      return;
    }
    editCtrl.closing = true;
    $modalInstance.close(editCtrl.data.auteurbiblio);
  }
  function cancel() {
    $modalInstance.dismiss('cancel');
  }
}
*/
