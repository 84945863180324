(function(module) {
try {
  module = angular.module('app');
} catch (e) {
  module = angular.module('app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('illustration/rattachement-dossier-modal.html',
    '<div><form ng-submit=rattachementDossierModalCtrl.submit() mediatheque-on-before-unload><div class=modal-header><h3 class=modal-title>Rattacher l\'illustration à un dossier Gertrude</h3></div><div class=modal-body><div class=form-group><label class=control-label for=dossierInput>Dossier</label><mediatheque-dossier-field-input id=dossierInput ng-model=rattachementDossierModalCtrl.dossier mediatheque-apply-focus=true></mediatheque-dossier-field-input></div><div class=checkbox><label class=control-label><input type=checkbox ng-model=rattachementDossierModalCtrl.updateIllustration> Mettre à jour les informations de l\'illustration à partir de celles du dossier</label></div><div class="alert alert-danger" ng-if=rattachementDossierModalCtrl.error><span class="fa fa-exclamation-triangle"></span> Erreur {{ rattachementDossierModalCtrl.error.error }} : {{ rattachementDossierModalCtrl.error.message }}</div></div><div class=modal-footer><button class="btn btn-success" type=button ng-if=rattachementDossierModalCtrl.closing disabled>Rattachement en cours…</button> <button class="btn btn-success" type=submit ng-if=!rattachementDossierModalCtrl.closing ng-disabled=!rattachementDossierModalCtrl.dossier.id>Rattacher</button> <button class="btn btn-warning" type=button ng-click=rattachementDossierModalCtrl.cancel() ng-disabled=rattachementDossierModalCtrl.closing>Annuler</button></div></form></div>');
}]);
})();
