angular.module('app')
  .factory('FichierPost', FichierPost);

/*@ngInject*/
function FichierPost(Upload, FichierApiConfiguration) {
  return { postImage, postFile };

  function postImage(file, format) {
      return Upload.upload({
          url: FichierApiConfiguration.postImageUri,
          data: {
              'format': format,
              'extension': file.name.split('.').pop(),
              file: file
          }
      });
  }

  function postFile(file) {
      return Upload.upload({
          url: FichierApiConfiguration.postInFsUri,
          data: {
              'name': file.name,
              'extension': file.name.split('.').pop(),
              file: file
          }
      });
  }
}
