angular.module('app')
  .directive('mediathequeMultivaluedDossierFieldInput', MultivaluedDossierFieldInputDirective);

function MultivaluedDossierFieldInputDirective() {
  return {
    restrict: 'E',
    scope: {
      name: '@mediathequeName',
      id: '@mediathequeId',
      inputPlaceholder: '@mediathequeInputPlaceholder',
      form: '@mediathequeForm',
      disabled: '&mediathequeDisabled',
      initialValues: '&mediathequeInitialValues'
    },
    replace: true,
    controller: MultivaluedDossierFieldInputController,
    controllerAs: 'inputsCtrl',
    templateUrl: 'common/multivalued-dossier-field-input.html'
  };
}

/*@ngInject*/
function MultivaluedDossierFieldInputController($scope) {
  var inputsCtrl = this;

  inputsCtrl.addValue = addValue;
  inputsCtrl.removeValue = removeValue;

  init();

  // TODO: Ajouter un watcher sur $scope.model (couplé à un $timeout pour s'assurer que le scope est bien à jour)
  // si le model initial est modifié par le composant parent

  function addValue() {
    inputsCtrl.values.push({ libelle: "" });
    inputsCtrl.dirty = true;
    inputsCtrl.newlyAdded = true;
  }

  function removeValue(valueToRemove) {
    inputsCtrl.values = _.without(inputsCtrl.values, valueToRemove);

    // Il faut au moins un champs affiché
    if (inputsCtrl.values.length === 0) {
      // { libelle: "" } par défaut pour satisfaire UiBootstrap#Select
      inputsCtrl.values = [{ libelle: "" }];
    }
    inputsCtrl.dirty = true;
    inputsCtrl.newlyAdded = false;
  }

  function init() {
    // { libelle: "" } par défaut pour satisfaire UiBootstrap#Select
    inputsCtrl.values = ($scope.initialValues() && $scope.initialValues().length !== 0) ? $scope.initialValues() : [{ libelle: "" }];
    inputsCtrl.dirty = false;
    inputsCtrl.newlyAdded = false;
  }
}
