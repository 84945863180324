(function(module) {
try {
  module = angular.module('app');
} catch (e) {
  module = angular.module('app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('common/expert-search-result-value.html',
    '<span ng-switch=expertSearchResultValueCtrl.typeChamp><span ng-switch-when=Double>{{ expertSearchResultValueCtrl.valeur.doubleValue }}</span> <span ng-switch-when=Integer>{{ expertSearchResultValueCtrl.valeur.integerValue }}</span> <span ng-switch-when=EnumLockStatus><span ng-switch=expertSearchResultValueCtrl.valeur.enumLockStatusValue><span ng-switch-when=FREE>{{ \'lockStatus.free\' | messageFormat }}</span> <span ng-switch-when=LOCKED>{{ \'lockStatus.locked\' | messageFormat }}</span> <span ng-switch-when=WORKING_COPY>{{ \'lockStatus.workingCopy\' | messageFormat }}</span></span></span> <span ng-switch-when=DateAsDouble>{{ expertSearchResultValueCtrl.valeur.dateValue | date:\'dd/MM/yyyy\' }}</span> <span ng-switch-when=Date>{{ expertSearchResultValueCtrl.valeur.dateValue | date:\'dd/MM/yyyy\' }}</span> <span ng-switch-when=Boolean><span class=fa ng-class="{ \'fa-check-square-o\' : expertSearchResultValueCtrl.valeur.booleanValue, \'fa-square-o\' : !expertSearchResultValueCtrl.valeur.booleanValue }"></span></span> <span ng-switch-default>{{ expertSearchResultValueCtrl.valeur.stringValueUn }}</span></span>');
}]);
})();
