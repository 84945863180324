angular.module('app')
  .directive('mediathequeMultivaluedCadastreInput', MultivaluedCadastreInputDirective);

function MultivaluedCadastreInputDirective() {
  return {
    restrict: 'E',
    scope: {
      name: '@mediathequeName',
      id: '@mediathequeId',
      form: '@mediathequeForm',
      disabled: '&mediathequeDisabled',
      model: '=ngModel',
      onChange: '&mediathequeOnChange',
      initialValues: '&mediathequeInitialValues'
    },
    replace: true,
    controller: MultivaluedCadastreInputController,
    controllerAs: 'inputsCtrl',
    templateUrl: 'common/multivalued-cadastre-input.html'
  };
}

/*@ngInject*/
function MultivaluedCadastreInputController($scope, $timeout) {
  var inputsCtrl = this;

  inputsCtrl.dirty = false;

  inputsCtrl.onChange = $scope.onChange;

  inputsCtrl.removeCadastre = removeCadastre;
  inputsCtrl.addCadastre = addCadastre;

  init();

  $scope.$watch(() => $scope.model, () => $timeout(init, 0));

  function addCadastre() {
    inputsCtrl.cadastres.push({ nom: { libelle: "" }, typeVoie: { libelle: "" } });
    inputsCtrl.dirty = true;
    inputsCtrl.newlyAdded = true;

    inputsCtrl.onChange && inputsCtrl.onChange();
  }

  function removeCadastre(cadastreToRemove) {
    inputsCtrl.cadastres = $scope.model = _.without(inputsCtrl.cadastres, cadastreToRemove);

    // Il faut au moins un champs affiché
    if (inputsCtrl.cadastres.length === 0) {
      inputsCtrl.cadastres = $scope.model = [{ annee: "", section: "", parcelle: "", commentaire: ""}];
    }
    inputsCtrl.dirty = true;
    inputsCtrl.newlyAdded = false;

    inputsCtrl.onChange && inputsCtrl.onChange();
  }

  function init() {
    inputsCtrl.newlyAdded = false;

    if ($scope.model) {
      inputsCtrl.cadastres = $scope.model = $scope.model.length > 0 ? $scope.model : [{ annee: "", section: "", parcelle: "", commentaire: ""}];
      return;
    }

    inputsCtrl.cadastres = $scope.model = ($scope.initialValues() && $scope.initialValues().length !== 0) ? $scope.initialValues() : [{ annee: "", section: "", parcelle: "", commentaire: ""}];
  }
}
