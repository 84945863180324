(function(module) {
try {
  module = angular.module('app');
} catch (e) {
  module = angular.module('app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('common/multivalued-cadre-etude-input.html',
    '<div class="multivalued-input row--flex row--flex--wrap"><input type=hidden data-mediatheque-default-value=false value="{{ inputsCtrl.dirty }}" ng-attr-form="{{ form }}"><div ng-repeat="value in inputsCtrl.values" ng-class="{ \'col-sm-10\': !disabled(), \'col-sm-12\': disabled() }"><div ng-if="! $first"><br></div><div ng-class="{ \'input-group\': ! disabled() }"><mediatheque-thesaurus-input mediatheque-name="{{ name + \'[\' + $index + \'].type\' }}" mediatheque-id="{{ id + \'-\' + $index }}" ng-attr-mediatheque-form="{{ form }}" mediatheque-placeholder="Cadre d\'étude" mediatheque-disabled=disabled() mediatheque-types-thesaurus="[\'CadreEtude\']" mediatheque-initial-value=value.type ng-model=value.type mediatheque-on-change=inputsCtrl.onChange() mediatheque-apply-focus="$last && inputsCtrl.newlyAdded"></mediatheque-thesaurus-input><span class=input-group-btn ng-if="! disabled()"><button type=button class="btn btn-addon" ng-click=inputsCtrl.removeValue(value) title="{{ removeElementTitle}}"><span class="fa fa-times-circle"></span></button></span><div class=col-sm-12><mediatheque-thesaurus-input mediatheque-name="{{ name + \'[\' + $index + \'].affixe\' }}" mediatheque-id=notice-cadreEtudeInputAffixe mediatheque-placeholder=Affixe mediatheque-disabled=disabled() mediatheque-types-thesaurus="[\'CadreEtudeAffixe\']" mediatheque-initial-value=value.affixe ng-model=value.affixe mediatheque-on-change=inputsCtrl.onChange()></mediatheque-thesaurus-input></div></div></div><button type=button class="btn btn-transparent btn-input-actions" ng-click=inputsCtrl.addValue() ng-if="! disabled()" title="{{ addElementTitle }}"><span class="fa fa-plus"></span></button></div>');
}]);
})();
