angular.module('app')
  .directive('mediathequeConfirm', ConfirmDirective);

/*@ngInject*/
function ConfirmDirective($window) {
  return {
    restrict: 'A',
    link: function(scope, element, attrs) {
      element.bind('submit', function(event) {
        var msg = attrs.ngConfirm;
        if(!msg){
          msg = "Voulez-vous vraiment effectuer cette opération ?"
        }
        if (!$window.confirm(msg)) {
          event.stopImmediatePropagation();
          event.preventDefault();
        }
      });
    }
  };
}
