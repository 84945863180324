angular.module('app')
  .directive('mediathequeCollapseButton', CollapseButtonDirective);

/*@ngInject*/
function CollapseButtonDirective() {
  return {
    restrict: 'E',
    scope: {
      btnClass: '@mediathequeBtnClass',
      containerClass: '@mediathequeContainerClass',
      btnLabel: '@mediathequeBtnLabel',
      btnIcon: '@mediathequeBtnIcon',
      disabled: '&mediathequeDisabled'
    },
    transclude: true,
    controller: CollapseButtonController,
    controllerAs: 'collapseButtonCtrl',
    templateUrl: 'common/collapse-button.html'
  };
}

/*@ngInject*/
function CollapseButtonController($scope) {

  var collapseButtonCtrl = this;

  collapseButtonCtrl.toggle = toggle;

  init();

  // TODO: Ajouter un watcher sur $scope.model (couplé à un $timeout pour s'assurer que le scope est bien à jour)
  // si le model initial est modifié par le composant parent

  function init() {
    $scope.isCollapsed = true;
  }

  function toggle() {
    return $scope.isCollapsed = !$scope.isCollapsed;
  }
}
