(function(module) {
try {
  module = angular.module('app');
} catch (e) {
  module = angular.module('app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('illustration/dossiers-rattaches-container.html',
    '<div class=col-lg-6><label class="col-sm-2 col-lg-4 control-label">Dossier Gertrude utilisant l\'illustration</label><div class="col-sm-10 col-lg-8"><mediatheque-dossiers-rattaches data-mediatheque-illustration-id="{{ ::dossiersRattachesContainerCtrl.illustrationId }}" data-mediatheque-editable=::dossiersRattachesContainerCtrl.editable data-mediatheque-dossiers=dossiersRattachesContainerCtrl.dossiers></mediatheque-dossiers-rattaches><button type=button class="btn btn-sm btn-default" ng-if=::dossiersRattachesContainerCtrl.editable ng-click=dossiersRattachesContainerCtrl.rattacherDossier()>Rattacher à un dossier Gertrude</button></div></div>');
}]);
})();
