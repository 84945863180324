angular.module('app')
  .directive('mediathequeMultivaluedCollectionBiblioInput', MultivaluedCollectionBiblioInputDirective);

function MultivaluedCollectionBiblioInputDirective() {
  return {
    restrict: 'E',
    scope: {
      name: '@mediathequeName',
      id: '@mediathequeId',
      form: '@mediathequeForm',
      disabled: '&mediathequeDisabled',
      initialValues: '&mediathequeInitialValues'
    },
    replace: true,
    controller: MultivaluedCollectionBiblioInputController,
    controllerAs: 'inputsCtrl',
    templateUrl: 'common/multivalued-collection-biblio-input.html'
  };
}

/*@ngInject*/
function MultivaluedCollectionBiblioInputController($scope) {
  const inputsCtrl = this;

  inputsCtrl.removeCollectionBiblio = removeCollectionBiblio;
  inputsCtrl.addCollectionBiblio = addCollectionBiblio;

  init();

  // TODO: Ajouter un watcher sur $scope.model (couplé à un $timeout pour s'assurer que le scope est bien à jour)
  // si le model initial est modifié par le composant parent

  function defaultEmptyCollectionBiblio() {
    return ({nom: null, numero: null});
  }

  function addCollectionBiblio() {
    inputsCtrl.collectionsBiblio.push(defaultEmptyCollectionBiblio());
    inputsCtrl.dirty = true;
    inputsCtrl.newlyAdded = true;
  }

  function removeCollectionBiblio(collectionToRemove) {
    inputsCtrl.collectionsBiblio = _.without(inputsCtrl.collectionsBiblio, collectionToRemove);

    // Il faut au moins un champs affiché
    if (inputsCtrl.collectionsBiblio.length === 0) {
      inputsCtrl.collectionsBiblio = [defaultEmptyCollectionBiblio()];
    }
    inputsCtrl.dirty = true;
    inputsCtrl.newlyAdded = false;
  }

  function init() {
    inputsCtrl.collectionsBiblio = ($scope.initialValues() && $scope.initialValues().length !== 0) ? $scope.initialValues() : [defaultEmptyCollectionBiblio()];
    inputsCtrl.dirty = false;
    inputsCtrl.newlyAdded = false;
  }
}
