angular.module('app')
  .directive('mediathequeIllustrationExpertSearchResultsTabBar', IllustrationExpertSearchResultsTabBarDirective);

function IllustrationExpertSearchResultsTabBarDirective() {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      printUri: '@mediathequePrintUri',
      totalResults: '&mediathequeTotalResults',
      numberDisplayedResults: '&mediathequeNumberDisplayedResults',
      allSelected: '&mediathequeAllSelected',
      atLeastOneSelected: '&mediathequeAtLeastOneSelected',
      mosaic: '&mediathequeMosaic',
      canSaveRecherche: '&mediathequeCanSaveRecherche'
    },
    controller: IllustrationExpertSearchResultsTabBarController,
    controllerAs: 'expertSearchResultsTabBarCtrl',
    templateUrl: 'illustration/illustration-expert-search-results-tab-bar.html'
  };
}

/*@ngInject*/
function IllustrationExpertSearchResultsTabBarController($scope, $rootScope, ExpertSearch) {
  var expertSearchResultsTabBarCtrl = this;

  expertSearchResultsTabBarCtrl.selectAll = selectAll;
  expertSearchResultsTabBarCtrl.selectNone = selectNone;
  expertSearchResultsTabBarCtrl.enableMosaic = enableMosaic;
  expertSearchResultsTabBarCtrl.disableMosaic = disableMosaic;
  expertSearchResultsTabBarCtrl.displayAllResults = displayAllResults;
  expertSearchResultsTabBarCtrl.hasMoreResults = ExpertSearch.hasMoreResults;

  $scope.$watch($scope.canSaveRecherche, (value) => expertSearchResultsTabBarCtrl.canSaveRecherche = value);
  $scope.$watch($scope.allSelected, (value) => expertSearchResultsTabBarCtrl.allSelected = value);
  $scope.$watch($scope.atLeastOneSelected, (value) => expertSearchResultsTabBarCtrl.atLeastOneSelected = value);
  $scope.$watch($scope.mosaic, (mosaic) => expertSearchResultsTabBarCtrl.mosaic = mosaic);
  $scope.$watch($scope.totalResults, (totalResults) => expertSearchResultsTabBarCtrl.totalResults = totalResults);
  $scope.$watch($scope.numberDisplayedResults, (numberDisplayedResults) => expertSearchResultsTabBarCtrl.numberDisplayedResults = numberDisplayedResults);

  function enableMosaic() {
    $rootScope.$emit('illustration-results-enable-mosaic');
  }

  function disableMosaic() {
    $rootScope.$emit('illustration-results-disable-mosaic');
  }

  function selectAll() {
    $rootScope.$emit('expert-search-results-select-all');
  }

  function selectNone() {
    $rootScope.$emit('expert-search-results-unselect-all');
  }

  function displayAllResults() {
    $rootScope.$emit('expert-search-all', 'Illustration');
  }
}
