angular.module('app')
  .filter('messageFormat', MessageFormatFilter);

/*@ngInject*/
function MessageFormatFilter(MessageFormatter) {
  return function(path, basePath) {
    if (!path) {
      return '';
    }
    if (!basePath) {
      return MessageFormatter.format(path);
    }
    return MessageFormatter.format(`${basePath}.${path}`);
  };
}
