(function(module) {
try {
  module = angular.module('app');
} catch (e) {
  module = angular.module('app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('common/multivalued-numerisation-input.html',
    '<div class=multivalued-input><input type=hidden data-mediatheque-default-value=false value="{{ inputsCtrl.dirty }}" ng-attr-form="{{ form }}"><div ng-repeat="numerisation in inputsCtrl.numerisations"><div class="row--flex row--flex--wrap"><input type=hidden name="{{ name + \'.originalIndex\' }}" value="{{ numerisation.originalIndex }}" ng-attr-form="{{ form }}"><div class="col-sm-4 col-lg-4"><mediatheque-thesaurus-input mediatheque-name="{{ name + \'[\' + $index + \'].\' + \'numerisation\' }}" mediatheque-id="{{ id + \'-numerisation\' }}" mediatheque-disabled=disabled() mediatheque-placeholder=Numérisation ng-attr-mediatheque-form="{{ form }}" ng-model=numerisation.numerisation mediatheque-on-change=inputCtrl.onChange() mediatheque-disable-suggest=false mediatheque-types-thesaurus="[\'Numerisation\']"></mediatheque-thesaurus-input></div><div class="col-sm-4 col-lg-4"><mediatheque-text-input mediatheque-name="{{ name + \'[\' + $index + \'].\' + \'affixe\' }}" mediatheque-id="{{ id + \'-\' + $index + \'-affixe\'}}" mediatheque-disabled=disabled() mediatheque-placeholder=Affixe mediatheque-field-path=numerique.numerisations.affixe mediatheque-entity-type=Illustration ng-attr-mediatheque-form="{{ form }}" mediatheque-value="{{ numerisation.affixe }}"></mediatheque-text-input></div><div ng-if="! disabled()"><button type=button class="btn btn-transparent btn-input-actions" ng-click=inputsCtrl.remove(numerisation) title="Supprimer un exemplaire"><span class="fa fa-times-circle"></span></button></div><div ng-if="! disabled() && $last"><button type=button class="btn btn-transparent btn-input-actions" ng-click=inputsCtrl.add() title="Ajouter un exemplaire"><span class="fa fa-plus"></span></button></div></div></div></div>');
}]);
})();
