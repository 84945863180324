angular.module('app')
  .directive('mediathequePictureDownloader', PictureDownloaderDirective);

function PictureDownloaderDirective() {
  return {
    restrict: 'E',
    replace: true,
    controller: PictureDownloaderController,
    controllerAs: 'pictureDownloaderCtrl',
    scope: {
      illustrationId: '@mediathequeIllustrationId',
      immatriculation: '@mediathequeImmatriculation',
      format: '@mediathequeFormat'
    },
    templateUrl: 'illustration/picture-downloader.html'
  };
}

/*@ngInject*/
function PictureDownloaderController($scope, $rootScope, ImageIllustrationGet) {
  var pictureDownloaderCtrl = this;

  pictureDownloaderCtrl.format = $scope.format;
  pictureDownloaderCtrl.immatriculation = $scope.immatriculation;
  pictureDownloaderCtrl.fileUri = null;

  pictureDownloaderCtrl.isAvailable = isAvailable;

  $scope.$on('illustration.image.changed', handleImageChanged);

  init();

  function isAvailable() {
    return !!pictureDownloaderCtrl.fileUri;
  }

  function handleImageChanged(event, eventIllustrationId, eventFormat) {
    if ($scope.illustrationId !== eventIllustrationId || $scope.format !== eventFormat) {
      return;
    }
    init();
  }

  function init() {
    var cache = ImageIllustrationGet.get($scope.illustrationId, $scope.format);
    pictureDownloaderCtrl.fileUri = cache && cache.uri;
  }
}
