angular.module('app')
  .factory('BookmarkService', BookmarkService);

/*@ngInject*/
function BookmarkService($rootScope, $http, Bookmarks, BookmarkApiConfiguration) {

  var bookmarks = _.clone(Bookmarks);
  $rootScope.$broadcast('bookmarks-changed');

  return { getBookmarks, addBookmark, removeBookmark, isBookmark };

  function getBookmarks() {
    return _.clone(bookmarks);
  }

  function addBookmark(id, entityType) {
    $http.post(BookmarkApiConfiguration.addUri, null,
      { params: { id: id, entityType: entityType }
    }).then(response => {
      if (!bookmarks[entityType]) {
        bookmarks[entityType] = [];
      }
      bookmarks[entityType].push(response.data);
      $rootScope.$broadcast('bookmarks-changed');
    }, () => {
      $rootScope.$broadcast('bookmarks-changed');
    })
  }

  function removeBookmark(id, entityType) {
    $http.post(BookmarkApiConfiguration.removeUri, null,
      { params: { id: id, entityType: entityType }
    }).then(response => {
      if (bookmarks[entityType]) {
        bookmarks[entityType] = _.filter(bookmarks[entityType], b => b.id !== id);
      }
      $rootScope.$broadcast('bookmarks-changed');
    }, () => {
      $rootScope.$broadcast('bookmarks-changed');
    })
  }

  function isBookmark(id, entityType) {
    if (!bookmarks || !bookmarks[entityType]) {
      return false;
    }
    return _.some(bookmarks[entityType], b => b.id === id);
  }
}
