angular.module('app')
  .directive('mediathequeAdresseInput', AdresseInputDirective);

function AdresseInputDirective() {
  return {
    restrict: 'E',
    scope: {
      name: '@mediathequeName',
      id: '@mediathequeId',
      form: '@mediathequeForm',
      disabled: '&mediathequeDisabled',
      model: '=ngModel',
      onChange: '&mediathequeOnChange',
      initialValue: '&mediathequeInitialValue',
      applyFocus: '&mediathequeApplyFocus'
    },
    replace: true,
    controller: AdresseInputController,
    controllerAs: 'inputCtrl',
    templateUrl: 'common/adresse-input.html'
  };
}

/*@ngInject*/
function AdresseInputController($scope) {
  var inputCtrl = this;

  inputCtrl.onChange = $scope.onChange;

  init();

  // TODO: Ajouter un watcher sur $scope.model (couplé à un $timeout pour s'assurer que le scope est bien à jour)
  // si le model initial est modifié par le composant parent

  function init() {
    if ($scope.model) {
      inputCtrl.adresse = $scope.model;
      return;
    }

    inputCtrl.adresse = $scope.model = $scope.initialValue() || { nom: { libelle: ""}, typeVoie: { libelle: ""} };
  }
}
