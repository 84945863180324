angular.module('app')
  .directive('mediathequeDossiersRattachesContainer', DossiersRattachesContainerDirective);

function DossiersRattachesContainerDirective($window, $timeout) {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      editable: '&mediathequeEditable',
      illustrationId: '@mediathequeIllustrationId'
    },
    controller: DossiersRattachesContainerController,
    controllerAs: 'dossiersRattachesContainerCtrl',
    templateUrl: 'illustration/dossiers-rattaches-container.html'
  };
}

/*@ngInject*/
function DossiersRattachesContainerController($scope, $rootScope, DossiersRattachesService, RattachementDossierModalService) {
  var dossiersRattachesContainerCtrl = this;

  dossiersRattachesContainerCtrl.editable = $scope.editable();
  dossiersRattachesContainerCtrl.illustrationId = $scope.illustrationId;
  dossiersRattachesContainerCtrl.dossiers = DossiersRattachesService.getDossiers();

  dossiersRattachesContainerCtrl.rattacherDossier = rattacherDossier;

  $scope.$on('illustration.dossier.changed', handleDossiersRattachesChanged);

  function handleDossiersRattachesChanged() {
    dossiersRattachesContainerCtrl.dossiers = DossiersRattachesService.getDossiers();
  }

  function rattacherDossier() {
    RattachementDossierModalService.rattacherDossier(dossiersRattachesContainerCtrl.illustrationId);
  }
}
