angular.module('app')
  .directive('mediathequeBiblioResults', BiblioResultsDirective);

function BiblioResultsDirective() {
  return {
    restrict: 'E',
    scope: {
      resultats: '&mediathequeResultats'
    },
    replace: true,
    controller: BiblioResultController,
    controllerAs: 'biblioResultsCtrl',
    templateUrl: 'biblio/biblio-results.html'
  };
}

/*@ngInject*/
function BiblioResultController($scope, $rootScope, BiblioResultsService) {
  var biblioResultsCtrl = this;

  biblioResultsCtrl.hasMoreResults = BiblioResultsService.hasMoreResults;
  biblioResultsCtrl.displayNextResults = displayNextResults;
  biblioResultsCtrl.displayAllResults = displayAllResults;

  $scope.$on('biblio-results-changed', handleResultsChanged);
  $scope.$on('biblio-search-loading', handleResultsLoading);
  $scope.$on('biblio-search-successful', handleResultsLoaded);
  $scope.$on('biblio-search-error', handleResultsLoaded);

  init();

  biblioResultsCtrl.selectResult = selectResult;

  function selectResult(result) {
    if (result.selected) {
      $rootScope.$emit('biblio-result-unselect', result.id);
    } else {
      $rootScope.$emit('biblio-result-select', result.id);
    }
  }
  function displayAllResults() {
    $rootScope.$emit('biblio-results-all');
  }
  function displayNextResults() {
    $rootScope.$emit('biblio-results-next');
  }

  function init() {
    handleResultsChanged();
    handleResultsLoaded();
  }

  function handleResultsChanged() {
    biblioResultsCtrl.resultats = BiblioResultsService.getResults();
    biblioResultsCtrl.allSelected = BiblioResultsService.areAllSelected();
    biblioResultsCtrl.atLeastOneSelected = BiblioResultsService.isAtLeastOneSelected();
    biblioResultsCtrl.numberDisplayedResults = BiblioResultsService.getNumberDisplayedResults();
    biblioResultsCtrl.totalResults = BiblioResultsService.getTotalResults();
  }

  function handleResultsLoaded() {
    biblioResultsCtrl.loading = false;
    biblioResultsCtrl.loaded = true;
  }

  function handleResultsLoading() {
    biblioResultsCtrl.loading = true;
  }
}
