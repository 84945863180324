(function(module) {
try {
  module = angular.module('app');
} catch (e) {
  module = angular.module('app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('illustration/consultation-refdoc-reprise.html',
    '<div><input name=mediathequeIllustration.removeRefdocReprise type=hidden value="{{ consultationRefdocRepriseCtrl.dirty }}" data-mediatheque-default-value="{{ ::consultationRefdocRepriseCtrl.dirty }}"><div ng-if=consultationRefdocRepriseCtrl.refdoc><h4>Référence documentaire - Reprise</h4><div class=form-group><label class="col-md-2 col-lg-4 control-label" for=tiredeRefDocImport>Tiré de</label><div class="col-md-10 col-lg-8"><input type=text class=form-control id=tiredeRefDocImport name=tiredeRefDocImport value="{{ ::consultationRefdocRepriseCtrl.refdoc.tirede }}" readonly></div></div><div class=form-group><label class="col-md-2 col-lg-4 control-label" for=lieuConservationRefDocImport>Lieu de conservation</label><div class="col-md-10 col-lg-8"><input type=text class=form-control id=lieuConservationRefDocImport name=lieuConservationRefDocImport value="{{ ::consultationRefdocRepriseCtrl.refdoc.lieuConservation.libelle }}" readonly></div></div><div class=form-group><label class="col-md-2 col-lg-4 control-label" for=coteRefDocImport>Cote</label><div class="col-md-6 col-lg-4"><input type=text class=form-control id=coteRefDocImport name=coteRefDocImport value="{{ ::consultationRefdocRepriseCtrl.refdoc.cote }}" readonly></div></div><div class=form-group><label class="col-md-2 col-lg-4 control-label" for=commentaireRefDocImport>Commentaire</label><div class="col-md-10 col-lg-8"><textarea class=form-control id=commentaireRefDocImport name=commentaireRefDocImport readonly>{{ ::consultationRefdocRepriseCtrl.refdoc.commentaire }}</textarea></div></div><div class=form-group ng-if="! consultationRefdocRepriseCtrl.disabled"><div class=col-sm-12><button type=button class="btn btn-sm btn-block btn-danger" ng-click=consultationRefdocRepriseCtrl.removeRefdoc()>Supprimer les informations de la ref-doc reprise</button></div></div></div></div>');
}]);
})();
