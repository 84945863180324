(function(module) {
try {
  module = angular.module('app');
} catch (e) {
  module = angular.module('app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('common/cadastre-input.html',
    '<div class=row><input type=hidden name="{{ name + \'.originalIndex\' }}" value="{{ inputCtrl.cadastre.originalIndex }}" ng-attr-form="{{ form }}"><div class=col-sm-2><input type=text class=form-control id="{{ id + \'-annee\' }}" name="{{ name + \'.annee\' }}" placeholder=année ng-model=inputCtrl.cadastre.annee ng-change=inputCtrl.onChange() ng-readonly=disabled() data-mediatheque-default-value="{{ ::initialValue().annee }}" mediatheque-autofocus=applyFocus()></div><div class=col-sm-3><input type=text class=form-control id="{{ id + \'-section\' }}" name="{{ name + \'.section\' }}" placeholder=section ng-model=inputCtrl.cadastre.section ng-change=inputCtrl.onChange() ng-readonly=disabled() data-mediatheque-default-value="{{ ::initialValue().section }}"></div><div class=col-sm-3><input type=text class=form-control id="{{ id + \'-parcelle\' }}" name="{{ name + \'.parcelle\' }}" placeholder=parcelle ng-model=inputCtrl.cadastre.parcelle ng-change=inputCtrl.onChange() ng-readonly=disabled() data-mediatheque-default-value="{{ ::initialValue().parcelle }}"></div><div class=col-sm-4><input type=text class=form-control id="{{ id + \'-commentaire\' }}" name="{{ name + \'.commentaire\' }}" placeholder=commentaire ng-model=inputCtrl.cadastre.commentaire ng-change=inputCtrl.onChange() ng-readonly=disabled() data-mediatheque-default-value="{{ ::initialValue().commentaire }}"></div></div>');
}]);
})();
