(function(module) {
try {
  module = angular.module('app');
} catch (e) {
  module = angular.module('app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('common/bookmarks-menu.html',
    '<li class="dropdown top-menu__bookmarks" dropdown on-toggle=toggled(open)><a href=# class=dropdown-toggle role=button aria-expanded=false dropdown-toggle>Favoris <span class=caret></span></a><ul class="dropdown-menu top-menu__submenu__bookmarks" role=menu><li ng-if=!bookmarksCtrl.bookmarksAsList.length><span class=type-entite>Aucun favori</span></li><li ng-repeat="bookmark in bookmarksCtrl.bookmarksAsList"><a ng-if=bookmark.uri ng-href={{bookmark.uri}} title={{bookmark.title}} class=text-overflow-ellipsis mediatheque-clear-context-on-click><mediatheque-bookmark-status data-mediatheque-bookmark=true></mediatheque-bookmark-status>{{bookmark.title}}</a> <span ng-if=!bookmark.uri class=type-entite>{{bookmark.title}}</span></li></ul></li>');
}]);
})();
