(function(module) {
try {
  module = angular.module('app');
} catch (e) {
  module = angular.module('app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('illustration/personne-edit-modal.html',
    '<div><form name=personneForm ng-submit=personneEditModalCtrl.submit() mediatheque-on-before-unload><div class=modal-header><h3 class=modal-title ng-if=personneEditModalCtrl.nbIllustration>Modification de l\'auteur</h3><h3 class=modal-title ng-if=!personneEditModalCtrl.nbIllustration>Création d\'un auteur</h3></div><div class="modal-header text-center"><select class=form-control ng-model=personneEditModalCtrl.personne.type ng-change=personneEditModalCtrl.updatePersonneType()><option value=Physique>{{ \'personne.type.personnePhysique\' | messageFormat }}</option><option value=Morale>{{ \'personne.type.personneMorale\' | messageFormat }}</option></select></div><div class=modal-body ng-include=personneEditModalCtrl.formTemplateUrl></div><div class=modal-footer><button class="btn btn-success" type=button ng-if="personneEditModalCtrl.editable && personneEditModalCtrl.closing" disabled>Enregistrement…</button> <button class="btn btn-success" type=submit ng-if="personneEditModalCtrl.editable && personneEditModalCtrl.notProcessing()">Enregistrer</button> <button class="btn btn-warning" type=button ng-if="personneEditModalCtrl.editable && personneEditModalCtrl.dismissing" disabled>Annulation…</button> <button class="btn btn-warning" type=button ng-click=personneEditModalCtrl.cancel() ng-if=personneEditModalCtrl.notProcessing()>Annuler</button></div></form></div>');
}]);
})();
