angular.module('app')
  .service('LotPictureSliderSupplier', LotPictureSliderSupplier);

/*ngInject*/
function LotPictureSliderSupplier($rootScope, LotElementsService, $q, $timeout) {
  return { getImages, canGetMoreImages, loadMoreImages };

  function getImages() {
    return _.map(LotElementsService.getElements(), (element) => {
      return {
        illustrationId: element.id,
        imageUrl: element.pleinEcranUri,
        titreCourant: element.titreCourant || element.titre,
        denominations: element.denomations,
        commune: element.sousTitre,
        numi: element.titre,
        illustrationUrl: element.uri,
        type: element.type,
        entityType: element.entityType,
      };
    });
  }

  function canGetMoreImages() {
    return LotElementsService.hasMoreElements();
  }

  function loadMoreImages() {
    $rootScope.$emit('lot-elements-next', 'Illustration');
    var listenerDeregistration;
    return $q((resolve, reject) => {
      // This listener should be removed after execution
      listenerDeregistration = $rootScope.$on('lot-elements-successful', () => {
        resolve();

        listenerDeregistration();
      });
    });
  }
}
