angular.module('app')
  .factory('NotificationService', NotificationService);

/*@ngInject*/
function NotificationService($rootScope) {
  return {
    'notify': notify
  };

  function notify(notification) {
    notification = _.defaults(notification, {
      'id': _.uniqueId('notification_'),
      'scope': {},
      'fadeOn': {
        'enter': true,
        'leave': true
      }
    });

    $rootScope.$emit('notification.notify', notification);
  }
}
