angular.module('app')
  .directive('mediathequeMultivaluedNumerisationInput', MultivaluedNumerisationInputDirective);

function MultivaluedNumerisationInputDirective() {
  return {
    restrict: 'E',
    scope: {
      name: '@mediathequeName',
      id: '@mediathequeId',
      form: '@mediathequeForm',
      disabled: '&mediathequeDisabled',
      initialValues: '&mediathequeInitialValues'
    },
    replace: true,
    controller: MultivaluedNumerisationInputController,
    controllerAs: 'inputsCtrl',
    templateUrl: 'common/multivalued-numerisation-input.html'
  };
}

/*@ngInject*/
function MultivaluedNumerisationInputController($scope) {
  var inputsCtrl = this;

  inputsCtrl.remove = remove;
  inputsCtrl.add = add;

  init();

  function add() {
    inputsCtrl.numerisations.push({ numerisation: null, affixe: null });
    inputsCtrl.dirty = true;
    inputsCtrl.newlyAdded = true;
  }

  function remove(numerisationToRemove) {
    inputsCtrl.numerisations = _.without(inputsCtrl.numerisations, numerisationToRemove);

    // Il faut au moins un champs affiché
    if (inputsCtrl.numerisations.length === 0) {
      inputsCtrl.numerisations = [{ numerisation: null, affixe: null }];
    }
    inputsCtrl.dirty = true;
    inputsCtrl.newlyAdded = false;
  }

  function init() {
    inputsCtrl.numerisations = ($scope.initialValues() && $scope.initialValues().length !== 0) ? $scope.initialValues() : [{ numerisation: null, affixe: null }];
    inputsCtrl.dirty = false;
    inputsCtrl.newlyAdded = false;
  }
}
