angular.module('app')
  .directive('mediathequeClearContextOnLoad', ClearContextOnLoadDirective)
  .directive('mediathequeClearContextOnClick', ClearContextOnClickDirective);

/*@ngInject*/
function ClearContextOnLoadDirective(NoticeNavigationService) {
  return {
    restrict: 'A',
    link: function(scope, element, attrs) {
      NoticeNavigationService.clearContext();
    }
  };
}

/*@ngInject*/
function ClearContextOnClickDirective(NoticeNavigationService) {

  return {
    restrict: 'A',
    link: function(scope, element, attrs) {
      element.bind('click', function(event) {
        NoticeNavigationService.clearContext();
      });
    }
  };
}
