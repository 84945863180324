angular.module('app')
  .directive('mediathequeIllustrationExpertSearchContainer', IllustrationExpertSearchContainerDirective);

function IllustrationExpertSearchContainerDirective($window, $timeout) {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      createLotUri: '@mediathequeCreateLotUri',
      createLotExpertUri: '@mediathequeCreateLotExpertUri',
      exportCsvUri: '@mediathequeExportCsvUri',
      printUri: '@mediathequePrintUri',
      saveRechercheExperteUri: '@mediathequeSaveRechercheExperteUri'
    },
    controller: IllustrationExpertSearchContainerController,
    controllerAs: 'expertSearchContainerCtrl',
    templateUrl: 'illustration/illustration-expert-search-container.html',
    link: function (scope, jqElement) {
      var resultsElement = null;
      scope.$on('expert-search-successful', () => {
        $timeout(() => {
          resultsElement = resultsElement || jqElement[0].querySelector('[mediatheque-scroll-marker]');
          var rect = resultsElement.getBoundingClientRect();
          var navbars = document.getElementsByClassName('navbar-fixed-top');
          var navbarHeight = _.chain(navbars)
            .map('scrollHeight')
            .reduce((previousValue, currentValue) => previousValue + currentValue)
            .value();

          // On ne scroll pas si l'utilisateur est déjà dans les résultats (comme le cas où l'utilisateur souhaite voir les résultats suivant)
          if (rect.top - navbarHeight <= 0) {
            return;
          }

          $window.scrollTo(0, $window.scrollY + rect.top - navbarHeight);
        }, 0);
      });
    }
  };
}

/*@ngInject*/
function IllustrationExpertSearchContainerController($scope, $rootScope, ExpertSearch, IllustrationResultsService, SecurityConfiguration) {
  var expertSearchContainerCtrl = this;
  expertSearchContainerCtrl.search = ExpertSearch.getCurrentSearch();
  expertSearchContainerCtrl.results = ExpertSearch.getResults();
  expertSearchContainerCtrl.isSearchValid = ExpertSearch.isSearchValid;
  expertSearchContainerCtrl.mosaic = IllustrationResultsService.isMosaicEnabled();

  expertSearchContainerCtrl.onSubmit = onSubmit;
  expertSearchContainerCtrl.onReset = onReset;

  expertSearchContainerCtrl.xsrf = SecurityConfiguration['_xsrf_'];

  $scope.$on('expert-search-filters-changed', handleSearchFiltersChanged);
  $scope.$on('expert-search-results-changed', handleSearchResultsChanged);
  $scope.$on('expert-search-loading', handleSearchResultsLoading);
  $scope.$on('expert-search-successful', handleSearchResultsLoaded);
  $scope.$on('expert-search-error', handleSearchResultsLoaded);
  $scope.$on('expert-search-reinitialized', handleReinitializedSearch);
  $scope.$on('illustration-results-mosaic-changed', handleMosaicChanged);

  function onSubmit() {
    $rootScope.$emit('expert-search-start', 'Illustration');
  }

  function onReset() {
    $rootScope.$emit('expert-search-reset');
  }

  function handleReinitializedSearch() {
    expertSearchContainerCtrl.loaded = false;
  }

  function handleSearchFiltersChanged() {
    expertSearchContainerCtrl.search = ExpertSearch.getCurrentSearch();
  }

  function handleSearchResultsChanged() {
    expertSearchContainerCtrl.results = ExpertSearch.getResults();
    expertSearchContainerCtrl.totalResults = ExpertSearch.getTotalResults();
    expertSearchContainerCtrl.numberDisplayedResults = ExpertSearch.getNumberDisplayedResults();
    expertSearchContainerCtrl.allResultsSelected = ExpertSearch.allResultsSelected();
    expertSearchContainerCtrl.atLeastOneResultSelected = ExpertSearch.atLeastOneResultSelected();
  }

  function handleSearchResultsLoading() {
    expertSearchContainerCtrl.loading = true;
  }

  function handleSearchResultsLoaded() {
    expertSearchContainerCtrl.loading = false;
    expertSearchContainerCtrl.loaded = true;
  }

  function handleMosaicChanged() {
    expertSearchContainerCtrl.mosaic = IllustrationResultsService.isMosaicEnabled();
  }
}
