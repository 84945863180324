angular.module('app')
  .directive('mediathequeBiblioResultsTabBar', BiblioResultsTabBarDirective);

function BiblioResultsTabBarDirective() {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      createLotSimpleUri: '@mediathequeCreateLotSimpleUri'
    },
    controller: BiblioResultsTabBarController,
    controllerAs: 'biblioResultsTabBarCtrl',
    templateUrl: 'biblio/biblio-results-tab-bar.html'
  };
}

/*@ngInject*/
function BiblioResultsTabBarController($scope, $rootScope, BiblioResultsService) {
  var biblioResultsTabBarCtrl = this;

  biblioResultsTabBarCtrl.selectAll = selectAll;
  biblioResultsTabBarCtrl.selectNone = selectNone;

  biblioResultsTabBarCtrl.hasMoreResults = BiblioResultsService.hasMoreResults;
  biblioResultsTabBarCtrl.displayAllResults = displayAllResults;

  $scope.$on('biblio-results-changed', handleResultsChanged);

  handleResultsChanged();

  function selectAll() {
    $rootScope.$emit('biblio-results-select-all');
  }

  function selectNone() {
    $rootScope.$emit('biblio-results-unselect-all');
  }

  function displayAllResults() {
    $rootScope.$emit('biblio-results-all');
  }

  function handleResultsChanged() {
    biblioResultsTabBarCtrl.allSelected = BiblioResultsService.areAllSelected();
    biblioResultsTabBarCtrl.atLeastOneSelected = BiblioResultsService.isAtLeastOneSelected();
    biblioResultsTabBarCtrl.numberDisplayedResults = BiblioResultsService.getNumberDisplayedResults();
    biblioResultsTabBarCtrl.totalResults = BiblioResultsService.getTotalResults();
  }
}
