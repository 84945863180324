angular.module('app')
  .directive('mediathequeSimpleSearchPictureSliderWrapper', SimpleSearchPictureSliderWrapperDirective);

function SimpleSearchPictureSliderWrapperDirective() {
  return {
    restrict: 'E',
    replace: true,
    transclude: true,
    scope: {
      illustrationId: '@mediathequeIllustrationId'
    },
    controller: SimpleSearchPictureSliderWrapperController,
    controllerAs: 'pictureSliderWrapperCtrl',
    templateUrl: 'illustration/picture-slider-wrapper.html'
  };
}

/*@ngInject*/
function SimpleSearchPictureSliderWrapperController($scope, PictureSliderModalService, SimpleSearchPictureSliderSupplier) {
  var pictureSliderWrapperCtrl = this;

  pictureSliderWrapperCtrl.displaySlider = displaySlider;

  function displaySlider() {
    PictureSliderModalService.displaySlider($scope.illustrationId, SimpleSearchPictureSliderSupplier);
  }
}
