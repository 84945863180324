(function(module) {
try {
  module = angular.module('app');
} catch (e) {
  module = angular.module('app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('illustration/picture-downloader.html',
    '<div title="{{ ::(pictureDownloaderCtrl.format | messageFormat: \'pictureFormatTooltip\') }}"><a ng-href="{{ pictureDownloaderCtrl.fileUri }}" ng-class="{ \'disabled\': !pictureDownloaderCtrl.isAvailable() }" ng-switch=::pictureDownloaderCtrl.format target=_blank class="btn btn-sm btn-default"><span ng-switch-when=PleinEcran class="fa fa-expand"></span> <span ng-switch-when=Source class="fa fa-picture-o"></span> <span ng-switch-when=Brut class="fa fa-file-image-o"></span> <span ng-switch-when=Xmp class="fa fa-file-code-o"></span></a></div>');
}]);
})();
