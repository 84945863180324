angular.module('app')
  .directive('mediathequeConfirmSuppressionNotices', ConfirmSuppressionNoticesDirective);

/*@ngInject*/
function ConfirmSuppressionNoticesDirective($window) {
  return {
    restrict: 'A',
    link: function(scope, element, attrs) {
      element.bind('submit', function(event) {
        var msg = attrs.ngConfirm;
        if(!msg){
          msg =
          `Vous êtes sur le point de supprimer définitivement des notices et les fichiers liés.

Voulez-vous vraiment effectuer cette opération ?

Si vous souhaitez dépublier les notices de la diffusion,
vous devez d'abord appliquer le traitement "DÉPUBLIER"
car après suppression cela ne sera plus possible.`
        }
        if (!$window.confirm(msg)) {
          event.stopImmediatePropagation();
          event.preventDefault();
        }
      });
    }
  };
}
