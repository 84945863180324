(function(module) {
try {
  module = angular.module('app');
} catch (e) {
  module = angular.module('app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('common/contribution-ref-doc-input.html',
    '<div class="row--flex row--flex--wrap"><div class=col-sm-6><mediatheque-personne-input data-mediatheque-name="{{ name }}.personne" data-mediatheque-id="{{ id }}.personne" data-mediatheque-placeholder=auteur data-mediatheque-disabled=disabled() ng-model=model.personne mediatheque-apply-focus=applyFocus()></mediatheque-personne-input></div><div class=col-sm-6><select name="{{ name }}.role" id="{{ id }}.role" ng-disabled=disabled() class=form-control ng-class="{ \'select--empty\': !model.role }" ng-model=model.role ng-options="option.value as (option.libelle | messageFormat: \'illustration.refDoc.role\') for option in [ { \'libelle\': \'auteur\', \'value\': \'Auteur\' }, { \'libelle\': \'traducteur\', \'value\': \'Traducteur\' } ]"><option class=hidden value>rôle</option></select></div><div class=col-sm-4><mediatheque-multivalued-text-input data-mediatheque-name="{{ name }}.datesIntervention" data-mediatheque-id="{{ id }}.datesInterventionInput" mediatheque-add-element-title="Ajouter une date" mediatheque-remove-element-title="Supprimer une date" data-mediatheque-placeholder="date d\'intervention" data-mediatheque-disabled=disabled() data-mediatheque-not-empty=true ng-model=model.datesIntervention></mediatheque-multivalued-text-input></div></div>');
}]);
})();
