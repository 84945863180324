(function(module) {
try {
  module = angular.module('app');
} catch (e) {
  module = angular.module('app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('task/task-checkbox-input.html',
    '<input type=checkbox name="{{ ::taskCheckboxInputCtrl.name }}" form="{{ ::taskCheckboxInputCtrl.form }}" value="{{ ::taskCheckboxInputCtrl.id }}" title="Sélectionner cette tâche" ng-model=taskCheckboxInputCtrl.selected ng-click=taskCheckboxInputCtrl.onSelect()>');
}]);
})();
