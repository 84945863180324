angular.module('app')
  .directive('mediathequeStoreScroll', StoreScrollPositionDirective)
  .directive('mediathequeStoreScrollOnClick', StoreScrollPositionOnClickDirective)
  .directive('mediathequeScrollToPosition', ScrollToPositionDirective);

const MEDIATHEQUE_SCROLL_POSITION = 'MEDIATHEQUE_SCROLL_POSITION';

/*@ngInject*/
function StoreScrollPositionDirective($window) {

  return {
    restrict: 'A',
    link: function(scope, element, attrs) {
      if (!$window.localStorage) {
        return;
      }
      element.bind('submit', function(event) {
        $window.localStorage.setItem(MEDIATHEQUE_SCROLL_POSITION, $window.scrollY);
      });
    }
  };
}

/*@ngInject*/
function StoreScrollPositionOnClickDirective($window) {

  return {
    restrict: 'A',
    link: function(scope, element, attrs) {
      if (!$window.localStorage) {
        return;
      }
      element.bind('click', function(event) {
        $window.localStorage.setItem(MEDIATHEQUE_SCROLL_POSITION, $window.scrollY);
      });
    }
  };
}

/*@ngInject*/
function ScrollToPositionDirective($window, $timeout) {

  return {
    restrict: 'A',
    link: function(scope, element, attrs) {
      if (!$window.localStorage) {
        return;
      }
      var position = $window.localStorage.getItem(MEDIATHEQUE_SCROLL_POSITION);
      if (!position) {
        return;
      }
      $window.localStorage.removeItem(MEDIATHEQUE_SCROLL_POSITION);
      // On essaie de positionner dans la prochaine boucle d'exécution
      $timeout(function() {
        $window.scrollTo(0, position);
        // On vérifie (dans la prochaine boucle d'exécution) si la position est bonne, sinon on retente
        // (ca permet de laisser le temps à la page d'avoir son rendu final : cas d'un affichage conditionné par le JS)
        $timeout(function() {
          if ($window.scrollY != position) {
            $window.scrollTo(0, position);
          }
        });
      });
    }
  };
}
