(function(module) {
try {
  module = angular.module('app');
} catch (e) {
  module = angular.module('app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('illustration/dossiers-rattaches.html',
    '<div class=multivalued-input><div ng-repeat="dossier in dossiersRattachesCtrl.dossiers"><div class=row><div ng-class="{ \'col-sm-10\': dossiersRattachesCtrl.editable, \'col-sm-12\': !dossiersRattachesCtrl.editable }"><input type=text class=form-control name=dossiersGertrude-ref value="{{ dossier.reference + \' - \' + dossier.titre }}" placeholder=Dossier readonly></div><div class=col-sm-2 ng-if=dossiersRattachesCtrl.editable><span class=input-group-btn><button type=button class="btn btn-addon" ng-click=dossiersRattachesCtrl.edit(dossier)><span class="fa fa-pencil-square"></span></button></span></div><label class="control-label col-sm-3 col-lg-5" for="dossiersGertrude-legende-{{ $index }}">Légende dans le dossier</label><div class="col-sm-9 col-lg-7"><input type=text class=form-control name=dossiersGertrude-legende id="dossiersGertrude-legende-{{ $index }}" value="{{ dossier.legende }}" placeholder=Légende readonly></div></div></div></div>');
}]);
})();
