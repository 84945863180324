angular.module('app')
  .directive('mediathequeBookmarksMenu', BookmarksMenuDirective);

function BookmarksMenuDirective() {
  return {
    restrict: 'E',
    replace: true,
    controller: BookmarksMenuController,
    controllerAs: 'bookmarksCtrl',
    templateUrl: 'common/bookmarks-menu.html'
  };
}

/*@ngInject*/
function BookmarksMenuController($filter, $scope, BookmarkService) {
  var inputCtrl = this;

  inputCtrl.bookmarksAsList = [];
  computeBookmarks();

  $scope.$on('bookmarks-changed', computeBookmarks);

  function computeBookmarks() {
    inputCtrl.bookmarks = BookmarkService.getBookmarks();
    inputCtrl.bookmarksAsList = [];
    _.forEach(inputCtrl.bookmarks, function(value, key) {
      if (!value || !value.length) {
        return;
      }
      inputCtrl.bookmarksAsList.push({title: $filter('messageFormat')(key, 'entityType')});
      _.forEach(value, function(v) {
        inputCtrl.bookmarksAsList.push(v);
      });
    });
  }
}
