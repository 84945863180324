angular.module('app')
  .factory('RechercheExperteSuggest', RechercheExperteSuggest);

/*@ngInject*/
function RechercheExperteSuggest($http, $q, SuggestApiConfiguration) {
  return { search };

  function search(typeRecherche, query) {
    return $http({
      method: 'GET',
      cache: true,
      url: SuggestApiConfiguration.rechercheExperteSuggestUri,
      params: { query: query, typeRecherche: typeRecherche }
    });
  }
}
