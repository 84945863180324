(function(module) {
try {
  module = angular.module('app');
} catch (e) {
  module = angular.module('app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('common/multivalued-biblio-file-input.html',
    '<div class=multivalued-input><input type=hidden data-mediatheque-default-value=false value="{{ inputsCtrl.dirty }}" ng-attr-form="{{ form }}"><div ng-repeat="file in inputsCtrl.files"><div class=row--flex><mediatheque-biblio-file-input class=col-sm-10 mediatheque-id="{{ inputsCtrl.id }}" mediatheque-name="{{ inputsCtrl.name }}" mediatheque-form="{{ inputsCtrl.form }}" mediatheque-disabled=inputsCtrl.isDisabled() mediatheque-size-max="{{ inputsCtrl.sizeMax }}" mediatheque-initial-value=::file></mediatheque-biblio-file-input><div ng-if="! inputsCtrl.isDisabled()"><button type=button class="btn btn-transparent btn-input-actions" ng-click=inputsCtrl.removeFile(file) title="Supprimer un auteur de l\'œuvre"><span class="fa fa-times-circle"></span></button></div><div ng-if="! inputsCtrl.isDisabled() && $last && ($index + 1) < inputsCtrl.nbMax"><button type=button class="btn btn-transparent btn-input-actions" ng-click=inputsCtrl.addFile() title="Ajouter un fichier"><span class="fa fa-plus"></span></button></div></div></div></div>');
}]);
})();
