angular.module('app')
  .directive('mediathequeKeydown', KeydownDirective);

// La différence entre ngKeydown et mediathequeKeydown est que ngKeydown écoute l'événement keydown sur l'élément en cours
// alors que mediathequeKeydown écoute sur le document
/*@ngInject*/
function KeydownDirective($document, $parse) {
  return {
    restrict: 'A',
    link: function(scope, element, attrs) {
      var keydownExprGetter = $parse(attrs['mediathequeKeydown']);
      var onKeydown = ($event) => {
        scope.$apply(() => {
          keydownExprGetter(_.merge(scope, { $event }));
        });
      };
      // On écoute l'événement keydown sur le document
      $document.on('keydown', onKeydown);

      // Dès que l'élément est supprimé, on retire l'événement keydown sur le document
      scope.$on('$destroy', () => $document.off('keydown', onKeydown));
    }
  };
}
