angular.module('app')
  .factory('ExpertSearch', ExpertSearch);

/*@ngInject*/
function ExpertSearch($rootScope, $http, initData, ExpertSearchApiConfiguration, LocationService, NoticeNavigationService) {
  var results = [];
  var currentSearch;
  var critereList = [];
  var currentPage = 1;
  var totalResults = 0;
  var moreResults = false;
  var notInitialized = true;
  var initialEmptySearch = {};

  var findChamp = _.memoize(function (nomChamp) {
    return _.find(initData.definitionRechercheExperte.champs, { nomChamp : nomChamp });
  });

  $rootScope.$on('expert-search-start', onSearchStart);

  $rootScope.$on('expert-search-next', onSearchNextPage);

  $rootScope.$on('expert-search-all', onSearchAll);

  $rootScope.$on('expert-search-filters-updated', updateCurrentSearch);

  $rootScope.$on('expert-search-tri-updated', updateCurrentTri);

  $rootScope.$on('expert-search-add-critere', addCritere);

  $rootScope.$on('expert-search-update-critere', updateCritere);

  $rootScope.$on('expert-search-remove-critere', removeCritere);

  $rootScope.$on('expert-search-update-champs-affichables', updateChampsAffichables);

  $rootScope.$on('expert-search-toggle-result', toggleResult);

  $rootScope.$on('expert-search-results-select-all', selectAllResults);

  $rootScope.$on('expert-search-results-unselect-all', unselectAllResults);

  $rootScope.$on('expert-search-reset', reset);

  $rootScope.$on('location-hash-changed', onHashChanged);

  initSearch();

  return { getResults, getNumberDisplayedResults, getTotalResults, getCurrentSearch, isSearchValid, hasMoreResults, allResultsSelected, atLeastOneResultSelected };

  function getResults() {
    return convertResultats(_.flatten(_.cloneDeep(results)), currentSearch.champs);
  }

  function getNumberDisplayedResults() {
    return _.flatten(results).length;
  }

  function getTotalResults() {
    return totalResults;
  }

  function hasMoreResults() {
    return moreResults;
  }

  function getCurrentSearch() {
    return _.cloneDeep(currentSearch);
  }

  function allResultsSelected() {
    return _.every(_.flatten(_.cloneDeep(results)), 'selected');
  }

  function atLeastOneResultSelected() {
    return _.some(_.flatten(_.cloneDeep(results)), 'selected');
  }

  function getUri(type) {
    switch (type) {
      case 'Biblio': return ExpertSearchApiConfiguration.searchBiblioUri;
      case 'Illustration': return ExpertSearchApiConfiguration.searchIllustrationUri;
      default: return null;
    }
  }

  function onSearchStart(event, type) {
    // On réinitialise la recherche
    currentPage = 1;
    results = [];
    totalResults = 0;
    moreResults = false;

    var uri = getUri(type);
    if (uri === null) {
      return;
    }

    updateLocationHash();
    search(uri, false);
  }

  function onSearchNextPage(event, type) {
    currentPage++;

    var uri = getUri(type);
    if (uri === null) {
      return;
    }

    search(uri, false);
  }

  function onSearchAll(event, type) {
    currentPage++;

    var uri = getUri(type);
    if (uri === null) {
      return;
    }

    search(uri, true);
  }

  function search(uri, all) {
    var page = currentPage;

    if (page === 1) {
      results = [];
      storeContext();
      $rootScope.$broadcast('expert-search-results-changed');
    }
    $rootScope.$broadcast('expert-search-loading');
    $http.post(uri, {
      rechercheExperte: _.cloneDeep(currentSearch),
      page: page,
      showAll: all
    }).then((response) => {
      results[page - 1] = response.data.results;
      totalResults = response.data.totalResults;
      moreResults = response.data.hasMoreResults;
      $rootScope.$broadcast('expert-search-successful');
      storeContext();
      $rootScope.$broadcast('expert-search-results-changed');
    }, () => {
      $rootScope.$broadcast('expert-search-error');
    });
  }

  function updateCurrentSearch(event, search) {
    validateSearch(search);

    $rootScope.$broadcast('expert-search-filters-changed');
  }

  function updateCurrentTri(event, champTri, ordreTri) {
    currentSearch.champTri = champTri;
    currentSearch.ordreTri = ordreTri || 'Asc';

    $rootScope.$broadcast('expert-search-filters-changed');
  }

  function addCritere(event, champ, typeCritere) {
    var newCritere = {
      id: _.uniqueId(),
      typeCritere: 'Field',
      champ: champ,
      operateur: null,
      data: {}
    };
    // Le premier critère ajouté est toujours de type And
    // Si l'utilisateur rajoute un second critère, le type du précédent critère sera modifié et le champ sélectionné sera ajouté dans le critère global
    if (hasOnlyTheFirstCritere()) {
      currentSearch.filtre.criteres.push(newCritere);
      currentSearch.filtre.typeCritere = typeCritere;
    } else {
      var critereId;
      currentSearch.filtre = {
        typeCritere: typeCritere,
        id: _.uniqueId(),
        // _.reject([...], _.isEmpty) => On supprime les valeurs "vides" pour gérer le cas où currentSearch.filter est vide
        criteres: _.reject([_.clone(currentSearch.filtre), newCritere], (critere) => _.isEmpty(_.omit(critere, 'id')))
      };
      critereList.push(newCritere);
    }

    critereList.push(newCritere);

    $rootScope.$broadcast('expert-search-filters-changed');
  }

  function updateCritere(event, critere) {
    var oldCritere = _.find(critereList, { id: critere.id });
    oldCritere.operateur = critere.operateur;
    oldCritere.data = critere.data;

    $rootScope.$broadcast('expert-search-filters-changed');
  }

  function removeCritere(event, critereId) {
    critereList = _.reject(critereList, { id: critereId });

    // Recherchons le critère à supprimer
    var parentFiltre = currentSearch.filtre;
    var currentFiltre = currentSearch.filtre;
    while (currentFiltre) {
      var critere = _.find(currentFiltre.criteres, { id: critereId });
      if (!critere) {
        parentFiltre = currentFiltre;
        // XXX: On suppose qu'il n'y a qu'un seul élément de type Et, Ou ou Sauf au maximum
        currentFiltre = _.first(_.reject(currentFiltre.criteres, { typeCritere: 'Field' }));
        continue;
      }

      // Dans le cas où nous sommes tout en haut de l'arbre
      if (parentFiltre === currentFiltre) {
        // L'élément à supprimer est le seul existant donc le filtre n'existe plus
        if (currentFiltre.criteres.length === 1) {
          currentSearch.filtre = { id: currentSearch.filtre.id };
          break;
        }
        // Si tous les enfants sont des éléments de type Field, on supprime l'élément et on modifie la racine en type Et
        if (_.every(currentFiltre.criteres, { typeCritere: 'Field' })) {
          currentFiltre.criteres = _.reject(currentFiltre.criteres, { id: critereId });
          currentFiltre.typeCritere = 'And';
          break;
        }

        // On prend l'opérateur fils et on le met tout en haut
        // XXX: On suppose qu'il n'y a qu'un seul élément de type Et, Ou ou Sauf au maximum et aussi qu'il n'y a qu'un seul é
        currentSearch.filtre = _.first(_.reject(currentFiltre.criteres, { typeCritere: 'Field' }))
        break;
      }

      // On prend tous les fils (sauf celui à supprimer) et on les ajoute au noeud parent
      parentFiltre.criteres = _.union(
        _.reject(currentFiltre.criteres, { id: critereId }),
        _.filter(parentFiltre.criteres, { typeCritere: 'Field' })
      );
      break;
    }

    $rootScope.$broadcast('expert-search-filters-changed');
  }

  function updateChampsAffichables(event, champs) {
    currentSearch.champs = _.chain(champs)
      .map('nomChamp')
      .filter()
      .value();

    $rootScope.$broadcast('expert-search-filters-changed');
  }

  function toggleResult(event, resultId) {
    var result = _.find(_.flatten(results), { id: resultId });
    result.selected = !result.selected;

    $rootScope.$broadcast('expert-search-results-changed');
  }

  function selectAllResults() {
    _.flatten(results).forEach((result) => result.selected = true);

    $rootScope.$broadcast('expert-search-results-changed');
  }

  function unselectAllResults() {
    _.flatten(results).forEach((result) => result.selected = false);

    $rootScope.$broadcast('expert-search-results-changed');
  }

  function hasOnlyTheFirstCritere() {
    return !_.isEmpty(currentSearch.filtre) && !_.isEmpty(currentSearch.filtre.criteres) &&
        currentSearch.filtre.criteres.length === 1 &&
        _.every(currentSearch.filtre.criteres, { typeCritere: 'Field' });
  }

  function reset() {
    results = [];
    critereList = [];
    currentPage = 1;
    moreResults = false;

    initEmptySearch();

    $rootScope.$broadcast('expert-search-reinitialized');
    $rootScope.$broadcast('expert-search-filters-changed');
    storeContext();
    $rootScope.$broadcast('expert-search-results-changed');
    updateLocationHash();
  }

  function convertResultats(resultats, champsAffiches) {
    return _.map(resultats, (resultat) => {
      var convertedResultat = {
        id: resultat.id,
        titre: resultat.titre,
        uri: resultat.uri,
        titrePrincipal: resultat.titrePrincipal,
        sousTitre: resultat.sousTitre,
        tooltip: resultat.tooltip,
        titreCourant: resultat.titreCourant || resultat.titre,
        denomination: resultat.denomination,
        lockStatus: resultat.lockStatus,
        bookmark: resultat.bookmark,
        selected: !!resultat.selected,
        vignetteUrl: resultat.vignetteUrl,
        pleinEcranUrl: resultat.pleinEcranUrl,
        resourceUrls: resultat.resourceUrls,
        type: resultat.type,
        entityType: resultat.entityType,
        data: []
      };
      // On récupère les valeurs de chaque champ qui doit être affiché
      _.forEach(champsAffiches, (nomChamp) => {
        var champ = findChamp(nomChamp);
        var result = _.find(resultat.resultats, { champ: champ.libelleChamp });
        if (_.isEmpty(result)) {
          convertedResultat.data.push({
            champ: champ
          });
          return;
        }

        // Toutes les valeurs sont dans "stringValueUn"
        convertedResultat.data.push({
          champ: champ,
          value: _.filter(result.datas)
        });
      });
      return convertedResultat;
    });
  }

  function onHashChanged() {
    results = [];
    critereList = [];
    currentPage = 1;
    moreResults = false;

    initSearch();

    $rootScope.$broadcast('expert-search-reinitialized');
    $rootScope.$broadcast('expert-search-filters-changed');
    storeContext();
    $rootScope.$broadcast('expert-search-results-changed');
  }

  function initSearch() {
    var hash = LocationService.getHash();
    var launch = LocationService.getSearch('launch');
    if (! _.isEmpty(hash)) {
      try {
        validateSearch(JSON.parse(decodeURIComponent(atob(hash))));
        // On lance la recherche si on vient de sauvegarder la recherche
        if (launch && !notInitialized && currentSearch.type) {
          onSearchStart(null, currentSearch.type)
        }
        return;
      } catch (e) {
        // le JSON dans la barre d'adresse est mal formatté
        // XXX: Afficher une alerte ?
        console.log(e);
      }
    }

    var tmpInitSearch;
    if (initData.initialSearch) {
      tmpInitSearch = _.merge(
        initData.initialSearch || {},
        { champs: _.chain(initData.definitionRechercheExperte.champs)
                    .filter('visibleColumn')
                    .map('nomChamp')
                    .value() });
    } else {
      tmpInitSearch = {
        type: initData.definitionRechercheExperte.type,
        champs: _.chain(initData.definitionRechercheExperte.champs)
            .filter('visibleColumn')
            .map('nomChamp')
            .value()
      };
    }
    validateSearch(tmpInitSearch);
    initialEmptySearch = _.cloneDeep(currentSearch);
    if (initData.initialSearch) {
      $rootScope.$emit('expert-search-start', initData.initialSearch.type);
    }
  }

  function initEmptySearch() {
    validateSearch(_.cloneDeep(initialEmptySearch));
  }

  function validateSearch(search) {
    currentSearch = search;

    // réinitialisation de la liste de critères
    critereList = [];
    var currentFiltre = currentSearch.filtre;
    if (!currentFiltre) {
      notInitialized = false;
      return;
    }

    currentFiltre.id = notInitialized ? _.uniqueId() : (currentFiltre.id || _.uniqueId());
    while (currentFiltre) {
      // On veille à ce que les ID soient bien mis en place
      // On écrase pour etre sur que les ID proviennent bien de _.uniqueId() de manière à ne pas avoir de duplicats
      _.forEach(currentFiltre.criteres, (critere) => {
        critere.id = notInitialized ? _.uniqueId() : (critere.id || _.uniqueId());
      });

      critereList = _.union(critereList, _.filter(currentFiltre.criteres, { typeCritere: 'Field' }));
      // XXX: On suppose qu'il n'y a qu'un seul élément de type Et, Ou ou Sauf au maximum
      currentFiltre = _.first(_.reject(currentFiltre.criteres, { typeCritere: 'Field' }));
    }
    notInitialized = false;
  }

  function isSearchValid() {
    // On suppose que la recherche n'a pas été créé depuis une autre source que le formulaire principal
    if (_.isEmpty(currentSearch.filtre)) {
      return false;
    }

    // Vérifions que tous les filtres sont valides
    var currentFiltre = currentSearch.filtre;
    while (currentFiltre) {
      if (_.isEmpty(currentFiltre.criteres)) {
        return false;
      }

      for (let critere of currentFiltre.criteres) {
        if (critere.typeCritere !== 'Field') {
          continue;
        }

        if (!critere.operateur) {
          return false;
        }

        if (!isCritereDataValid(critere)) {
          return false;
        }
      }

      currentFiltre = _.first(_.reject(currentFiltre.criteres, { typeCritere: 'Field' }));
    }

    return true;
  }

  function isCritereDataValid(critere) {
    if (isOperatorUnary(critere.operateur)) {
      return true;
    }
    // On vérifie qu'il y a au moins une valeur non vide dans l'objet critere.data
    return _.filter(_.values(critere.data)).length > 0;
  }

  function isOperatorUnary(operator) {
    return _.contains(initData.unaryOperators, operator);
  }

  function updateLocationHash() {
    if (_.isEqual(currentSearch, initialEmptySearch)) {
      $rootScope.$emit('location-hash-set', '');
      return;
    }
    $rootScope.$emit('location-hash-set', btoa(encodeURIComponent(JSON.stringify(currentSearch))));
  }

  function storeContext() {
    let context = _.map(_.flatten(results), r => {
      return {
        id: r.id,
        titre: r.titre,
        uri: r.uri
      }
    });
    NoticeNavigationService.setContext(context);
  }
}
