(function(module) {
try {
  module = angular.module('app');
} catch (e) {
  module = angular.module('app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('common/datepicker-input.html',
    '<div ng-class="{ \'input-group\': ! disabled()}"><input type=text class=form-control name="{{ name }}" id="{{ id }}" ng-model=model ng-change=inputCtrl.onChange() ng-attr-form="{{ form }}" placeholder="{{ placeholder }}" data-mediatheque-default-value="{{ ::(model | date:\'dd/MM/yyyy\') }}" datepicker-popup=dd/MM/yyyy is-open=inputCtrl.opened ng-readonly=disabled()> <span class=input-group-btn ng-if="! disabled()"><button type=button class="btn btn-addon" ng-click=inputCtrl.openDatepicker($event)><span class="fa fa-calendar"></span></button></span></div>');
}]);
})();
