(function(module) {
try {
  module = angular.module('app');
} catch (e) {
  module = angular.module('app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('common/lien-input.html',
    '<div><div class=input-group><input type=url class=form-control id="{{ ::inputCtrl.id }}" name="{{ ::inputCtrl.name }}" ng-disabled=inputCtrl.isDisabled() ng-model=inputCtrl.value mediatheque-default-value="{{ ::inputCtrl.value }}" mediatheque-autofocus=applyFocus()> <span class=input-group-btn><a ng-href="{{ inputCtrl.value }}" ng-disabled=!inputCtrl.value target=_blank class="btn btn-addon" title="Se rendre à l\'adresse {{ inputCtrl.value }}"><span class="fa fa-external-link"></span></a></span></div></div>');
}]);
})();
