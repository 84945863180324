angular.module('app')
  .factory('LotElementsService', LotElementsService);

/*@ngInject*/
function LotElementsService($rootScope, $http, LotApiConfiguration, initData, NoticeNavigationService) {
  var initialElements = angular.copy(initData.elementsInLot);
  var elements = angular.copy(initialElements);
  storeContext();
  for (let i = 0; i < elements.length; i++) {
    elements[i].initialOrderId = elements[i].orderId = i;
  }
  var lotId = initData.lotId;
  var totalElements = initData.totalElements;
  var moreElements = initData.hasMoreElements;
  var currentPage = 1;

  var positionDirty = false;

  var findElement = _.memoize(function(id) {
    return _.find(elements, { id });
  });

  $rootScope.$on('lot-element-moved', handleOrderChanged);
  $rootScope.$on('lot-element-selected', handleSelected);
  $rootScope.$on('lot-element-unselected', handleUnselected);
  $rootScope.$on('lot-element-remove', handleRemove);
  $rootScope.$on('lot-element-remove-selected', handleRemoveSelected);
  $rootScope.$on('lot-elements-next', onLoadNextPage);
  $rootScope.$on('lot-elements-all', onLoadAll);

  return { getElements, isPositionDirty, getNumberDisplayedElements, getTotalElements, hasMoreElements, getNbLoaded };

  function getNumberDisplayedElements() {
    return _.flatten(elements).length;
  }

  function getNbLoaded() {
    return initialElements.length;
  }

  function getTotalElements() {
    return totalElements;
  }

  function hasMoreElements() {
    return moreElements;
  }

  function onLoadNextPage(event, type) {
    currentPage++;
    load(false);
  }

  function onLoadAll(event, type) {
    currentPage++;
    load(true);
  }

  function isPositionDirty() {
    return positionDirty;
  }

  function getElements() {
    return _.cloneDeep(elements);
  }

  function load(all) {
    var page = currentPage;

    $rootScope.$broadcast('lot-elements-loading');
    $http.get(LotApiConfiguration.lotPageUri, {
      params: { id: lotId,
                page: page,
                all: all }
    }).then((response) => {
      var elementsTmp = response.data.elementsInLot;
      var initialElementsLength = initialElements.length;

      Array.prototype.push.apply(initialElements, angular.copy(elementsTmp));
      for (let i = 0; i < elementsTmp.length; i++) {
        elementsTmp[i].initialOrderId = elementsTmp[i].orderId = initialElementsLength + i;
      }
      Array.prototype.push.apply(elements, elementsTmp);

      moreElements = response.data.hasMoreElements;
      $rootScope.$broadcast('lot-elements-successful');
      storeContext();
      $rootScope.$broadcast('lot-elements-changed');
    }, () => {
      $rootScope.$broadcast('lot-elements-error');
    });
  }

  function handleOrderChanged(event, newOrder) {
    positionDirty = true;

    var newElements = [];
    for (let i = 0; i < elements.length; i++) {
      var initialElement = initialElements[newOrder[i]];
      var element = findElement(initialElement.id);
      element.orderId = i;
      newElements.push(element);
    }
    elements = newElements;

    // Pas de storeContext, c'est après la sauvegarde du lots qu'on prend en compte la modification
    $rootScope.$broadcast('lot-elements-changed');
  }

  function handleSelected(event, elementId) {
    var element = findElement(elementId);
    element.selected = true;
    $rootScope.$broadcast('lot-elements-changed');
  }

  function handleUnselected(event, elementId) {
    var element = findElement(elementId);
    element.selected = false;
    $rootScope.$broadcast('lot-elements-changed');
  }

  function handleRemove(event, elementId) {
    positionDirty = true;
    elements = _.reject(elements, { id: elementId });
    totalElements--;

    // Pas de storeContext, c'est après la sauvegarde du lots qu'on prend en compte la modification
    $rootScope.$broadcast('lot-elements-changed');

    if (elements.length === 0 && moreElements) {
      currentPage++;
      load(false);
    }
  }

  function handleRemoveSelected() {
    positionDirty = true;
    var initialElementsLength = elements.length;
    elements = _.reject(elements, 'selected');
    var newElementsLength = elements.length;
    totalElements -= (initialElementsLength - newElementsLength);
    // Pas de storeContext, c'est après la sauvegarde du lots qu'on prend en compte la modification
    $rootScope.$broadcast('lot-elements-changed');
  }

  function storeContext() {
    let context = _.map(elements, r => {
      return {
        id: r.id,
        titre: r.titre,
        uri: r.uri
      }
    });
    NoticeNavigationService.setContext(context);
  }
}
