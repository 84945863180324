angular.module('app')
  .directive('mediathequeAuteurbiblioInput', AuteurbiblioInputDirective);

function AuteurbiblioInputDirective() {
  return {
    restrict: 'E',
    scope: {
      name: '@mediathequeName',
      id: '@mediathequeId',
      form: '@mediathequeForm',
      placeholder: '@mediathequePlaceholder',
      disabled: '&mediathequeDisabled',
      model: '=ngModel',
      initialValue: '&mediathequeInitialValue',
      onChange: '&mediathequeOnChange',
      applyFocus: '&mediathequeApplyFocus'
    },
    replace: true,
    controller: AuteurbiblioInputController,
    controllerAs: 'inputCtrl',
    templateUrl: 'common/personne-input.html'
  };
}

/*@ngInject*/
function AuteurbiblioInputController($scope, EntitySuggest) {
  var inputCtrl = this;

  inputCtrl.getSuggestions = getSuggestions;
  inputCtrl.hasValidValue = hasValidValue;
  inputCtrl.onChange = $scope.onChange;

  init();

  // TODO: Ajouter un watcher sur $scope.model (couplé à un $timeout pour s'assurer que le scope est bien à jour)
  // si le model initial est modifié par le composant parent

  function init() {
    if ($scope.model) {
      return;
    }
    $scope.model = $scope.initialValue() || { libelle: "" };
  }

  function hasValidValue() {
    if ($scope.model === '') {
      return true;
    }
    if ($scope.model.id) {
      return true;
    }
    return $scope.model.libelle === '' || $scope.model.libelle === null;
  }

  inputCtrl.loading = false;
  function getSuggestions(currentValue) {
    if ($scope.disabled()) {
      return;
    }
    inputCtrl.loading = true;
    return EntitySuggest.search("AuteurBiblio", currentValue)
      .then((result) => {
        inputCtrl.loading = false;
        return result.data;
      });
  }
}
