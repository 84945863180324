angular.module('app')
  .factory('XWwwFormUrlEncoded', XWwwFormUrlEncoded);

/*@ngInject*/
function XWwwFormUrlEncoded() {
  return { transform };

  function transform(data) {
    var params = [];
    angular.forEach(data, function (value, key) {
      if (!value) {
        return;
      }
      if (_.isArray(value)) {
        _.forEach(value, function(v) {
          params.push(encodeURIComponent(key) + "=" + encodeURIComponent(v));
        });
      } else {
        params.push(encodeURIComponent(key) + "=" + encodeURIComponent(value));
      }
    });
    return params.join('&');
  }
}
