(function(module) {
try {
  module = angular.module('app');
} catch (e) {
  module = angular.module('app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('common/notice-navigation.html',
    '<div><div class="well clearfix" ng-if=!noticeNavigationCtrl.disabled><div class=btn-group-justified><a class="btn btn-sm btn-block btn-default" ng-disabled=!noticeNavigationCtrl.notices.previous title="{{ noticeNavigationCtrl.notices.previous.titre }}" href="{{ noticeNavigationCtrl.notices.previous.uri }}" mediatheque-store-scroll-on-click><span class="fa fa-chevron-left" mediatheque-store-scroll-on-click></span>&nbsp;Précédent</a> <a class="btn btn-sm btn-block btn-default" ng-disabled=!noticeNavigationCtrl.notices.next title="{{ noticeNavigationCtrl.notices.next.titre }}" href="{{ noticeNavigationCtrl.notices.next.uri }}" mediatheque-store-scroll-on-click>Suivant&nbsp;<span class="fa fa-chevron-right"></span></a></div></div></div>');
}]);
})();
