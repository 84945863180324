angular.module('app')
  .directive('mediathequeMultivaluedBiblio', MultivaluedBiblioDirective);

/*@ngInject*/
function MultivaluedBiblioDirective(dragularService, $rootScope) {
  return {
    restrict: 'E',
    scope: {
      name: '@mediathequeName',
      orderForm: '@mediathequeOrderForm',
      selectForm: '@mediathequeSelectForm',
      createLotUri: '@mediathequeCreateLotUri',
      searchLotUri: '@mediathequeSearchLotUri',
      disabled: '&mediathequeDisabled'
    },
    replace: true,
    controller: MultivaluedBiblioController,
    controllerAs: 'inputsCtrl',
    templateUrl: 'common/multivalued-biblio.html',
    link: function(scope, element, attrs) {
      if (scope.disabled()) {
        return;
      }
      var mosaicGroup = element[0].querySelector('[data-mediatheque-orderable]');
      dragularService(angular.element(mosaicGroup), {
        nameSpace: 'mediathequeLot',
        scope: scope,
        moves: (el, container, handle) => {
          // Il faut laisser les boutons et les champs accessibles
          return !_.contains(['BUTTON', 'INPUT'], handle.tagName);
        }
      });
      scope.$on('drop', (event, element, container, source) => {
        scope.$apply(() => {
          var newOrder = _.map(source.children, (child) => {
            return parseInt(child.getAttribute('data-mediatheque-order'));
          });
          $rootScope.$emit('lot-element-moved', newOrder);
        });
      });
    }
  };
}

/*@ngInject*/
function MultivaluedBiblioController($scope, $rootScope, LotElementsService) {
  var inputsCtrl = this;

  inputsCtrl.removeValue = removeValue;
  inputsCtrl.onSelectChange = onSelectChange;
  inputsCtrl.selectAll = selectAll;
  inputsCtrl.selectNone = selectNone;
  inputsCtrl.oneSelected = oneSelected;
  inputsCtrl.allSelected = allSelected;
  inputsCtrl.removeSelection = removeSelection;
  inputsCtrl.hasMoreElements = LotElementsService.hasMoreElements;
  inputsCtrl.displayNextElements = displayNextElements;
  inputsCtrl.displayAllElements = displayAllElements;

  $scope.$on('lot-elements-changed', handleElementsChanged);
  $scope.$on('lot-elements-loading', handleElementsLoading);
  $scope.$on('lot-elements-successful', handleElementsLoaded);
  $scope.$on('lot-elements-error', handleElementsLoaded);

  handleElementsChanged();
  handleElementsLoaded();

  function removeValue(valueToRemove) {
    $rootScope.$emit('lot-element-remove', valueToRemove.biblio.id);
  }
  function removeSelection() {
    $rootScope.$emit('lot-element-remove-selected');
  }
  function onSelectChange(value) {
    if (!value.biblio.selected) {
      $rootScope.$emit('lot-element-unselected', value.biblio.id);
    } else {
      $rootScope.$emit('lot-element-selected', value.biblio.id);
    }
  }
  function selectNone() {
    _.forEach(inputsCtrl.values, function(n, key) {
      $rootScope.$emit('lot-element-unselected', n.biblio.id);
    });
  }
  function selectAll() {
    _.forEach(inputsCtrl.values, function(n, key) {
      $rootScope.$emit('lot-element-selected', n.biblio.id);
    });
  }
  function displayAllElements() {
    $rootScope.$emit('lot-elements-all');
  }
  function displayNextElements() {
    $rootScope.$emit('lot-elements-next');
  }
  function oneSelected() {
    return _.some(inputsCtrl.values, (value) => {
      return value.biblio.selected;
    });
  }
  function allSelected() {
    return _.every(inputsCtrl.values, (value) => {
      return value.biblio.selected;
    });
  }

  function handleElementsChanged() {
    inputsCtrl.values = getBibliosFromService();
    inputsCtrl.positionDirty = LotElementsService.isPositionDirty();
    inputsCtrl.allLoaded = ! LotElementsService.hasMoreElements();
    inputsCtrl.nbLoaded = LotElementsService.getNbLoaded();
    inputsCtrl.numberDisplayedElements = LotElementsService.getNumberDisplayedElements();
    inputsCtrl.totalElements = LotElementsService.getTotalElements();
  }

  function getBibliosFromService() {
    return _.map(LotElementsService.getElements() || [], (value) => {
        return { biblio: value };
    });
  }

  function handleElementsLoading() {
    inputsCtrl.loading = true;
  }

  function handleElementsLoaded() {
    inputsCtrl.loading = false;
    inputsCtrl.loaded = true;
  }
}
