angular.module('app')
  .directive('mediathequeFileUri', FileUriDirective);

/*@ngInject*/
function FileUriDirective(ImageIllustrationGet) {
  return {
    restrict: 'A',
    link: function(scope, jqElement, attrs, ctrl) {
    
      var attr = attrs['mediathequeFileUri'];
      var illustrationId = attrs['mediathequeFileUriId'];
      var format = attrs['mediathequeFileUriFormat'];

      scope.$on('illustration.image.changed', setValue);
      function setValue(event, eventIllustrationId, eventFormat) {
        if (illustrationId !== eventIllustrationId || format !== eventFormat) {
          return;
        }
        var cache = ImageIllustrationGet.get(illustrationId, format);
        
        if (attr) {
          if (cache && cache.uri) {
            jqElement[0][attr] = cache.uri;
            jqElement.removeClass("ng-hide");
          } else {
            jqElement.addClass("ng-hide");
          }
        } else {
          if (cache && cache.uri) {
            jqElement.addClass("ng-hide");
          } else {
            jqElement.removeClass("ng-hide");
          }
        }
      }
    }
  };
}
