angular.module('app')
  .service('SimpleSearchPictureSliderSupplier', SimpleSearchPictureSliderSupplier);

/*ngInject*/
function SimpleSearchPictureSliderSupplier($rootScope, IllustrationResultsService, $q, $timeout) {
  return { getImages, canGetMoreImages, loadMoreImages };

  function getImages() {
    return _.map(IllustrationResultsService.getResults(), (result) => {
      return {
        illustrationId: result.id,
        imageUrl: result.pleinEcranUrl,
        titreCourant: result.titreCourant || result.titre,
        denominations: result.denomations,
        commune: result.sousTitre,
        numi: result.titrePrincipal,
        illustrationUrl: result.uri,
        type: result.type,
        entityType: result.entityType,
      };
    });
  }

  function canGetMoreImages() {
    return IllustrationResultsService.hasMoreResults();
  }

  function loadMoreImages() {
    $rootScope.$emit('illustration-results-next');
    var listenerDeregistration;
    return $q((resolve, reject) => {
      // This listener should be removed after execution
      listenerDeregistration = $rootScope.$on('illustration-search-successful', () => {
        resolve();

        listenerDeregistration();
      });
    });
  }
}
