angular.module('app')
  .factory('RefDocFormModal', RefDocFormModal);

/*@ngInject*/
function RefDocFormModal($q, $modal, $window, RefDocService, initData) {
  return {
    createNewRefDoc: openModal,
    updateRefDoc: openModal,
  };

  function openModal(illustrationId) {
    return $modal.open({
      animation: true,
      size: 'lg',
      templateUrl: 'illustration/ref-doc-form-modal.html',
      controller: RefDocFormModalCtrl,
      controllerAs: 'refDocModalCtrl',
      resolve: {
        initialRefDoc: () => {
          var oldRefDoc = RefDocService.get();
          if (oldRefDoc && oldRefDoc.id) {
            return RefDocService.lockAndGet(oldRefDoc.id, illustrationId)
                .then(() => {
                  return RefDocService.get();
                });
          }
          return oldRefDoc;
        },
        illustrationId: () => illustrationId,
        refDocReprise: () => {
          return initData.referenceDocumentaireReprise;
        }
      }
    }).result;
  }
}

function RefDocFormModalCtrl($scope, $modalInstance, initialRefDoc, illustrationId, refDocReprise, RefDocService) {
  var refDocModalCtrl = this;

  refDocModalCtrl.submit = submit;
  refDocModalCtrl.cancel = cancel;
  refDocModalCtrl.notProcessing = notProcessing;

  init();

  $scope.$on('modal.closing', (event, reason, closed) => {
    if (!initialRefDoc || closed || refDocModalCtrl.fullyCancelled) {
      return;
    }

    // Il faut veiller à ce que la refdoc soit correctement annulée
    event.preventDefault();

    cancel();
  });

  function submit() {
    refDocModalCtrl.closing = true;
    refDocModalCtrl.error = null;

    var promise;
    if (initialRefDoc) {
      promise = RefDocService.update(refDocModalCtrl.refDoc, illustrationId);
    } else {
      promise = RefDocService.create(refDocModalCtrl.refDoc, illustrationId);
    }

    promise.then(() => {
      refDocModalCtrl.closing = false;
      $modalInstance.close();
    }, (error) => {
      refDocModalCtrl.closing = false;
      refDocModalCtrl.error = error;
    });
  }

  function cancel() {
    if (!initialRefDoc) {
      $modalInstance.dismiss();
      return;
    }

    refDocModalCtrl.dismissing = true;
    refDocModalCtrl.error = null;

    RefDocService.cancel(initialRefDoc.id, illustrationId)
      .then(() => {
        refDocModalCtrl.fullyCancelled = true;
        $modalInstance.dismiss();
      }, (error) => {
        refDocModalCtrl.dismissing = false;
        refDocModalCtrl.error = error;
        refDocModalCtrl.error.cancel = true;
      })
  }

  function notProcessing() {
    return !refDocModalCtrl.dismissing && !refDocModalCtrl.closing;
  }

  function init() {
    refDocModalCtrl.refDoc = _.cloneDeep(initialRefDoc) || {};
    refDocModalCtrl.reprise = refDocReprise || {}
  }
}
