angular.module('app')
  .factory('ModificationEnMasseModalService', ModificationEnMasseModalService);

/*@ngInject*/
function ModificationEnMasseModalService($modal) {
  return { openRechercherRemplacerModal, openValoriserModal };

  function openRechercherRemplacerModal() {
    return openModal(true);
  }

  function openValoriserModal() {
    return openModal(false);
  }

  function openModal(enableRecherche) {
    return $modal.open({
      animation: true,
      size: 'lg',
      templateUrl: 'common/modification-en-masse-modal.html',
      controller: ModificationEnMasseModalCtrl,
      controllerAs: 'modificationEnMasseModalCtrl',
      resolve: { enableRecherche: () => enableRecherche }
    }).result;
  }
}

function ModificationEnMasseModalCtrl($scope, $modalInstance, $filter, enableRecherche, initData, SecurityConfiguration) {
  const collator = new Intl.Collator('fr');

  var modificationEnMasseModalCtrl = this;

  modificationEnMasseModalCtrl.actionUri = initData.rechercheRemplacerUri;
  modificationEnMasseModalCtrl.enableRecherche = enableRecherche;
  modificationEnMasseModalCtrl.rechercherChamp = { libelle: '' };
  modificationEnMasseModalCtrl.rechercherValeur = { libelle: '' };
  modificationEnMasseModalCtrl.remplacerValeur = { libelle: '' };
  modificationEnMasseModalCtrl.xsrf = SecurityConfiguration['_xsrf_'];

  modificationEnMasseModalCtrl.publish = isLockableEntity();

  modificationEnMasseModalCtrl.getAvailableChamps = _.memoize(getAvailableChamps);
  modificationEnMasseModalCtrl.isSubmitButtonDisabled = isSubmitButtonDisabled;
  modificationEnMasseModalCtrl.cancel = cancel;
  modificationEnMasseModalCtrl.isLockableEntity = isLockableEntity;

  function isLockableEntity() {
    return initData.rechercheRemplacerDefinition.type == 'Illustration';
  }

  function getAvailableChamps($viewValue) {
    return _.chain(initData.rechercheRemplacerDefinition.champs)
      .map((champ) => {
        let libelle;
        if (champ.fieldLabel != null) {
          libelle = $filter('messageFormat')(champ.fieldLabel, 'field');
        } else {
          libelle = $filter('messageFormat')(`${champ.parentPath}.${champ.nomChamp}`, 'field');
          if (libelle.startsWith("#{")) { // on a pas trouvé de libellé correspond à parentPath.nomChamps
            libelle = $filter('messageFormat')(champ.nomChamp, 'field')
          }
        }
        return _.merge(champ, { libelle }); })
      .filter((champ) => {
        return _.includes(champ.libelle.toLowerCase(), ($viewValue || '').toLowerCase());
      })
      .value()
      // sort() n'est pas fonctionnel car il ne prend pas en compte les accents.
      // en utilisant le Collator pour la langue française, on s'assure que l'ordre naturel est conservé
      .sort((o1, o2) => {
        return collator.compare(o1.libelle, o2.libelle);
      });
  }

  function isSubmitButtonDisabled() {
    // On bloque le bouton submit si aucun champ n'a été sélectionné ou si la recherche est vide
    // On ne bloque pas si on remplace par des données vides
    return !modificationEnMasseModalCtrl.rechercherChamp.nomChamp || (modificationEnMasseModalCtrl.enableRecherche && isValeurEmpty(modificationEnMasseModalCtrl.rechercherValeur));
  }

  function isValeurEmpty(valeur) {
    if (!modificationEnMasseModalCtrl.rechercherChamp) {
      return true;
    }

    switch (modificationEnMasseModalCtrl.rechercherChamp.typeChamp) {
      case 'Adresse':
        return isDataEmpty(valeur) || (isDataEmpty(valeur.thesaurusFieldValueUn) && isDataEmpty(valeur.thesaurusFieldValueDeux) && isDataEmpty(valeur.stringValueUn));
      case 'LieuDit':
        return isDataEmpty(valeur) || (isDataEmpty(valeur.thesaurusFieldValueUn) && isDataEmpty(valeur.thesaurusFieldValueDeux));
      case 'Integer':
        return !_.isNaN(parseInt(valeur));
      case 'Boolean':
        return !valeur;
      default:
        return isDataEmpty(valeur);
    }
  }

  function isDataEmpty(value) {
    if (value && value.dateValueUn instanceof Date) {
      return false;
    }
    if (_.isObject(value)) {
      return _.isEmpty(_.omit(value, _.isEmpty));
    }
    return _.isEmpty(value);
  }

  function cancel() {
    $modalInstance.dismiss();
  }
}
