(function(module) {
try {
  module = angular.module('app');
} catch (e) {
  module = angular.module('app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('common/app-notifications.html',
    '<div class=notifications><div class=notifications-container><div ng-repeat="notification in notificationsCtrl.getVisibleNotifications()" class="alert alert-{{notification.type}} alert-dismissable notification" ng-class="{ \'am-fade\': notification.fadeOn.enter && notification.fadeOn.leave, \'am-fade-enter\': notification.fadeOn.enter && !notification.fadeOn.leave, \'am-fade-leave\': !notification.fadeOn.enter && notification.fadeOn.leave }"><button type=button class=close aria-hidden=true ng-click=notificationsCtrl.close(notification)>&times;</button><mediatheque-app-notification data-mediatheque-notification=notification></mediatheque-app-notification></div></div></div>');
}]);
})();
