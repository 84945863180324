angular.module('app')
  .directive('mediathequeMultivaluedExemplaireInput', MultivaluedExemplaireInputDirective);

function MultivaluedExemplaireInputDirective() {
  return {
    restrict: 'E',
    scope: {
      name: '@mediathequeName',
      id: '@mediathequeId',
      form: '@mediathequeForm',
      disabled: '&mediathequeDisabled',
      initialValues: '&mediathequeInitialValues'
    },
    replace: true,
    controller: MultivaluedExemplaireInputController,
    controllerAs: 'inputsCtrl',
    templateUrl: 'common/multivalued-exemplaire-input.html'
  };
}

/*@ngInject*/
function MultivaluedExemplaireInputController($scope) {
  var inputsCtrl = this;

  inputsCtrl.removeExemplaire = removeExemplaire;
  inputsCtrl.addExemplaire = addExemplaire;

  init();

  // TODO: Ajouter un watcher sur $scope.model (couplé à un $timeout pour s'assurer que le scope est bien à jour)
  // si le model initial est modifié par le composant parent

  function addExemplaire() {
    inputsCtrl.exemplaires.push({ numero: null, localisation: null, nomEmprunt: null, dateEmprunt: null, precisionEmprunt: null, cote: null });
    inputsCtrl.dirty = true;
    inputsCtrl.newlyAdded = true;
  }

  function removeExemplaire(exemplaireToRemove) {
    inputsCtrl.exemplaires = _.without(inputsCtrl.exemplaires, exemplaireToRemove);

    // Il faut au moins un champs affiché
    if (inputsCtrl.exemplaires.length === 0) {
      inputsCtrl.exemplaires = [{ numero: null, localisation: null, nomEmprunt: null, dateEmprunt: null, precisionEmprunt: null, cote: null }];
    }
    inputsCtrl.dirty = true;
    inputsCtrl.newlyAdded = false;
  }

  function init() {
    inputsCtrl.exemplaires = ($scope.initialValues() && $scope.initialValues().length !== 0) ? $scope.initialValues() : [{ numero: null, localisation: null, nomEmprunt: null, dateEmprunt: null, precisionEmprunt: null, cote: null }];
    inputsCtrl.dirty = false;
    inputsCtrl.newlyAdded = false;
  }
}
