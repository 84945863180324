angular.module('app')
  .directive('mediathequeExpertSearchFilters', ExpertSearchFiltersDirective);

function ExpertSearchFiltersDirective() {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      typeRecherche: '@mediathequeTypeRecherche',
      currentSearch: '&mediathequeCurrentSearch'
    },
    controller: ExpertSearchFiltersController,
    controllerAs: 'expertSearchFiltersCtrl',
    templateUrl: 'common/expert-search-filters.html'
  };
}

/*@ngInject*/
function ExpertSearchFiltersController($scope, $rootScope, $timeout, initData, $filter, StringComparator) {
  const collator = new Intl.Collator('fr');
  var expertSearchFiltersCtrl = this;

  expertSearchFiltersCtrl.currentSearch = {};
  expertSearchFiltersCtrl.searchJSON = '';
  expertSearchFiltersCtrl.champCritere = { libelle: '' };
  expertSearchFiltersCtrl.flatReversedCriteres = [];
  expertSearchFiltersCtrl.typeCritere = 'And';
  $scope.$watch($scope.currentSearch, (value) => {
    expertSearchFiltersCtrl.searchJSON = angular.toJson(value || '{}', true);
    expertSearchFiltersCtrl.currentSearch = value;
    expertSearchFiltersCtrl.flatReversedCriteres = getFlatReversedCriteres();

    if (!value.filtre || !value.filtre.criteres || value.filtre.criteres.length === 0) {
      expertSearchFiltersCtrl.newChamp = true;
    }
  });

  expertSearchFiltersCtrl.onSearchChanged = onSearchChanged;
  expertSearchFiltersCtrl.onCritereChanged = onCritereChanged;
  expertSearchFiltersCtrl.getChampsFiltrables = _.memoize(getChampsFiltrables);
  expertSearchFiltersCtrl.addCritere = addCritere;
  expertSearchFiltersCtrl.prepareAddCritereForm = prepareAddCritereForm;

  var findChamp = _.memoize(function (nomChamp) {
    return _.find(initData.definitionRechercheExperte.champs, { nomChamp : nomChamp });
  });

  function onSearchChanged() {
    // XXX: Ce code est temporaire : il devra être supprimé lorsque le textarea sera supprimé.
    try {
      var search = JSON.parse(expertSearchFiltersCtrl.searchJSON);
      $rootScope.$emit('expert-search-filters-updated', search);
    } catch (e) {
      console.log(e);
    }
  }

  function onCritereChanged() {
    $rootScope.$emit('expert-search-filters-updated', expertSearchFiltersCtrl.currentSearch);
  }

  function getChampsFiltrables($viewValue) {
    return _.chain(_.cloneDeep(initData.definitionRechercheExperte.champs))
          .filter('filtrable')
          .map((champ) => {
            return _.merge(champ, { libelle: $filter('messageFormat')(champ.libelleChamp, 'field') });
          })
          .filter((champ) => {
            return StringComparator.startsWith(champ.libelle, ($viewValue || ''));
          })
          .value()
          // sort() n'est pas fonctionnel car il ne prend pas en compte les accents.
          // en utilisant le Collator pour la langue française, on s'assure que l'ordre naturel est conservé
          .sort((c1, c2) => {
            if (c1.groupe != c2.groupe) {
              return c1.groupe - c2.groupe;
            }
            return collator.compare(c1.libelle, c2.libelle);
          });
  }

  /**
   * Retourne la liste des critères aplatie: chaque noeud de l'arbre (= les critères de type Et, Ou et Sauf) combiné à ses feuilles
   * (= les critères de type Field) est ajouté à une liste retournéee en toute fin.
   */
  function getFlatReversedCriteres() {
    var flatCriteres = [];
    var currentNoeud = expertSearchFiltersCtrl.currentSearch.filtre;
    while (!_.isEmpty(currentNoeud)) {
      flatCriteres.unshift({
        parent: _.omit(currentNoeud, 'criteres'),
        children: _.chain(currentNoeud.criteres)
            .filter({ typeCritere: 'Field' })
            .map((critere) => {
              return {
                critere: critere,
                champ: findChamp(critere.champ)
              };
            })
            .value()
      });
      currentNoeud = _.find(currentNoeud.criteres, _.negate(_.matches({ typeCritere: 'Field' })));
    }
    return flatCriteres;
  }

  function addCritere() {
    $rootScope.$emit('expert-search-add-critere', expertSearchFiltersCtrl.champCritere.nomChamp, expertSearchFiltersCtrl.typeCritere);
    $scope.$broadcast('form-input-ajout-critere-champ-empty');
    expertSearchFiltersCtrl.typeCritere = 'And';
    expertSearchFiltersCtrl.newChamp = false;
  }

  function prepareAddCritereForm() {
    expertSearchFiltersCtrl.newChamp = true;
  }
}
