angular.module('app')
  .directive('mediathequeBookmarkStatus', BookmarkStatusDirective);

function BookmarkStatusDirective() {
  return {
    restrict: 'E',
    scope: {
      bookmark: '&mediathequeBookmark'
    },
    replace: true,
    templateUrl: 'common/bookmark-status.html'
  };
}
