(function(module) {
try {
  module = angular.module('app');
} catch (e) {
  module = angular.module('app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('common/multivalued-contribution-ref-doc-input.html',
    '<div><input type=hidden data-mediatheque-default-value=false value="{{ inputsCtrl.dirty }}" ng-attr-form="{{ form }}"><div ng-repeat="contribution in model" class=contribution-container><div class=row--flex><div ng-class="{ \'col-sm-10\': ! disabled(), \'col-sm-12\': disabled() }"><mediatheque-contribution-ref-doc-input data-mediatheque-name="{{ name }}" mediatheque-id="{{ id + \'-\' + $index }}" data-mediatheque-disabled=disabled() ng-model=contribution mediatheque-apply-focus="$last && inputsCtrl.newlyAdded"></mediatheque-contribution-ref-doc-input></div><div ng-if="! disabled()"><button type=button class="btn btn-transparent btn-input-actions" ng-click=inputsCtrl.removeContribution(contribution) title="Supprimer une contribution"><span class="fa fa-times-circle"></span></button></div><div ng-if="! disabled() && $last"><button type=button class="btn btn-transparent btn-input-actions" ng-click=inputsCtrl.addContribution() title="Ajouter une contribution"><span class="fa fa-plus"></span></button></div></div></div></div>');
}]);
})();
