angular.module('app')
  .directive('mediathequeMultivaluedAuteuroeuvreIllustrationInput', MultivaluedAuteuroeuvreIllustrationInputDirective);

function MultivaluedAuteuroeuvreIllustrationInputDirective() {
  return {
    restrict: 'E',
    scope: {
      name: '@mediathequeName',
      id: '@mediathequeId',
      illustrationId: '@mediathequeIllustrationId',
      form: '@mediathequeForm',
      disabled: '&mediathequeDisabled',
      initialValues: '&mediathequeInitialValues'
    },
    replace: true,
    controller: MultivaluedAuteuroeuvreIllustrationInputController,
    controllerAs: 'inputsCtrl',
    templateUrl: 'common/multivalued-auteuroeuvre-illustration-input.html'
  };
}

/*@ngInject*/
function MultivaluedAuteuroeuvreIllustrationInputController($scope) {
  var inputsCtrl = this;

  inputsCtrl.addAuteuroeuvre = addAuteuroeuvre;
  inputsCtrl.removeAuteuroeuvre = removeAuteuroeuvre;

  init();

  // TODO: Ajouter un watcher sur $scope.model (couplé à un $timeout pour s'assurer que le scope est bien à jour)
  // si le model initial est modifié par le composant parent

  function addAuteuroeuvre() {
    inputsCtrl.auteuroeuvres.push({ libelle: "" });
    inputsCtrl.dirty = true;
    inputsCtrl.newlyAdded = true;
  }

  function removeAuteuroeuvre(auteuroeuvreToRemove) {
    inputsCtrl.auteuroeuvres = _.without(inputsCtrl.auteuroeuvres, auteuroeuvreToRemove);

    // Il faut au moins un champs affiché
    if (inputsCtrl.auteuroeuvres.length === 0) {
      inputsCtrl.auteuroeuvres = [{ professions: [{ libelle: ""}], auteurId: null }];
    }
    inputsCtrl.dirty = true;
    inputsCtrl.newlyAdded = false;
  }

  function init() {
    inputsCtrl.auteuroeuvres = ($scope.initialValues() && $scope.initialValues().length !== 0) ? $scope.initialValues() : [{ professions: [{ libelle: ""}], auteurId: null }];
    inputsCtrl.dirty = false;
    inputsCtrl.newlyAdded = false;
  }
}
