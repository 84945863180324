angular.module('app')
  .directive('mediathequeSelectInput', SelectInputDirective);

function SelectInputDirective() {
  return {
    restrict: 'E',
    scope: {
      id: '@mediathequeId',
      name: '@mediathequeName',
      inputClass: '@mediathequeInputClass',
      placeholder: '@mediathequePlaceholder',
      suggestions: '&mediathequeSuggestions',
      model: '=ngModel',
      initialValue: '&mediathequeInitialValue',
      onChange: '&mediathequeOnChange',
      applyFocus: '&mediathequeApplyFocus'
    },
    replace: true,
    controller: SelectInputController,
    controllerAs: 'inputCtrl',
    templateUrl: 'common/select-input.html'
  };
}

/*@ngInject*/
function SelectInputController($scope, $timeout, StringComparator) {
  const collator = new Intl.Collator('fr');

  var inputCtrl = this;

  inputCtrl.getSuggestions = getSuggestions;
  inputCtrl.onChange = onChange;

  init();

  // TODO: Ajouter un watcher sur $scope.model (couplé à un $timeout pour s'assurer que le scope est bien à jour)
  // si le model initial est modifié par le composant parent

  if ($scope.id) {
    $scope.$on(`form-input-${ $scope.id }-empty`, empty);
  }

  inputCtrl.loading = false;

  // Il se peut que le model ne soit pas encore bien initialisé
  // Il faut donc surveiller le model et attendre qu'il le soit
  $scope.$watch('model', () => {
    if (_.isString(inputCtrl.model) && !_.isEmpty(inputCtrl.model)) {
      return;
    }
    if (_.isObject(inputCtrl.model) && !_.isEmpty(inputCtrl.model.libelle)) {
      return;
    }
    inputCtrl.model = $scope.model;
  });

  function init() {
    if ($scope.model) {
      inputCtrl.model = $scope.model;
      return;
    }
    inputCtrl.model = $scope.model = $scope.initialValue();
  }

  function getSuggestions($viewValue) {
    var suggestions = $scope.suggestions();
    if (_.isFunction(suggestions)) {
      return suggestions($viewValue);
    }

    // On suppose que les éléments de la liste sont des chaînes de caractères
    return _.filter(suggestions, (suggestion) => {
          return StringComparator.startsWith(suggestion, ($viewValue || ''));
        })
        // sort() n'est pas fonctionnel car il ne prend pas en compte les accents.
        // en utilisant le Collator pour la langue française, on s'assure que l'ordre naturel est conservé
        .sort((suggestion1, suggestion2) => {
          return collator.compare(suggestion1, suggestion2);
        });
  }

  function empty() {
    inputCtrl.model = { libelle: '' };
    _.assign($scope.model, inputCtrl.model);
  }

  function onChange() {
    if ($scope.model) {
      if (_.isObject(inputCtrl.model)) {
        _.assign($scope.model, inputCtrl.model);
      } else if(_.isString(inputCtrl.model) && !_.isEmpty(inputCtrl.model)) {
        $scope.model.libelle = inputCtrl.model;
      } else {
        // reinitialisation of the model
        for (let key of _.keys($scope.model)) {
          $scope.model[key] = undefined;
        }
        $scope.model.libelle = '';
      }
    }

    $scope.onChange && $scope.onChange();
  }
}
