angular.module('app')
  .factory('GenerateMiniature', GenerateMiniature);

/*@ngInject*/
function GenerateMiniature($rootScope, $window, $http, $q, XWwwFormUrlEncoded, FichierApiConfiguration, ImageIllustrationGet) {

  return { generate };

  function generate(illustrationId, id, sourceFormat, formats) {
    return $http({
      method: 'POST',
      cache: false,
      url: FichierApiConfiguration.generateMiniatureUri,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      transformRequest: XWwwFormUrlEncoded.transform,
      data: {
        id: id,
        sourceFormat: sourceFormat,
        formats: formats
      }
    }).then(function (result) {
      _.forOwn(result.data, function(value, key) {
        ImageIllustrationGet.put(illustrationId, key, value.id, value.uri)
      });
    }, function (reason) {
      $window.alert(reason.data.model.message);
    });
  }

}
