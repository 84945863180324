angular.module('app')
  .factory('ConsultationIllustrationService', ConsultationIllustrationService);

/*@ngInject*/
function ConsultationIllustrationService($rootScope, initData) {
  var illustration = _.cloneDeep(initData.illustration);
  var auteursOeuvre = _.cloneDeep(initData.auteursOeuvre);

  $rootScope.dateVersement = initData.illustration.mediathequeIllustration && initData.illustration.mediathequeIllustration.versement
    && initData.illustration.mediathequeIllustration.versement.dateVersement;
  $rootScope.versementBaseNationale = initData.illustration.mediathequeIllustration && initData.illustration.mediathequeIllustration.versement
    && initData.illustration.mediathequeIllustration.versement.versementBaseNationale;

  $rootScope.$on('illustration.designation.updated', handleIllustrationUpdated);
  $rootScope.$on('illustration.localisation.updated', handleIllustrationUpdated);
  $rootScope.$on('illustration.oeuvre-representee.updated', handleIllustrationUpdated);
  $rootScope.$on('illustration.localisation.updateFromDossier', handleUpdateFromDossier);

  return { getIllustration, getAuteursOeuvre };

  function getIllustration() {
    return _.cloneDeep(illustration);
  }

  function getAuteursOeuvre() {
    return _.cloneDeep(auteursOeuvre);
  }

  function handleIllustrationUpdated(event, updatedIllustration) {
    illustration = updatedIllustration;

    $rootScope.$broadcast('illustration.changed');
  }

  function handleUpdateFromDossier(event, dossier) {
    var updated = false;

    // Mise à jour de chaque champ à copier, seulement si le champ du dossier n'est pas vide
    if (dossier.localisationPrincipale && !_.isEmpty(dossier.localisationPrincipale.commune)) {
      illustration.localisationIllustration.localisation.commune = dossier.localisationPrincipale.commune;
      updated = true;
    }
    if (dossier.localisationPrincipale && !_.isEmpty(dossier.localisationPrincipale.adresses)) {
      illustration.localisationIllustration.localisation.adresses = dossier.localisationPrincipale.adresses;
      updated = true;
    }
    if (dossier.localisationPrincipale && !_.isEmpty(dossier.localisationPrincipale.lieudits)) {
      illustration.localisationIllustration.localisation.lieudits = dossier.localisationPrincipale.lieudits;
      updated = true;
    }
    if (!_.isEmpty(dossier.aireEtude)) {
      illustration.localisationIllustration.aireEtude = dossier.aireEtude;
      updated = true;
    }
    if (!_.isEmpty(dossier.denominations)) {
      illustration.designationIllustration.denominations = dossier.denominations;
      updated = true;
    }
    if (!_.isEmpty(dossier.titre)) {
      illustration.designationIllustration.titreCourant = dossier.titre;
      updated = true;
    }
    if (dossier.edif && !_.isEmpty(dossier.edif)) {
      illustration.mediathequeIllustration.edificeConservation = dossier.edif;
      updated = true;
    }
    if (dossier.cadresEtude) {
      illustration.notice.cadresEtude = [];
      dossier.cadresEtude.forEach(cadreEtude => {
        if (!_.isEmpty(cadreEtude.type) || !_.isEmpty(cadreEtude.affixe)) {
          illustration.notice.cadresEtude.push({
            type: !_.isEmpty(cadreEtude.type) ? cadreEtude.type : null,
            affixe: !_.isEmpty(cadreEtude.affixe) ? cadreEtude.affixe : null
          });
          };
        }
      )
      updated = true;
    }

    if (updated) {
      $rootScope.$broadcast('illustration.changed');
    }
  }
}
