angular.module('app')
  .directive('mediathequeBookmarkInput', BookmarkInputDirective);

function BookmarkInputDirective() {
  return {
    restrict: 'E',
    scope: {
      entityId: '@mediathequeEntityId',
      entityType: '@mediathequeEntityType'
    },
    replace: true,
    controller: BookmarkInputController,
    controllerAs: 'inputCtrl',
    templateUrl: 'common/bookmark-input.html'
  };
}

/*@ngInject*/
function BookmarkInputController($scope, BookmarkService) {
  var inputCtrl = this;

  inputCtrl.toggleBookmark = toggleBookmark;

  $scope.$on('bookmarks-changed', updateData);

  init();

  function init() {
    updateData();
  }

  function updateData() {
    inputCtrl.processing = false;
    inputCtrl.isBookmark = BookmarkService.isBookmark($scope.entityId, $scope.entityType);
  }

  function toggleBookmark() {
    inputCtrl.processing = true;
    if (inputCtrl.isBookmark) {
      BookmarkService.removeBookmark($scope.entityId, $scope.entityType);
    } else {
      BookmarkService.addBookmark($scope.entityId, $scope.entityType);
    }
  }
}
