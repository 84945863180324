angular.module('app')
  .directive('mediathequeExpertSearchResultValue', ExpertSearchResultValueDirective);

function ExpertSearchResultValueDirective() {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      typeChamp: '@mediathequeTypeChamp',
      valeur: '&mediathequeValeur'
    },
    controller: ExpertSearchResultValueController,
    controllerAs: 'expertSearchResultValueCtrl',
    templateUrl: 'common/expert-search-result-value.html'
  };
}

/*@ngInject*/
function ExpertSearchResultValueController($scope) {
  var expertSearchResultValueCtrl = this;

  expertSearchResultValueCtrl.typeChamp = $scope.typeChamp;
  expertSearchResultValueCtrl.valeur = $scope.valeur();
}
