angular.module('app')
  .provider('NavbarService', NavBarProvider);

function NavBarProvider() {
  var defaults = this.defaults = {
    activeClass: 'active',
    routeAttr: 'data-match-route',
    strict: false
  };

  this.$get = function() {
    return {defaults: defaults};
  };
}
