angular.module('app')
  .factory('PersonneService', PersonneService);

/*@ngInject*/
function PersonneService($rootScope, $http, XWwwFormUrlEncoded, PersonneApiConfiguration, initData) {
  var personnes = {};

  return { getByTemporaryId, lockAndGet, save, cancel };

  function getByTemporaryId(id) {
    return _.cloneDeep(_.get(personnes, id));
  }

  function lockAndGet(personneId, illustrationId) {
    return $http({
      method: 'POST',
      cache: false,
      url: PersonneApiConfiguration.lockAndGetUri,
      params: { personneId, illustrationId }
    }).then((response) => {
      if (response.data.personne.type == 'Morale') {
        response.data.personne.autresRaisonsSociales = _.map(response.data.personne.autresRaisonsSociales, (autreRaisonSociale) => {
          return { 'libelle': autreRaisonSociale };
        });
      }
      return response.data;
    }, (error) => {
      throw error.data.model;
    });
  }

  function save(personne, illustrationId) {
    if (personne.type == 'Morale') {
        var url = PersonneApiConfiguration.saveMoraleUri;
        var data = {
           raisonSociale: personne.raisonSociale,
           autresRaisonsSociales: _.map(personne.autresRaisonsSociales, 'libelle'),
           debutActivite: personne.debutActivite,
           finActivite: personne.finActivite,
           version: personne.version
        }
    } else {
        var url = PersonneApiConfiguration.saveUri;
        var data = {
           nom: personne.nom,
           prenom: personne.prenom,
           nomNaissance: personne.nomNaissance,
           pseudo: personne.pseudo,
           dateNaissance: personne.dateNaissance,
           dateDeces: personne.dateDeces,
           sexe: personne.sexe,
           version: personne.version
        }
    }

    return $http({
      method: 'POST',
      cache: false,
      url: url,
      params: {
        personneId: personne.id,
        illustrationId: illustrationId
      },
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      transformRequest: XWwwFormUrlEncoded.transform,
      data: data
    }).then((response) => {
      _.set(personnes, response.data.id, response.data);
      return response.data;
    }, (error) => {
      throw error.data.model;
    });
  }

  function cancel(personneId, illustrationId) {
    return $http({
      method: 'POST',
      cache: false,
      url: PersonneApiConfiguration.cancelUri,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      transformRequest: XWwwFormUrlEncoded.transform,
      data: { personneId, illustrationId }
    }).then(null, (error) => {
      throw error.data.model;
    });
  }
}
