angular.module('app')
  .factory('StringComparator', StringComparator);

/*@ngInject*/
function StringComparator() {

  var stringComparator = {};
  stringComparator.normalize = _.memoize(normalize);
  stringComparator.startsWith = _.memoize(startsWith, (value, search) => { return (value||'') + "--" + (search||''); });

  function startsWith(value, search) {
    return stringComparator.normalize(value).startsWith(stringComparator.normalize(search));
  }

  function normalize(value) {
    if (!_.isString(value)) {
      return value;
    }
    return (value || "").toLowerCase().replace("œ", "oe").replace("æ", "ae").replace("ç", "c").normalize("NFD").replace(/[^\000-\177]/g, "");
  }

  return stringComparator;
}
