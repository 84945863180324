angular.module('app')
  .directive('mediathequeMultivaluedPersonneInput', MultivaluedPersonneInputDirective);

function MultivaluedPersonneInputDirective() {
  return {
    restrict: 'E',
    scope: {
      name: '@mediathequeName',
      id: '@mediathequeId',
      addElementTitle: '@mediathequeAddElementTitle',
      removeElementTitle: '@mediathequeRemoveElementTitle',
      form: '@mediathequeForm',
      disabled: '&mediathequeDisabled',
      initialValues: '&mediathequeInitialValues'
    },
    replace: true,
    controller: MultivaluedPersonneInputController,
    controllerAs: 'inputsCtrl',
    templateUrl: 'common/multivalued-personne-input.html'
  };
}

/*@ngInject*/
function MultivaluedPersonneInputController($scope) {
  var inputsCtrl = this;

  inputsCtrl.addAuteur = addAuteur;
  inputsCtrl.removeAuteur = removeAuteur;

  init();

  // TODO: Ajouter un watcher sur $scope.model (couplé à un $timeout pour s'assurer que le scope est bien à jour)
  // si le model initial est modifié par le composant parent

  function addAuteur() {
    inputsCtrl.auteurs.push({ libelle: "" });
    inputsCtrl.dirty = true;
    inputsCtrl.newlyAdded = true;
  }

  function removeAuteur(auteurToRemove) {
    inputsCtrl.auteurs = _.without(inputsCtrl.auteurs, auteurToRemove);

    // Il faut au moins un champs affiché
    if (inputsCtrl.auteurs.length === 0) {
      inputsCtrl.auteurs = [{ libelle: "" }];
    }
    inputsCtrl.dirty = true;
    inputsCtrl.newlyAdded = false;
  }

  function init() {
    inputsCtrl.auteurs = ($scope.initialValues() && $scope.initialValues().length !== 0) ? $scope.initialValues() : [{ libelle: "" }];
    inputsCtrl.dirty = false;
    inputsCtrl.newlyAdded = false;
  }
}
