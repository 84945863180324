(function(module) {
try {
  module = angular.module('app');
} catch (e) {
  module = angular.module('app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('common/adresse-input.html',
    '<div class=row><div class=col-sm-3><input type=text class=form-control id="{{ id + \'-numero\' }}" name="{{ name + \'.numero\' }}" placeholder=numéro ng-model=inputCtrl.adresse.numero ng-change=inputCtrl.onChange() ng-readonly=disabled() data-mediatheque-default-value="{{ ::initialValue().numero }}" mediatheque-autofocus=applyFocus()></div><div class=col-sm-4><mediatheque-thesaurus-input mediatheque-name="{{ name + \'.typeVoie\' }}" mediatheque-id="{{ id + \'-typeVoie\' }}" mediatheque-disabled=disabled() mediatheque-placeholder="type de voie" ng-attr-mediatheque-form="{{ form }}" ng-model=inputCtrl.adresse.typeVoie mediatheque-on-change=inputCtrl.onChange() mediatheque-disable-suggest=true mediatheque-types-thesaurus="[\'AdresseTypeVoie\']"></mediatheque-thesaurus-input></div><div class=col-sm-5><mediatheque-thesaurus-input mediatheque-name="{{ name + \'.nom\' }}" mediatheque-id="{{ id + \'-nom\' }}" mediatheque-disabled=disabled() mediatheque-placeholder=nom ng-attr-mediatheque-form="{{ form }}" ng-model=inputCtrl.adresse.nom mediatheque-on-change=inputCtrl.onChange() mediatheque-disable-suggest=true mediatheque-types-thesaurus="[\'AdresseNomRue\']"></mediatheque-thesaurus-input></div></div>');
}]);
})();
