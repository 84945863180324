angular.module('app')
  .directive('mediathequeIllustrationExpertSearchResults', IllustrationExpertSearchResultsDirective);

function IllustrationExpertSearchResultsDirective() {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      results: '&mediathequeSearchResults',
      loaded: '&mediathequeSearchLoaded',
      loading: '&mediathequeSearchLoading',
      mosaic: '&mediathequeMosaic'
    },
    controller: IllustrationExpertSearchResultsController,
    controllerAs: 'expertSearchResultsCtrl',
    templateUrl: 'illustration/illustration-expert-search-results.html'
  };
}

/*@ngInject*/
function IllustrationExpertSearchResultsController($scope, $rootScope, ExpertSearch) {
  var expertSearchResultsCtrl = this;

  expertSearchResultsCtrl.results = null;
  expertSearchResultsCtrl.loading = false;
  expertSearchResultsCtrl.loaded = false;
  $scope.$watch($scope.results, (newResults) => {
    expertSearchResultsCtrl.results = newResults;
  });
  $scope.$watch($scope.loading, (loading) => {
    expertSearchResultsCtrl.loading = loading;
  });
  $scope.$watch($scope.loaded, (loaded) => {
    expertSearchResultsCtrl.loaded = loaded;
  });
  $scope.$watch($scope.mosaic, (mosaic) => {
    expertSearchResultsCtrl.mosaic = mosaic;
  });

  expertSearchResultsCtrl.displayNextResults = displayNextResults;
  expertSearchResultsCtrl.displayAllResults = displayAllResults;
  expertSearchResultsCtrl.hasMoreResults = ExpertSearch.hasMoreResults;
  expertSearchResultsCtrl.toggleResult = toggleResult;

  function displayNextResults() {
    $rootScope.$emit('expert-search-next', 'Illustration');
  }

  function displayAllResults() {
    $rootScope.$emit('expert-search-all', 'Illustration');
  }

  function toggleResult(result) {
    $rootScope.$emit('expert-search-toggle-result', result.id);
  }
}
