angular.module('app')
  .factory('EntitySuggest', EntitySuggest);

/*@ngInject*/
function EntitySuggest($http, $q, SuggestApiConfiguration) {
  return { search };

  function search(entityType, query) {

    return $http({
      method: 'GET',
      cache: true,
      url: SuggestApiConfiguration.entityUri,
      params: { query: query, entityType: entityType }
    });
  }
}
