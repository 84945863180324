(function(module) {
try {
  module = angular.module('app');
} catch (e) {
  module = angular.module('app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('task/tasks-bar.html',
    '<div class=col-xs-12><ul class=list-inline><li><button ng-disabled=tasksBarCtrl.allSelected ng-click=tasksBarCtrl.selectAll() class="btn btn-default btn-block" type=button>Sélectionner les tâches affichées</button></li><li><button ng-disabled=!tasksBarCtrl.atLeastOneSelected ng-click=tasksBarCtrl.selectNone() class="btn btn-default btn-block" type=button>Tout désélectionner</button></li><li><form method=POST action="{{ tasksBarCtrl.removeSelectedTasksUri }}" id="{{ tasksBarCtrl.removeSelectedTasksForm }}" name="{{ tasksBarCtrl.removeSelectedTasksForm }}" mediatheque-confirm><input type=hidden name=_xsrf_ value="{{ tasksBarCtrl.xsrf }}"> <button class="btn btn-block btn-default" ng-disabled=!tasksBarCtrl.atLeastOneSelected type=submit>Supprimer les tâches sélectionnées</button></form></li></ul></div>');
}]);
})();
