(function(module) {
try {
  module = angular.module('app');
} catch (e) {
  module = angular.module('app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('common/multivalued-adresse-input.html',
    '<div class=multivalued-input><input type=hidden data-mediatheque-default-value=false value="{{ inputsCtrl.dirty }}" ng-attr-form="{{ form }}"><div ng-repeat="adresse in inputsCtrl.adresses"><div class=row--flex><input type=hidden name="{{ name + \'[\'+$index+\'].originalIndex\' }}" value="{{ adresse.originalIndex }}" ng-attr-form="{{ form }}"><div ng-class="{ \'col-sm-10\': ! disabled(), \'col-sm-12\': disabled() }"><mediatheque-adresse-input mediatheque-name="{{ name + \'[\'+$index+\']\' }}" mediatheque-id="{{ id + \'-\' + $index }}" ng-attr-mediatheque-form="{{ form }}" mediatheque-disabled=disabled() ng-model=adresse mediatheque-on-change=inputsCtrl.onChange() mediatheque-initial-value=adresse mediatheque-apply-focus="$last && inputsCtrl.newlyAdded"></mediatheque-adresse-input></div><div ng-if="! disabled()"><button type=button class="btn btn-transparent btn-input-actions" ng-click=inputsCtrl.removeAdresse(adresse) title="Supprimer une adresse"><span class="fa fa-times-circle"></span></button></div><div ng-if="! disabled() && $last"><button type=button class="btn btn-transparent btn-input-actions" ng-click=inputsCtrl.addAdresse() title="Ajouter une adresse"><span class="fa fa-plus"></span></button></div></div></div></div>');
}]);
})();
