angular.module('app')
  .service('ExpertSearchPictureSliderSupplier', ExpertSearchPictureSliderSupplier);

/*ngInject*/
function ExpertSearchPictureSliderSupplier($rootScope, ExpertSearch, $q, $timeout) {
  return { getImages, canGetMoreImages, loadMoreImages };

  function getImages() {
    return _.map(ExpertSearch.getResults(), (result) => {
      return {
        illustrationId: result.id,
        imageUrl: result.pleinEcranUrl,
        titreCourant: result.titreCourant || result.titre,
        denominations: result.denomations,
        commune: result.sousTitre,
        numi: result.titrePrincipal,
        illustrationUrl: result.uri,
        type: result.type,
        entityType: result.entityType,
      };
    });
  }

  function canGetMoreImages() {
    return ExpertSearch.hasMoreResults();
  }

  function loadMoreImages() {
    $rootScope.$emit('expert-search-next', 'Illustration');
    var listenerDeregistration;
    return $q((resolve, reject) => {
      // This listener should be removed after execution
      listenerDeregistration = $rootScope.$on('expert-search-successful', () => {
        resolve();

        listenerDeregistration();
      });
    });
  }
}
