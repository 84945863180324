angular.module('app')
  .directive('mediathequeLocationHash', LocationHashDirective);

/*@ngInject*/
function LocationHashDirective(LocationService) {
  return {
    restrict: 'A',
    link: function(scope, jqElement, attrs, inputCtrl) {
      scope.$on('location-hash-changed', setValue);
      jqElement[0].value = LocationService.getHash();
      function setValue(event) {
        jqElement[0].value = LocationService.getHash();
      }
    }
  };
}
