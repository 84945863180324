(function(module) {
try {
  module = angular.module('app');
} catch (e) {
  module = angular.module('app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('template/datepicker/popup.html',
    '<ul class=dropdown-menu ng-style="{display: (isOpen && \'block\') || \'none\', top: position.top+\'px\', left: position.left+\'px\', width: \'280px\'}" ng-keydown=keydown($event)><li ng-transclude></li></ul>');
}]);
})();
