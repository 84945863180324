angular.module('app')
  .directive('mediathequeBiblioExpertSearchResultsTabBar', BiblioExpertSearchResultsTabBarDirective);

function BiblioExpertSearchResultsTabBarDirective() {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      totalResults: '&mediathequeTotalResults',
      numberDisplayedResults: '&mediathequeNumberDisplayedResults',
      allSelected: '&mediathequeAllSelected',
      atLeastOneSelected: '&mediathequeAtLeastOneSelected',
      canSaveRecherche: '&mediathequeCanSaveRecherche'
    },
    controller: BiblioExpertSearchResultsTabBarController,
    controllerAs: 'expertSearchResultsTabBarCtrl',
    templateUrl: 'biblio/biblio-expert-search-results-tab-bar.html'
  };
}

/*@ngInject*/
function BiblioExpertSearchResultsTabBarController($scope, $rootScope, ExpertSearch) {
  var expertSearchResultsTabBarCtrl = this;

  expertSearchResultsTabBarCtrl.selectAll = selectAll;
  expertSearchResultsTabBarCtrl.selectNone = selectNone;
  expertSearchResultsTabBarCtrl.displayAllResults = displayAllResults;
  expertSearchResultsTabBarCtrl.hasMoreResults = ExpertSearch.hasMoreResults;

  $scope.$watch($scope.canSaveRecherche, (value) => expertSearchResultsTabBarCtrl.canSaveRecherche = value);
  $scope.$watch($scope.allSelected, (value) => expertSearchResultsTabBarCtrl.allSelected = value);
  $scope.$watch($scope.atLeastOneSelected, (value) => expertSearchResultsTabBarCtrl.atLeastOneSelected = value);
  $scope.$watch($scope.totalResults, (totalResults) => expertSearchResultsTabBarCtrl.totalResults = totalResults);
  $scope.$watch($scope.numberDisplayedResults, (numberDisplayedResults) => expertSearchResultsTabBarCtrl.numberDisplayedResults = numberDisplayedResults);

  function selectAll() {
    $rootScope.$emit('expert-search-results-select-all');
  }

  function selectNone() {
    $rootScope.$emit('expert-search-results-unselect-all');
  }

  function displayAllResults() {
    $rootScope.$emit('expert-search-all', 'Biblio');
  }
}
