angular.module('app')
  .directive('mediathequeBiblioFileInput', BiblioFileInputDirective);

function BiblioFileInputDirective() {
  return {
    restrict: 'E',
    scope: {
      name: '@mediathequeName',
      id: '@mediathequeId',
      form: '@mediathequeForm',
      disabled: '&mediathequeDisabled',
      initialValue: '&mediathequeInitialValue',
      sizeMax: '@mediathequeSizeMax'
    },
    replace: true,
    controller: BiblioFileInputController,
    controllerAs: 'inputCtrl',
    templateUrl: 'common/biblio-file-input.html'
  };
}

/*@ngInject*/
function BiblioFileInputController($scope, $timeout, FichierPost, NotificationService) {
  var inputCtrl = this;
  var uploadObject = null;

  inputCtrl.id = $scope.id;
  inputCtrl.name = $scope.name;
  inputCtrl.form = $scope.form;

  inputCtrl.upload = upload;
  inputCtrl.remove = remove;
  inputCtrl.abort = abort;
  inputCtrl.hasFile = hasFile;
  inputCtrl.isDisabled = $scope.disabled;
  inputCtrl.sizeMax = $scope.sizeMax;

  init();

  function init() {
    inputCtrl.loading = false;
    inputCtrl.progressPercentage = 0;

    inputCtrl.file = $scope.initialValue() || { name: '' };
  }

  function updateResource(event, illustrationId, format) {
    inputCtrl.id = cache.id;
  }

  function setLoading(loading) {
    inputCtrl.loading = loading;
    if (! loading) {
      inputCtrl.uploadObject = null;
    }
  }

  function hasFile() {
    return inputCtrl.file.id != null && !inputCtrl.loading;
  }

  function remove() {
    inputCtrl.file = null;
  }

  function abort() {
    if (!inputCtrl.loading) {
      return;
    }
    uploadObject.abort();
    uploadObject = null;
  }

  function upload(files, file, event, rejectedFiles) {
    if (file === null) {
      return;
    } else if (file.size > inputCtrl.sizeMax) {
      NotificationService.notify({
        'id': "file-input-size-max",
        'type': 'danger',
        'fadeOn': {
          'enter': false,
          'leave': true
        },
        'contentTemplate': 'common/app-notification-error.html',
        'scope': {
          data: {
            error: "Le fichier dépasse la taille maximale autorisée de " + inputCtrl.sizeMax / 1000000 + "Mo"
          }
        }
      });
      return;
    }

    inputCtrl.loading = true;
    uploadObject = FichierPost.postFile(file);
    uploadObject.then((response) => {
      inputCtrl.file = response.data;
      inputCtrl.loading = false;
      uploadObject = null;
    }, () => {
      inputCtrl.loading = false;
      uploadObject = null;
    });
  }
}
