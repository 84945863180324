angular.module('app')
  .directive('mediathequeTasksBar', TasksBarDirective);

function TasksBarDirective() {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      removeSelectedTasksForm: '@mediathequeRemoveSelectedTasksForm',
      removeSelectedTasksUri: '@mediathequeRemoveSelectedTasksUri'
    },
    controller: TasksBarController,
    controllerAs: 'tasksBarCtrl',
    templateUrl: 'task/tasks-bar.html'
  };
}

/*@ngInject*/
function TasksBarController($scope, $rootScope, TasksService, SecurityConfiguration) {
  var tasksBarCtrl = this;

  tasksBarCtrl.selectAll = selectAll;
  tasksBarCtrl.selectNone = selectNone;
  tasksBarCtrl.removeSelectedTasksForm = $scope.removeSelectedTasksForm;
  tasksBarCtrl.removeSelectedTasksUri = $scope.removeSelectedTasksUri;
  tasksBarCtrl.xsrf = SecurityConfiguration['_xsrf_'];

  $scope.$on('tasks-select-changed', handleSelectedChanged);

  handleSelectedChanged();

  function selectAll() {
    $rootScope.$emit('tasks-select-all');
  }

  function selectNone() {
    $rootScope.$emit('tasks-unselect-all');
  }

  function handleSelectedChanged() {
    tasksBarCtrl.allSelected = TasksService.areAllSelected();
    tasksBarCtrl.atLeastOneSelected = TasksService.isAtLeastOneSelected();
  }
}
