angular.module('app')
  .factory('MessageFormatter', MessageFormatter);

/*@ngInject*/
function MessageFormatter(MessageFormatterConfiguration) {
  return { format };

  function format(path) {
    // TODO: Gérer les variables dans les messages
    return _.get(MessageFormatterConfiguration, path) || '#{' + path + '}';
  }
}
