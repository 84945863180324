angular.module('app')
  .directive('mediathequeIllustrationResultsTabBar', IllustrationResultsTabBarDirective);

function IllustrationResultsTabBarDirective() {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      printUri: '@mediathequePrintUri',
      createLotSimpleUri: '@mediathequeCreateLotSimpleUri'
    },
    controller: IllustrationResultsTabBarController,
    controllerAs: 'illustrationResultsTabBarCtrl',
    templateUrl: 'illustration/illustration-results-tab-bar.html'
  };
}

/*@ngInject*/
function IllustrationResultsTabBarController($scope, $rootScope, IllustrationResultsService) {
  var illustrationResultsTabBarCtrl = this;

  illustrationResultsTabBarCtrl.enableMosaic = enableMosaic;
  illustrationResultsTabBarCtrl.disableMosaic = disableMosaic;

  illustrationResultsTabBarCtrl.selectAll = selectAll;
  illustrationResultsTabBarCtrl.selectNone = selectNone;

  illustrationResultsTabBarCtrl.hasMoreResults = IllustrationResultsService.hasMoreResults;
  illustrationResultsTabBarCtrl.displayAllResults = displayAllResults;

  $scope.$on('illustration-results-changed', handleResultsChanged);
  $scope.$on('illustration-results-mosaic-changed', reloadMosaicMode);

  init();

  function enableMosaic() {
    $rootScope.$emit('illustration-results-enable-mosaic');
  }

  function disableMosaic() {
    $rootScope.$emit('illustration-results-disable-mosaic');
  }

  function selectAll() {
    $rootScope.$emit('illustration-results-select-all');
  }

  function selectNone() {
    $rootScope.$emit('illustration-results-unselect-all');
  }

  function displayAllResults() {
    $rootScope.$emit('illustration-results-all');
  }

  function init() {
    handleResultsChanged();
    reloadMosaicMode();
  }

  function handleResultsChanged() {
    illustrationResultsTabBarCtrl.allSelected = IllustrationResultsService.areAllSelected();
    illustrationResultsTabBarCtrl.atLeastOneSelected = IllustrationResultsService.isAtLeastOneSelected();
    illustrationResultsTabBarCtrl.numberDisplayedResults = IllustrationResultsService.getNumberDisplayedResults();
    illustrationResultsTabBarCtrl.totalResults = IllustrationResultsService.getTotalResults();
  }

  function reloadMosaicMode() {
    illustrationResultsTabBarCtrl.mosaic = IllustrationResultsService.isMosaicEnabled();
  }
}
