angular.module('app')
  .directive('mediathequeContributionIllustrationInput', ContributionIllustrationInputDirective);

function ContributionIllustrationInputDirective() {
  return {
    restrict: 'E',
    scope: {
      name: '@mediathequeName',
      id: '@mediathequeId',
      illustrationId: '@mediathequeIllustrationId',
      form: '@mediathequeForm',
      disabled: '&mediathequeDisabled',
      withReproduction: '&mediathequeWithReproduction',
      initialValue: '&mediathequeInitialValue',
      applyFocus: '&mediathequeApplyFocus'
    },
    replace: true,
    controller: ContributionIllustrationInputController,
    controllerAs: 'inputCtrl',
    templateUrl: 'common/contribution-illustration-input.html'
  };
}

/*@ngInject*/
function ContributionIllustrationInputController($rootScope, $scope, PersonneEditModal, PersonneService) {
  var inputCtrl = this;

  inputCtrl.editPersonne = editPersonne;
  inputCtrl.createPersonne = createPersonne;

  init();

  // TODO: Ajouter un watcher sur $scope.model (couplé à un $timeout pour s'assurer que le scope est bien à jour)
  // si le model initial est modifié par le composant parent

  function init() {
    inputCtrl.contribution = $scope.initialValue() || { qualites: [{libelle: ""}] };
    $rootScope.$on('personne.edit.success', editPersonneSuccess);
  }
  function editPersonneSuccess(event, uniqueId, personneId) {
    // on s'occupe de l'événement si on en est à l'origine ou si la donnée modifiée et la notre
    if (inputCtrl.uniqueId === uniqueId || inputCtrl.contribution.auteur.id === personneId) {
      inputCtrl.contribution.auteur = PersonneService.getByTemporaryId(personneId);
    }
  }
  function editPersonne() {
    inputCtrl.uniqueId = _.uniqueId('personne.edit.start_');
    PersonneEditModal.updatePersonne(inputCtrl.uniqueId, $scope.illustrationId, inputCtrl.contribution.auteur)
  }
  function createPersonne() {
    inputCtrl.uniqueId = _.uniqueId('personne.edit.start_');
    PersonneEditModal.createPersonne(inputCtrl.uniqueId, $scope.illustrationId)
  }
}
