(function(module) {
try {
  module = angular.module('app');
} catch (e) {
  module = angular.module('app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('illustration/illustration-with-error.html',
    '<table class="table table-striped table-hover"><thead><tr><th></th><th>Vignette</th><th><a href={{illustrationWithErrorCtrl.sortNumiUri}}>Numi <span ng-if="illustrationWithErrorCtrl.sortColumn === \'NUMI\'" class="fa fa-sort-{{illustrationWithErrorCtrl.sortOrder}}"></span></a></th><th>Titre courant</th><th>Statut</th><th><a href={{illustrationWithErrorCtrl.sortCreatedByUri}}>Créateur <span ng-if="illustrationWithErrorCtrl.sortColumn === \'CREATED_BY\'" class="fa fa-sort-{{illustrationWithErrorCtrl.sortOrder}}"></span></a></th><th>Erreurs</th></tr></thead><tbody><tr ng-repeat="resultat in illustrationWithErrorCtrl.resultats"><td><input type=checkbox name=elementsId data-ignorable-value ng-value=resultat.illustration.id title="Sélectionner cette illustration" ng-checked=resultat.selected ng-click=illustrationWithErrorCtrl.selectResult(resultat)></td><td><mediatheque-vignette-from-id data-mediatheque-vignette-url={{resultat.vignetteUri}} data-mediatheque-styles="[\'fa-3x\']" data-mediatheque-empty-icon=fa-camera-retro class=img-thumbnail></mediatheque-vignette-from-id></td><td><a href={{resultat.illustrationUri}}>{{resultat.illustration.numero.immatriculationSansEmet}}</a></td><td>{{resultat.illustration.designationIllustration.titreCourant}}</td><td><span ng-switch=resultat.illustration.lockStatus><span ng-switch-when=FREE>{{ \'lockStatus.free\' | messageFormat }}</span> <span ng-switch-when=LOCKED>{{ \'lockStatus.locked\' | messageFormat }}</span> <span ng-switch-when=WORKING_COPY>{{ \'lockStatus.workingCopy\' | messageFormat }}</span></span></td><td>{{resultat.illustration.createdBy}}</td><td><ul><li ng-repeat="message in resultat.validationErrorMessages">{{message}}</li></ul></td></tr></tbody></table>');
}]);
})();
