(function(module) {
try {
  module = angular.module('app');
} catch (e) {
  module = angular.module('app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('illustration/picture-slider-wrapper.html',
    '<div class=picture-slider-wrapper><ng-transclude></ng-transclude><div class=picture-slider-wrapper-tab><button type=button class=picture-slider-wrapper-open ng-click=pictureSliderWrapperCtrl.displaySlider()><span class="fa fa-expand fa-lg"></span></button></div></div>');
}]);
})();
