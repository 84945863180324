(function(module) {
try {
  module = angular.module('app');
} catch (e) {
  module = angular.module('app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('common/multivalued-text-input.html',
    '<div class=multivalued-input><input type=hidden data-mediatheque-default-value=false value="{{ inputsCtrl.dirty }}" ng-attr-form="{{ form }}"><div class="form row--flex" ng-class="{ \'row--flex--wrap\': !inputsCtrl.oneElementPerRow() }"><div ng-repeat="value in model track by $index" class="{{ cssClasses }} {{ \'col-sm-\' + inputsCtrl.inputSize() }}"><div ng-class="{ \'input-group\': ! disabled() || prefix }" class="{{ inputGroupClass }}"><div class=input-group-btn ng-if=prefix><button type=button class="btn btn-addon {{ inputClass }}"><span class="fa {{ prefix }}"></span></button></div><mediatheque-text-input mediatheque-name="{{ name }}" mediatheque-id="{{ id + \'-\' + $index }}" mediatheque-input-class="{{ inputClass }}" ng-attr-mediatheque-form="{{ form }}" mediatheque-disabled=disabled() mediatheque-field-path="{{ fieldPath }}" mediatheque-entity-type="{{ entityType }}" mediatheque-value="{{ value.libelle }}" mediatheque-placeholder="{{ placeholder }}" ng-model=value.libelle mediatheque-on-change=inputsCtrl.onChange() mediatheque-apply-focus="$last && inputsCtrl.newlyAdded"></mediatheque-text-input><span class=input-group-btn ng-if="! disabled()"><button type=button class="btn btn-addon {{ inputClass }}" ng-click=inputsCtrl.removeValue(value) title="{{ removeElementTitle}}"><span class="fa fa-times-circle"></span></button></span></div></div><button type=button class="btn btn-transparent btn-input-actions" ng-click=inputsCtrl.addValue() ng-if="! disabled()" title="{{ addElementTitle }}"><span class="fa fa-plus"></span></button></div></div>');
}]);
})();
