angular.module('app')
  .directive('mediathequeVignetteFromLot', VignetteFromLotDirective);

function VignetteFromLotDirective() {
  return {
    restrict: 'E',
    scope: {
      resourceUrls: '&mediathequeResourceUrls',
      titre: '@mediathequeTitre',
      styles: '&mediathequeStyles',
      emptyIcon: '@mediathequeEmptyIcon'
    },
    replace: true,
    controller: VignetteFromLotController,
    controllerAs: 'vignetteFromLotCtrl',
    templateUrl: 'common/vignette-from-lot.html'
  };
}

/*@ngInject*/
function VignetteFromLotController($scope) {
  var vignetteFromLotCtrl = this;

  vignetteFromLotCtrl.getResourceUrls = _.memoize(getResourceUrls);

  init();

  function getResourceUrls() {
    var resourceUrls = [];
    for (let i = 0; i < 4; i++) {
      if (vignetteFromLotCtrl.resourceUrls.length > i) {
        resourceUrls.push(vignetteFromLotCtrl.resourceUrls[i]);
      } else {
        resourceUrls.push(null);
      }
    }
    return resourceUrls;
  }

  function init() {
    vignetteFromLotCtrl.resourceUrls = $scope.resourceUrls();
  }
}
