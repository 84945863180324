angular.module('app')
  .directive('mediathequeLienInput', LienInputDirective);

function LienInputDirective() {
  return {
    restrict: 'E',
    scope: {
      id: '@mediathequeId',
      name: '@mediathequeName',
      form: '@mediathequeForm',
      disabled: '&mediathequeDisabled',
      initialValue: '@mediathequeInitialValue',
      applyFocus: '&mediathequeApplyFocus'
    },
    replace: true,
    controller: LienInputController,
    controllerAs: 'inputCtrl',
    templateUrl: 'common/lien-input.html'
  };
}

/*@ngInject*/
function LienInputController($scope) {
  var inputCtrl = this;

  inputCtrl.id = $scope.id;
  inputCtrl.name = $scope.name;
  inputCtrl.form = $scope.form;
  inputCtrl.value = $scope.initialValue;

  inputCtrl.isDisabled = $scope.disabled;
}
