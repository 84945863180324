(function(module) {
try {
  module = angular.module('app');
} catch (e) {
  module = angular.module('app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('common/auteuroeuvre-illustration-input.html',
    '<div class=row><div class="col-sm-5 col-lg-12"><div class=input-group><mediatheque-personne-input data-mediatheque-name="{{ name + \'.auteur\' }}" data-mediatheque-id="{{ id + \'-auteurId\' }}" ng-attr-mediatheque-form="{{ form }}" mediatheque-placeholder=auteur ng-model=inputCtrl.auteuroeuvre.auteur mediatheque-initial-value=inputCtrl.auteuroeuvre.auteur mediatheque-disabled=disabled() mediatheque-apply-focus=applyFocus()></mediatheque-personne-input><span class=input-group-btn><button type=button class="btn btn-addon" ng-click=inputCtrl.editPersonne() title="Modifier les informations de l\'auteur" ng-disabled="! inputCtrl.auteuroeuvre.auteur.id"><span class="fa fa-pencil"></span></button></span> <span class=input-group-btn ng-show="! disabled()"><button type=button class="btn btn-addon" ng-click=inputCtrl.createPersonne() title="Créer un nouvel auteur"><span class="fa fa-user-plus"></span></button></span></div></div><div class="col-sm-4 col-lg-8"><mediatheque-multivalued-thesaurus-input mediatheque-name="{{ name + \'.professions\' }}" mediatheque-id="{{ id + \'-professions\' }}" ng-attr-mediatheque-form="{{ form }}" mediatheque-input-placeholder=profession mediatheque-add-element-title="Ajouter une profession" mediatheque-remove-element-title="Supprimer une profession" mediatheque-disabled=disabled() mediatheque-initial-values=inputCtrl.auteuroeuvre.professions mediatheque-types-thesaurus="[\'ProfessionAfig\']"></mediatheque-multivalued-thesaurus-input></div><div class="col-sm-2 col-lg-3 checkbox-block"><input type=checkbox ng-attr-form="{{ form }}" name="{{ name + \'.incertitude\' }}" id="{{ id + \'-incertitude\' }}" data-mediatheque-default-checked="{{ ::initialValue().incertitude }}" ng-model=inputCtrl.auteuroeuvre.incertitude value=true ng-disabled=disabled()> <label for="{{ id + \'-incertitude\' }}" class=checkbox-block-label>Incert.</label></div></div>');
}]);
})();
