angular.module('app')
  .directive('mediathequeDefaultValue', DefaultValueDirective);

/*@ngInject*/
function DefaultValueDirective() {
  return {
    restrict: 'A',
    link: function(scope, element, attrs) {
      // The scope parameter is not used here in order to not interfere with another directive with it own scope (like typeahead)
      var defaultValue = attrs.mediathequeDefaultValue;
      // element variable is a JQLite instance defined as an array of DOM elements
      _.forEach(element, (input) => {
        if (input.tagName === 'BUTTON' && input.type === 'hidden') {
          // Inutile de lui attribuer une valeur par défaut
          return;
        }
        // Seuls les champs de type autre que radio et checkbox sont gérés par cette directive.
        input.defaultValue = defaultValue;
      });
    }
  };
}
