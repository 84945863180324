angular.module('app')
  .directive('mediathequeContributionBiblioInput', ContributionBiblioInputDirective);

function ContributionBiblioInputDirective() {
  return {
    restrict: 'E',
    scope: {
      name: '@mediathequeName',
      id: '@mediathequeId',
      form: '@mediathequeForm',
      disabled: '&mediathequeDisabled',
      initialValue: '&mediathequeInitialValue',
      applyFocus: '&mediathequeApplyFocus'
    },
    replace: true,
    controller: ContributionBiblioInputController,
    controllerAs: 'inputCtrl',
    templateUrl: 'common/contribution-biblio-input.html'
  };
}

/*@ngInject*/
function ContributionBiblioInputController($rootScope, $scope, AuteurbiblioEditModal, AuteurbiblioService) {
  var inputCtrl = this;

  inputCtrl.editAuteurBiblio = editAuteurBiblio;
  inputCtrl.createAuteurBiblio = createAuteurBiblio;

  init();

  // TODO: Ajouter un watcher sur $scope.model (couplé à un $timeout pour s'assurer que le scope est bien à jour)
  // si le model initial est modifié par le composant parent

  function init() {
    inputCtrl.contribution = $scope.initialValue() || { role: { libelle: ""} };
    $scope.$on('auteurbiblio.edit.success', editAuteurbiblioSuccess);
  }
  function editAuteurbiblioSuccess(event, uniqueId, auteurbiblioId) {
    // on s'occupe de l'événement si on en est à l'origine ou si la donnée modifiée et la notre
    if (inputCtrl.uniqueId === uniqueId || inputCtrl.contribution.auteur.id === auteurbiblioId) {
      inputCtrl.contribution.auteur = AuteurbiblioService.getByTemporaryId(auteurbiblioId);
    }
  }
  function editAuteurBiblio() {
    inputCtrl.uniqueId = _.uniqueId('auteurbiblio.edit.start_');
    AuteurbiblioEditModal.updateAuteurbiblio(inputCtrl.uniqueId, inputCtrl.contribution.auteur);
  }
  function createAuteurBiblio() {
    inputCtrl.uniqueId = _.uniqueId('auteurbiblio.edit.start_');
    AuteurbiblioEditModal.createAuteurbiblio(inputCtrl.uniqueId);
  }
}
