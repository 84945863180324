angular.module('app')
  .directive('mediathequeMultivaluedLieuditInput', MultivaluedLieuditInputDirective);

function MultivaluedLieuditInputDirective() {
  return {
    restrict: 'E',
    scope: {
      name: '@mediathequeName',
      id: '@mediathequeId',
      form: '@mediathequeForm',
      disabled: '&mediathequeDisabled',
      model: '=ngModel',
      onChange: '&mediathequeOnChange',
      initialValues: '&mediathequeInitialValues'
    },
    replace: true,
    controller: MultivaluedLieuditInputController,
    controllerAs: 'inputsCtrl',
    templateUrl: 'common/multivalued-lieudit-input.html'
  };
}

/*@ngInject*/
function MultivaluedLieuditInputController($scope, $timeout) {
  var inputsCtrl = this;

  inputsCtrl.dirty = false;

  inputsCtrl.onChange = $scope.onChange;

  inputsCtrl.removeLieudit = removeLieudit;
  inputsCtrl.addLieudit = addLieudit;

  init();

  $scope.$watch(() => $scope.model, () => $timeout(init, 0));

  function addLieudit() {
    inputsCtrl.lieudits.push({ nom: { libelle: "" }, typeVoie: { libelle: "" } });
    inputsCtrl.dirty = true;
    inputsCtrl.newlyAdded = true;

    inputsCtrl.onChange && inputsCtrl.onChange();
  }

  function removeLieudit(lieuditToRemove) {
    inputsCtrl.lieudits = $scope.model = _.without(inputsCtrl.lieudits, lieuditToRemove);

    // Il faut au moins un champs affiché
    if (inputsCtrl.lieudits.length === 0) {
      inputsCtrl.lieudits = $scope.model = [{ nom: { libelle: "" }, precision: { libelle: "" } }];
    }
    inputsCtrl.dirty = true;
    inputsCtrl.newlyAdded = false;

    inputsCtrl.onChange && inputsCtrl.onChange();
  }

  function init() {
    inputsCtrl.newlyAdded = false;

    if ($scope.model) {
      inputsCtrl.lieudits = $scope.model = $scope.model.length > 0 ? $scope.model : [{ nom: { libelle: "" }, precision: { libelle: "" } }];
      return;
    }

    inputsCtrl.lieudits = $scope.model = ($scope.initialValues() && $scope.initialValues().length !== 0) ? $scope.initialValues() : [{ nom: { libelle: "" }, precision: { libelle: "" } }];
  }
}
