angular.module('app')
  .directive('mediathequeExpertSearchPictureSliderWrapper', ExpertSearchPictureSliderWrapperDirective);

function ExpertSearchPictureSliderWrapperDirective() {
  return {
    restrict: 'E',
    replace: true,
    transclude: true,
    scope: {
      illustrationId: '@mediathequeIllustrationId'
    },
    controller: ExpertSearchPictureSliderWrapperController,
    controllerAs: 'pictureSliderWrapperCtrl',
    templateUrl: 'illustration/picture-slider-wrapper.html'
  };
}

/*@ngInject*/
function ExpertSearchPictureSliderWrapperController($scope, PictureSliderModalService, ExpertSearchPictureSliderSupplier) {
  var pictureSliderWrapperCtrl = this;

  pictureSliderWrapperCtrl.displaySlider = displaySlider;

  function displaySlider() {
    PictureSliderModalService.displaySlider($scope.illustrationId, ExpertSearchPictureSliderSupplier);
  }
}
