angular.module('app')
  .directive('mediathequeExpertSearchCritereInput', ExpertSearchCritereInputDirective);

function ExpertSearchCritereInputDirective() {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      typeRecherche: '@mediathequeTypeRecherche',
      critere: '&mediathequeCritere',
      champ: '&mediathequeChamp',
      onChange: '&mediathequeOnChange'
    },
    controller: ExpertSearchCritereInputController,
    controllerAs: 'expertSearchCritereInputCtrl',
    templateUrl: 'common/expert-search-critere-input.html'
  };
}

/*@ngInject*/
function ExpertSearchCritereInputController($scope, $rootScope, $filter, initData, $timeout, StringComparator) {
  const collator = new Intl.Collator('fr');
  var expertSearchCritereInputCtrl = this;
  var operators = initData.operators;
  expertSearchCritereInputCtrl.critere = null;
  $scope.$watch($scope.critere, (value) => {
    expertSearchCritereInputCtrl.critere = value;
    initOperators();
    initValues();
  });
  // Ne devrait pas bouger en cours de route, donc pas de $scope.$watch sur le champ
  expertSearchCritereInputCtrl.champ = $scope.champ();
  expertSearchCritereInputCtrl.getOperators = _.memoize(getOperators);
  expertSearchCritereInputCtrl.isOperatorUnary = _.memoize(isOperatorUnary, () => {
    if (_.isEmpty(expertSearchCritereInputCtrl.operateur)) {
      return '';
    }
    return JSON.stringify(expertSearchCritereInputCtrl.operateur);
  });
  expertSearchCritereInputCtrl.isOperatorValid = _.memoize(isOperatorValid, () => {
    if (_.isEmpty(expertSearchCritereInputCtrl.operateur)) {
      return '';
    }
    return JSON.stringify(expertSearchCritereInputCtrl.operateur);
  });
  expertSearchCritereInputCtrl.removeCritere = removeCritere;
  expertSearchCritereInputCtrl.onOperatorChange = onOperatorChange;
  expertSearchCritereInputCtrl.onValueChange = onValueChange;

  function removeCritere() {
    $rootScope.$emit('expert-search-remove-critere', expertSearchCritereInputCtrl.critere.id);
  }

  function onOperatorChange() {
    if (_.isString(expertSearchCritereInputCtrl.operateur) && !_.isEmpty(expertSearchCritereInputCtrl.operateur)) {
      return;
    }
    expertSearchCritereInputCtrl.critere.operateur = expertSearchCritereInputCtrl.operateur && expertSearchCritereInputCtrl.operateur.value;
    $rootScope.$emit('expert-search-update-critere', expertSearchCritereInputCtrl.critere);
  }

  function onValueChange() {
    // sans timeout, la valeur de "expertSearchCritereInputCtrl.temporaryData" n'est pas encore à jour
    // dans le cas où ng-change est détenu par un sous-élément
    $timeout(() => {
      expertSearchCritereInputCtrl.critere.data = _.merge(expertSearchCritereInputCtrl.critere.data || {}, getCritereValueFromForm());
      $rootScope.$emit('expert-search-update-critere', expertSearchCritereInputCtrl.critere);
    }, 0);
  }

  function initOperators() {
    expertSearchCritereInputCtrl.operators = operators[expertSearchCritereInputCtrl.champ.typeChamp];
  }

  function initValues() {
    // Initialisation de l'opérateur :
    // - on sélectionne la valeur actuelle si il y en a une
    // - si un seul opérateur possible : on le sélectionne
    var operators = getOperators();
    expertSearchCritereInputCtrl.operateurUnique = (operators.length === 1);
    var currentCritereOperateur = _.find(operators, { value: expertSearchCritereInputCtrl.critere.operateur })
    if (!currentCritereOperateur) {
      if (expertSearchCritereInputCtrl.operateurUnique) {
        expertSearchCritereInputCtrl.operateur = operators[0];
        onOperatorChange();
      } else {
        expertSearchCritereInputCtrl.operateur = { libelle: '' };
      }
    } else {
      expertSearchCritereInputCtrl.operateur = currentCritereOperateur;
    }
    expertSearchCritereInputCtrl.temporaryData = getCritereValueFromInitialValue();
  }

  function isOperatorValid() {
    if (!expertSearchCritereInputCtrl.operateur || _.isString(expertSearchCritereInputCtrl.operateur || !expertSearchCritereInputCtrl.operateur.value)) {
      return false;
    }
    return _.chain(operators)
      .values(expertSearchCritereInputCtrl.operateur.value)
      .flatten()
      .contains(expertSearchCritereInputCtrl.operateur.value)
      .value();
  }

  function isOperatorUnary() {
    if (!expertSearchCritereInputCtrl.operateur) {
      return false;
    }
    return _.contains(initData.unaryOperators, expertSearchCritereInputCtrl.operateur.value);
  }

  function getOperators($viewValue) {
    return _.chain(operators[expertSearchCritereInputCtrl.champ.typeChamp])
      .map((operator) => {
        return {
          value: operator,
          libelle: $filter('messageFormat')(operator, 'expert.operators')
        };
      })
      .filter((operator) => {
        return StringComparator.startsWith(operator.libelle, ($viewValue || ''));
      })
      .value()
      // sort() n'est pas fonctionnel car il ne prend pas en compte les accents.
      // en utilisant le Collator pour la langue française, on s'assure que l'ordre naturel est conservé
      .sort((o1, o2) => {
        return collator.compare(o1.libelle, o2.libelle);
      });
  }

  function getCritereValueFromForm() {
    switch (expertSearchCritereInputCtrl.champ.typeChamp) {
      case 'Date':
        if (!expertSearchCritereInputCtrl.temporaryData) {
          return {};
        }
        return {
          dateValue: new Date(expertSearchCritereInputCtrl.temporaryData),
          doubleValue: expertSearchCritereInputCtrl.temporaryData.getFullYear() * 10000 +
            (expertSearchCritereInputCtrl.temporaryData.getMonth()+1) * 100 +
            expertSearchCritereInputCtrl.temporaryData.getDate()
        };
      case 'DateAsDouble':
        if (!expertSearchCritereInputCtrl.temporaryData) {
          return {};
        }
        return { doubleValue: expertSearchCritereInputCtrl.temporaryData.getFullYear() * 10000 +
                              (expertSearchCritereInputCtrl.temporaryData.getMonth()+1) * 100 +
                              expertSearchCritereInputCtrl.temporaryData.getDate()};
      case 'Double':
        return { doubleValue: expertSearchCritereInputCtrl.temporaryData };
      case 'Integer':
        return { integerValue: expertSearchCritereInputCtrl.temporaryData };
      case 'Boolean':
        return { booleanValue: expertSearchCritereInputCtrl.temporaryData };
      case 'String':
      case 'StringWithSuggest':
        return { stringValueUn: expertSearchCritereInputCtrl.temporaryData.trim() };
      case 'EnumLockStatus':
        return { enumLockStatusValue: expertSearchCritereInputCtrl.temporaryData.trim() };
      case 'RechercheExperteId': {
        if (!expertSearchCritereInputCtrl.temporaryData) {
          return { stringValueUn: null, libelleUn: null };
        }

        if (_.isString(expertSearchCritereInputCtrl.temporaryData)) {
          return {
            stringValueUn: null,
            libelleUn: expertSearchCritereInputCtrl.temporaryData
          };
        }

        return {
          stringValueUn: expertSearchCritereInputCtrl.temporaryData.id,
          libelleUn: expertSearchCritereInputCtrl.temporaryData.libelle
        };
      }
      case 'Thesaurus':
        if (!expertSearchCritereInputCtrl.temporaryData) {
          return { stringValueUn: null, libelleUn: null };
        }

        if (_.isString(expertSearchCritereInputCtrl.temporaryData)) {
          return {
            stringValueUn: expertSearchCritereInputCtrl.temporaryData,
            libelleUn: expertSearchCritereInputCtrl.temporaryData
          };
        }

        return {
          stringValueUn: expertSearchCritereInputCtrl.temporaryData.thesaurusId,
          libelleUn: expertSearchCritereInputCtrl.temporaryData.libelle
        };
      case 'LienBiblioAuteur':
        // Si le champ est vide, on vide sa valeur

        var personne;
        var role;

        if (!expertSearchCritereInputCtrl.temporaryData || !expertSearchCritereInputCtrl.temporaryData.personne) {
          personne = { stringValueUn: null, libelleUn: null };
        } else if (_.isString(expertSearchCritereInputCtrl.temporaryData.personne)) {
          personne = {
            stringValueUn: null,
            libelleUn: expertSearchCritereInputCtrl.temporaryData.personne
          }
        } else {
          personne = {
            stringValueUn: expertSearchCritereInputCtrl.temporaryData.personne.id,
            libelleUn: expertSearchCritereInputCtrl.temporaryData.personne.libelle
          };
        }

        if (!expertSearchCritereInputCtrl.temporaryData || !expertSearchCritereInputCtrl.temporaryData.role) {
          role = { stringValueDeux: null, libelleDeux: null };
        } else if (_.isString(expertSearchCritereInputCtrl.temporaryData.role)) {
          role = {
            stringValueDeux: expertSearchCritereInputCtrl.temporaryData.role,
            libelleDeux: expertSearchCritereInputCtrl.temporaryData.role
          }
        } else {
          role = {
            stringValueDeux: expertSearchCritereInputCtrl.temporaryData.role.thesaurusId,
            libelleDeux: expertSearchCritereInputCtrl.temporaryData.role.libelle
          };
        }

        return _.merge(personne, role);
      case 'LienIllustrationDossier':
        var dossier;
        if (!expertSearchCritereInputCtrl.temporaryData || !expertSearchCritereInputCtrl.temporaryData.dossier) {
          dossier = { stringValueUn: null, libelleUn: null };
        } else if (_.isString(expertSearchCritereInputCtrl.temporaryData.dossier)) {
          dossier = {
            stringValueUn: null,
            libelleUn: expertSearchCritereInputCtrl.temporaryData.dossier
          }
        } else {
          dossier = {
            stringValueUn: expertSearchCritereInputCtrl.temporaryData.dossier.dossierId,
            libelleUn: expertSearchCritereInputCtrl.temporaryData.dossier.libelle
          };
        }

        return _.merge(dossier, {
          booleanValue: expertSearchCritereInputCtrl.temporaryData.significatif,
          stringValueDeux: expertSearchCritereInputCtrl.temporaryData.legende
        });
      case 'LienIllustrationLot':
      case 'LienBiblioLot':
        var lot;
        if (!expertSearchCritereInputCtrl.temporaryData || !expertSearchCritereInputCtrl.temporaryData.lot) {
          lot = { stringValueUn: null, libelleUn: null };
        } else if (_.isString(expertSearchCritereInputCtrl.temporaryData.lot)) {
          lot = {
            stringValueUn: null,
            libelleUn: expertSearchCritereInputCtrl.temporaryData.lot
          }
        } else {
          lot = {
            stringValueUn: expertSearchCritereInputCtrl.temporaryData.lot.id,
            libelleUn: expertSearchCritereInputCtrl.temporaryData.lot.libelle
          };
        }

        return lot;
      case 'LienIllustrationAuteurOeuvre':
        var auteur;
        var profession;

        if (!expertSearchCritereInputCtrl.temporaryData || !expertSearchCritereInputCtrl.temporaryData.auteur) {
          auteur = { stringValueUn: null, libelleUn: null };
        } else if (_.isString(expertSearchCritereInputCtrl.temporaryData.auteur)) {
          auteur = {
            stringValueUn: null,
            libelleUn: expertSearchCritereInputCtrl.temporaryData.auteur
          }
        } else {
          auteur = {
            stringValueUn: expertSearchCritereInputCtrl.temporaryData.auteur.id,
            libelleUn: expertSearchCritereInputCtrl.temporaryData.auteur.libelle
          };
        }

        if (!expertSearchCritereInputCtrl.temporaryData || !expertSearchCritereInputCtrl.temporaryData.profession) {
          profession = { stringValueDeux: null, libelleDeux: null };
        } else if (_.isString(expertSearchCritereInputCtrl.temporaryData.profession)) {
          profession = {
            stringValueDeux: expertSearchCritereInputCtrl.temporaryData.profession,
            libelleDeux: expertSearchCritereInputCtrl.temporaryData.profession
          }
        } else {
          profession = {
            stringValueDeux: expertSearchCritereInputCtrl.temporaryData.profession.thesaurusId,
            libelleDeux: expertSearchCritereInputCtrl.temporaryData.profession.libelle
          };
        }

        return _.merge(auteur, profession);
      case 'LienIllustrationAuteurQualite':
        var auteur;
        var qualite;

        if (!expertSearchCritereInputCtrl.temporaryData || !expertSearchCritereInputCtrl.temporaryData.auteur) {
          auteur = { stringValueUn: null, libelleUn: null };
        } else if (_.isString(expertSearchCritereInputCtrl.temporaryData.auteur)) {
          auteur = {
            stringValueUn: null,
            libelleUn: expertSearchCritereInputCtrl.temporaryData.auteur
          }
        } else {
          auteur = {
            stringValueUn: expertSearchCritereInputCtrl.temporaryData.auteur.id,
            libelleUn: expertSearchCritereInputCtrl.temporaryData.auteur.libelle
          };
        }

        if (!expertSearchCritereInputCtrl.temporaryData || !expertSearchCritereInputCtrl.temporaryData.qualite) {
          qualite = { stringValueDeux: null, libelleDeux: null };
        } else if (_.isString(expertSearchCritereInputCtrl.temporaryData.qualite)) {
          qualite = {
            stringValueDeux: expertSearchCritereInputCtrl.temporaryData.qualite,
            libelleDeux: expertSearchCritereInputCtrl.temporaryData.qualite
          }
        } else {
          qualite = {
            stringValueDeux: expertSearchCritereInputCtrl.temporaryData.qualite.thesaurusId,
            libelleDeux: expertSearchCritereInputCtrl.temporaryData.qualite.libelle
          };
        }

        return _.merge(auteur, qualite);
      default:
        throw new Error(`Le type de champ ${ expertSearchCritereInputCtrl.champ.typeChamp } n'est pas géré.`);
    }
  }

  function getCritereValueFromInitialValue() {
    switch (expertSearchCritereInputCtrl.champ.typeChamp) {
      case 'Date':
      case 'DateAsDouble':
        if (!expertSearchCritereInputCtrl.critere.data.doubleValue) {
          return null;
        }
        var str = ''+expertSearchCritereInputCtrl.critere.data.doubleValue;
        return new Date(str.substring(0, 4),
                        str.substring(4, 6) - 1,
                        str.substring(6, 8));
      case 'Integer':
        return expertSearchCritereInputCtrl.critere.data.integerValue;
      case 'Double':
        return expertSearchCritereInputCtrl.critere.data.doubleValue;
      case 'String':
      case 'StringWithSuggest':
        return expertSearchCritereInputCtrl.critere.data.stringValueUn;
      case 'EnumLockStatus':
        return expertSearchCritereInputCtrl.critere.data.enumLockStatusValue;
      case 'Thesaurus':
        return {
          libelle: expertSearchCritereInputCtrl.critere.data.libelleUn || '',
          thesaurusId: expertSearchCritereInputCtrl.critere.data.stringValueUn
        };
      case 'RechercheExperteId':
        return {
          libelle: expertSearchCritereInputCtrl.critere.data.libelleUn || '',
          id: expertSearchCritereInputCtrl.critere.data.stringValueUn
        };
      case 'Boolean':
        return expertSearchCritereInputCtrl.critere.data.booleanValue;
      // TODO: enumLockStatus, lien auteurs et dossiers pour les illustrations
      case 'LienBiblioAuteur':
        return {
          personne: {
            libelle: expertSearchCritereInputCtrl.critere.data.libelleUn || '',
            id: expertSearchCritereInputCtrl.critere.data.stringValueUn
          },
          role: {
            libelle: expertSearchCritereInputCtrl.critere.data.libelleDeux || '',
            thesaurusId: expertSearchCritereInputCtrl.critere.data.stringValueDeux
          }
        };
      case 'LienIllustrationDossier':
        return {
          dossier: {
            libelle: expertSearchCritereInputCtrl.critere.data.libelleUn || '',
            id: expertSearchCritereInputCtrl.critere.data.stringValueUn,
            dossierId: expertSearchCritereInputCtrl.critere.data.stringValueUn
          },
          significatif: expertSearchCritereInputCtrl.critere.data.booleanValue,
          legende: expertSearchCritereInputCtrl.critere.data.stringValueDeux
        };
      case 'LienIllustrationLot':
      case 'LienBiblioLot':
        return {
          lot: {
            libelle: expertSearchCritereInputCtrl.critere.data.libelleUn || '',
            id: expertSearchCritereInputCtrl.critere.data.stringValueUn
          }
        };
      case 'LienIllustrationAuteurOeuvre':
        return {
          auteur: {
            libelle: expertSearchCritereInputCtrl.critere.data.libelleUn || '',
            id: expertSearchCritereInputCtrl.critere.data.stringValueUn
          },
          profession: {
            libelle: expertSearchCritereInputCtrl.critere.data.libelleDeux || '',
            thesaurusId: expertSearchCritereInputCtrl.critere.data.stringValueDeux
          }
        };
      case 'LienIllustrationAuteurQualite':
        return {
          auteur: {
            libelle: expertSearchCritereInputCtrl.critere.data.libelleUn || '',
            id: expertSearchCritereInputCtrl.critere.data.stringValueUn
          },
          qualite: {
            libelle: expertSearchCritereInputCtrl.critere.data.libelleDeux || '',
            thesaurusId: expertSearchCritereInputCtrl.critere.data.stringValueDeux
          }
        };
      default:
        throw new Error(`Le type de champ ${ expertSearchCritereInputCtrl.champ.typeChamp } n'est pas géré.`);
    }
  }
}
