(function(module) {
try {
  module = angular.module('app');
} catch (e) {
  module = angular.module('app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('common/multivalued-select-input.html',
    '<div class="multivalued-input row--flex"><input type=hidden data-mediatheque-default-value=false value="{{ inputsCtrl.dirty }}"><div ng-repeat="value in model" class="{{ cssClasses }}"><div class=input-group><mediatheque-select-input mediatheque-input-class="{{ inputClass }}" mediatheque-placeholder="{{ placeholder }}" mediatheque-suggestions=suggestions() ng-model=value mediatheque-on-change=onChange() mediatheque-apply-focus="$last && inputsCtrl.newlyAdded"></mediatheque-select-input><span class=input-group-btn><button type=button class="btn btn-addon {{ inputClass }}" ng-click=inputsCtrl.removeValue(value) title="{{ removeElementTitle}}"><span class="fa fa-times-circle"></span></button></span></div></div><button type=button class="btn btn-transparent btn-input-actions" ng-click=inputsCtrl.addValue() title="{{ addElementTitle }}"><span class="fa fa-plus"></span></button></div>');
}]);
})();
