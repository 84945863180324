var initDataElement = document.getElementById('initial-data');
var initData = {};
if (initDataElement) {
  initData = angular.fromJson(initDataElement.innerHTML);
}

var apiConfiguration = getConfiguration('api-configuration');
var messagesFormatterConfiguration = getConfiguration('messages-formatter-configuration');
var securityConfiguration = getConfiguration('security-configuration');
var bookmarks = getConfiguration('bookmarks');

var modules = [
  'ngSanitize',
  'ngMessageFormat',
  'ui.bootstrap.collapse',
  'ui.bootstrap.dropdown',
  'ui.bootstrap.tabs',
  'ui.bootstrap.datepicker',
  'ui.bootstrap.position',
  'ui.bootstrap.bindHtml',
  'ui.bootstrap.modal',
  'ui.bootstrap.progressbar',
  'dragularModule',
  'ngFileUpload',
  'template/tabs/tab.html',
  'template/tabs/tabset.html',
  'template/modal/window.html',
  'template/modal/backdrop.html',
  'template/progressbar/progressbar.html',
  'template/typeahead/typeahead-match.html',
  'template/typeahead/typeahead-popup.html'
];
var app = angular.module('app', modules)
    .value('initData', initData)
    .value('SuggestApiConfiguration', apiConfiguration.suggestApi)
    .value('AuteurBiblioApiConfiguration', apiConfiguration.auteurBiblioApi)
    .value('FichierApiConfiguration', apiConfiguration.fichierApi)
    .value('PersonneApiConfiguration', apiConfiguration.personneApi)
    .value('RefDocApiConfiguration', apiConfiguration.refDocApi)
    .value('DossierApiConfiguration', apiConfiguration.dossierApi)
    .value('ExpertSearchApiConfiguration', apiConfiguration.expertSearchApi)
    .value('BookmarkApiConfiguration', apiConfiguration.bookmarkApi)
    .value('SimpleSearchApiConfiguration', apiConfiguration.simpleSearchApi)
    .value('LotApiConfiguration', apiConfiguration.lotApi)
    .value('MessageFormatterConfiguration', messagesFormatterConfiguration)
    .value('SecurityConfiguration', securityConfiguration)
    .value('Bookmarks', bookmarks)
    .run(function($http, SecurityConfiguration) {
      $http.defaults.headers.common['X-XSRF-TOKEN'] = SecurityConfiguration['_xsrf_'];
    })
    .factory('$exceptionHandler', ['$log', function($log) {
      return function myExceptionHandler(exception, cause) {
        window.Sentry && window.Sentry.captureException(exception)
        $log.error(exception, cause);
      };
    }]);

function getConfiguration(id) {
  var configurationElement = document.getElementById(id);
  if (!configurationElement || !configurationElement.innerHTML || !configurationElement.innerHTML.trim()) {
    return {};
  }
  return angular.fromJson(configurationElement.innerHTML);
}
