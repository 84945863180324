angular.module('app')
  .directive('mediathequeMultivaluedLienInput', MultivaluedLienInputDirective);

function MultivaluedLienInputDirective() {
  return {
    restrict: 'E',
    scope: {
      name: '@mediathequeName',
      id: '@mediathequeId',
      form: '@mediathequeForm',
      disabled: '&mediathequeDisabled',
      initialValues: '&mediathequeInitialValues'
    },
    replace: true,
    controller: MultivaluedLienInputController,
    controllerAs: 'inputsCtrl',
    templateUrl: 'common/multivalued-lien-input.html'
  };
}

/*@ngInject*/
function MultivaluedLienInputController($scope) {
  var inputsCtrl = this;

  inputsCtrl.id = $scope.id;
  inputsCtrl.name = $scope.name;
  inputsCtrl.form = $scope.form;

  inputsCtrl.removeLien = removeLien;
  inputsCtrl.addLien = addLien;
  inputsCtrl.isDisabled = $scope.disabled;

  init();

  function addLien() {
    inputsCtrl.liens.push('');
    inputsCtrl.dirty = true;
    inputsCtrl.newlyAdded = true;
  }

  function removeLien(lienToRemove) {
    inputsCtrl.liens = _.without(inputsCtrl.liens, lienToRemove);

    // Il faut au moins un champs affiché
    if (inputsCtrl.liens.length === 0) {
      inputsCtrl.liens = [''];
    }
    inputsCtrl.dirty = true;
    inputsCtrl.newlyAdded = false;
  }

  function init() {
    inputsCtrl.liens = ($scope.initialValues() && $scope.initialValues().length !== 0) ? $scope.initialValues() : [''];
    inputsCtrl.dirty = false;
    inputsCtrl.newlyAdded = false;
  }
}
