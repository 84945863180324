angular.module('app')
  .directive('mediathequeAppNotifications', AppNotificationsDirective);

/*@ngInject*/
function AppNotificationsDirective($window) {
  return {
    restrict: 'E',
    controller: AppNotificationsController,
    controllerAs: 'notificationsCtrl',
    replace: true,
    link: function (scope, element) {
      var windowEl = angular.element($window);

      windowEl.on('scroll', checkPosition);
      windowEl.on('resize', checkPosition);

      checkPosition();

      function checkPosition() {
        // jqLite elements are like arrays of raw DOM elements (with some additional functionalities)
        var top = element[0].getBoundingClientRect().top;
        var children = element.children();
        if (top <= 0) {
          // The main element is (starting to be) not visible so let's make it fixed to the top of the viewport
          // It will now follow the user during scrolling events.
          children.addClass('notifications-fixed');
          children.removeClass('notifications-absolute');
        } else {
          // The main element is entirely visible. It shouldn't be moved vertically to not hide the navigation bar.
          children.addClass('notifications-absolute');
          children.removeClass('notifications-fixed');
        }
      }
    },
    templateUrl: 'common/app-notifications.html'
  };
}

/*@ngInject*/
function AppNotificationsController($rootScope, $timeout) {
  var notificationsCtrl = this;
  notificationsCtrl.notifications = [];

  notificationsCtrl.getVisibleNotifications = getVisibleNotifications;

  notificationsCtrl.close = close;

  $rootScope.$on('notification.notify', function(event, notification) {
    if (notification.delay) {
      notification.delayed = true;
      $timeout(function() {
        notification.delayed = false;
      }, notification.delay);
    }
    addNotification(notification);

    $timeout(function() {
      notificationsCtrl.close(notification);
    }, 3000);
  });

  function getVisibleNotifications() {
    return _.reject(notificationsCtrl.notifications, { 'delayed': true });
  }

  function close(notification) {
    notificationsCtrl.notifications = _.reject(notificationsCtrl.notifications, { 'id': notification.id });
  }

  function addNotification(notification) {
    if (!notification.replace || !_.some(notificationsCtrl.notifications, { 'id': notification.replace })) {
      notificationsCtrl.notifications.push(notification);
      return;
    }

    var index = _.findIndex(notificationsCtrl.notifications, { 'id': notification.replace });
    notificationsCtrl.notifications[index] = notification;
  }
}
