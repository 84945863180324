angular.module('app')
  .directive('mediathequeDossierFieldInput', DossierFieldInputDirective);

function DossierFieldInputDirective() {
  return {
    restrict: 'E',
    scope: {
      name: '@mediathequeName',
      id: '@mediathequeId',
      form: '@mediathequeForm',
      disabled: '&mediathequeDisabled',
      model: '=ngModel',
      onChange: '&mediathequeOnChange',
      placeholder: '@mediathequePlaceholder',
      initialValue: '&mediathequeInitialValue',
      typesDossierField: '&mediathequeTypesDossierField',
      applyFocus: '&mediathequeApplyFocus'
    },
    replace: true,
    controller: DossierFieldInputController,
    controllerAs: 'inputCtrl',
    templateUrl: 'common/dossier-field-input.html'
  };
}

/*@ngInject*/
function DossierFieldInputController($scope, EntitySuggest) {
  var inputCtrl = this;

  inputCtrl.getSuggestions = getSuggestions;
  inputCtrl.hasValidValue = hasValidValue;
  inputCtrl.onChange = $scope.onChange;

  init();

  // TODO: Ajouter un watcher sur $scope.model (couplé à un $timeout pour s'assurer que le scope est bien à jour)
  // si le model initial est modifié par le composant parent

  function init() {
    if ($scope.model) {
      return;
    }
    // { libelle: "" } par défaut pour satisfaire UiBootstrap#Select
    $scope.model = $scope.initialValue() || { libelle: "" };
  }

  function hasValidValue() {
    if ($scope.model === '') {
      return true;
    }
    if ($scope.model.dossierId) {
      return true;
    }
    return $scope.model.libelle === '' || $scope.model.libelle === null;
  }

  inputCtrl.loading = false;
  function getSuggestions(currentValue) {
    if ($scope.disabled()) {
      return;
    }
    inputCtrl.loading = true;
    return EntitySuggest.search("Dossier", currentValue)
      .then((result) => {
        inputCtrl.loading = false;
        return _.map(result.data, (dossierField) => {return _.assign(dossierField, {dossierId: dossierField.id})});
      });
  }
}
