(function(module) {
try {
  module = angular.module('app');
} catch (e) {
  module = angular.module('app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('common/contribution-biblio-input.html',
    '<div class=row><div class=col-sm-8><div class=input-group><mediatheque-auteurbiblio-input data-mediatheque-name="{{ name + \'.auteur\' }}" data-mediatheque-id="{{ id + \'-auteurId\' }}" ng-attr-mediatheque-form="{{ form }}" mediatheque-placeholder=auteur ng-model=inputCtrl.contribution.auteur mediatheque-initial-value=inputCtrl.contribution.auteur mediatheque-disabled=disabled() mediatheque-apply-focus=applyFocus()></mediatheque-auteurbiblio-input><span class=input-group-btn><button type=button class="btn btn-addon" ng-click=inputCtrl.editAuteurBiblio() title="Modifier les informations de l\'auteur" ng-disabled="! inputCtrl.contribution.auteur.id"><span class="fa fa-pencil"></span></button></span> <span class=input-group-btn ng-show="! disabled()"><button type=button class="btn btn-addon" ng-click=inputCtrl.createAuteurBiblio() title="Créer un nouvel auteur"><span class="fa fa-user-plus"></span></button></span></div></div><div class=col-sm-4><mediatheque-thesaurus-input mediatheque-name="{{ name + \'.role\' }}" mediatheque-id="{{ id + \'-role\' }}" ng-attr-mediatheque-form="{{ form }}" mediatheque-placeholder=rôle mediatheque-disabled=disabled() mediatheque-initial-value=inputCtrl.contribution.role mediatheque-types-thesaurus="[\'BiblioRoleAuteur\']"></mediatheque-thesaurus-input></div></div>');
}]);
})();
