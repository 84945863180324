angular.module('app')
  .factory('PictureSliderModalService', PictureSliderModalService);

/*@ngInject*/
function PictureSliderModalService($rootScope, $modal, $q) {
  var supplier = null;
  var images = null;
  var currentPosition = null;
  var loadingMoreImages = false;

  return { displaySlider, getPreviousImage, getNextImage, hasPreviousImage, hasNextImage, canGetMoreImages, isLoadingMoreImages, loadMoreImages };

  function displaySlider(initialIllustrationId, pictureSupplier) {
    supplier = pictureSupplier;

    initSlider(initialIllustrationId);

    return $modal.open({
      animation: true,
      size: 'lg',
      templateUrl: 'illustration/picture-slider-modal.html',
      controller: PictureSliderModalCtrl,
      controllerAs: 'pictureSliderModalCtrl',
      resolve: {
        initialImage: () => images[currentPosition]
      }
    }).result;
  }

  function initSlider(initialIllustrationId) {
    images = supplier.getImages();

    currentPosition = _.findIndex(images, { illustrationId: initialIllustrationId });
  }

  function getPreviousImage() {
    return images[--currentPosition];
  }

  function getNextImage() {
    return images[++currentPosition];
  }

  function hasPreviousImage() {
    return currentPosition > 0;
  }

  function hasNextImage() {
    return currentPosition + 1 < images.length;
  }

  function canGetMoreImages() {
    return supplier.canGetMoreImages();
  }

  function isLoadingMoreImages() {
    return loadingMoreImages;
  }

  function loadMoreImages() {
    loadingMoreImages = true;
    supplier.loadMoreImages()
      .then(() => {
        loadingMoreImages = false;
        images = supplier.getImages();
      }, () => loadingMoreImages = false);
  }
}

function PictureSliderModalCtrl($scope, $modalInstance, initialImage, PictureSliderModalService) {
  var pictureSliderModalCtrl = this;

  pictureSliderModalCtrl.image = initialImage;
  pictureSliderModalCtrl.loadingNextPage = false;

  pictureSliderModalCtrl.titre = _.memoize(titre, () => pictureSliderModalCtrl.image.illustrationId);
  pictureSliderModalCtrl.nextImage = nextImage;
  pictureSliderModalCtrl.previousImage = previousImage;
  pictureSliderModalCtrl.hasNextImage = PictureSliderModalService.hasNextImage;
  pictureSliderModalCtrl.hasPreviousImage = PictureSliderModalService.hasPreviousImage;
  pictureSliderModalCtrl.canGetMoreImages = PictureSliderModalService.canGetMoreImages;
  pictureSliderModalCtrl.isLoadingMoreImages = PictureSliderModalService.isLoadingMoreImages;
  pictureSliderModalCtrl.loadMoreImages = PictureSliderModalService.loadMoreImages;
  pictureSliderModalCtrl.close = close;
  pictureSliderModalCtrl.onKeydown = onKeydown;

  function titre() {
    if (pictureSliderModalCtrl.image.titreCourant) {
      return pictureSliderModalCtrl.image.titreCourant;
    }
    return _.map(pictureSliderModalCtrl.image.denominations, 'libelle').join(', ');
  }

  function nextImage() {
    pictureSliderModalCtrl.image = PictureSliderModalService.getNextImage();
  }

  function previousImage() {
    pictureSliderModalCtrl.image = PictureSliderModalService.getPreviousImage();
  }

  function close() {
    return $modalInstance.dismiss();
  }

  function onKeydown($event) {
    switch ($event.keyCode) {
      case 37:
        if (!PictureSliderModalService.hasPreviousImage()) {
          break;
        }
        previousImage();
        break;
      case 39:
        if (!PictureSliderModalService.hasNextImage()) {
          break;
        }
        nextImage();
        break;
    }
  }
}
