angular.module('app')
  .directive('mediathequeOnBeforeUnload', OnBeforeUnloadDirective);

function OnBeforeUnloadDirective() {
  return {
    restrict: 'A',
    link: (scope, element) => {
      window.onbeforeunload = handleBeforeUnload;

      var currentElement = element[0];
      if (currentElement.tagName === 'FORM') {
        currentElement.onsubmit = protectOnSubmit;
      } else {
        var forms = currentElement.querySelectorAll('form');
        for (let i = 0; i < forms.length; i++) {
          forms[i].onsubmit = protectOnSubmit;
        }
      }
    }
  };
}

function handleBeforeUnload(event) {
  var submittedForm = null;
  // Dans le cas où l'utilisateur clique sur le bouton Submit d'un formulaire ou s'il appuie sur la touche entrée tout en ayant le focus sur un champs
  if (event.target.activeElement.form && event.target.activeElement.form.hasAttribute('data-mediatheque-submitted')) {
    submittedForm = event.target.activeElement.form;
  }

  var inputs = document.querySelectorAll('[mediatheque-on-before-unload] select:not([ignorable-value]):not([data-ignorable-value]):not([disabled]) option, [mediatheque-on-before-unload] textarea, [mediatheque-on-before-unload] input:not([ignorable-value]):not([data-ignorable-value]):not([type=button]):not([type=submit]):not([type=reset]):not([type=image])');
  for (let i = 0; i < inputs.length; i++) {
    var input = inputs[i];
    // On ne vérifie pas le formulaire qui vient d'être soumis
    if (input.form && input.form === submittedForm) {
      continue;
    }

    if (isDirty(input)) {
      // XXX: Rendre ce message configurable ?
      if (submittedForm) {
        submittedForm.removeAttribute('data-mediatheque-submitted');
      }
      return 'La page contient des données non sauvegardées.';
    }
  }
}

function isDirty(element) {
  if (element.tagName === 'OPTION') {
    return element.defaultSelected !== element.selected;
  }

  if (element.tagName === 'TEXTAREA') {
    return element.defaultValue !== element.value;
  }

  // On suppose ici que l'élement est un input
  switch (element.type) {
    case 'radio':
    case 'checkbox':
      return element.defaultChecked !== element.checked;
    case 'hidden': {
      if (!element.hasAttribute('data-mediatheque-default-value') && !element.hasAttribute('mediatheque-default-value')) {
        // element.defaultValue est toujours égal à element.value dans le cas d'un input[type=hidden]
        // Seule la valeur contenue dans l'attribut mediatheque-default-value peut nous fournir cette donnée
        return false;
      }
      var defaultValue = element.getAttribute('data-mediatheque-default-value') || element.getAttribute('mediatheque-default-value') || '';
      return defaultValue !== element.value;
    }
    default:
      return element.defaultValue !== element.value;
  }
}

function protectOnSubmit(event) {
  event.target.setAttribute('data-mediatheque-submitted', 'true');
}
