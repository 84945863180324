angular.module('app')
  .directive('mediathequeIllustrationResults', IllustrationResultsDirective);

function IllustrationResultsDirective() {
  return {
    restrict: 'E',
    replace: true,
    controller: IllustrationResultController,
    controllerAs: 'illustrationResultsCtrl',
    templateUrl: 'illustration/illustration-results.html'
  };
}

/*@ngInject*/
function IllustrationResultController($scope, $rootScope, IllustrationResultsService) {
  var illustrationResultsCtrl = this;

  illustrationResultsCtrl.hasMoreResults = IllustrationResultsService.hasMoreResults;
  illustrationResultsCtrl.displayNextResults = displayNextResults;
  illustrationResultsCtrl.displayAllResults = displayAllResults;

  $scope.$on('illustration-results-changed', handleResultsChanged);
  $scope.$on('illustration-results-mosaic-changed', reloadMosaicMode);
  $scope.$on('illustration-search-loading', handleResultsLoading);
  $scope.$on('illustration-search-successful', handleResultsLoaded);
  $scope.$on('illustration-search-error', handleResultsLoaded);

  init();

  illustrationResultsCtrl.selectResult = selectResult;

  function selectResult(result) {
    if (result.selected) {
      $rootScope.$emit('illustration-result-unselect', result.id);
    } else {
      $rootScope.$emit('illustration-result-select', result.id);
    }
  }
  function displayAllResults() {
    $rootScope.$emit('illustration-results-all');
  }
  function displayNextResults() {
    $rootScope.$emit('illustration-results-next');
  }

  function init() {
    handleResultsChanged();
    handleResultsLoaded();
    reloadMosaicMode();
  }

  function handleResultsChanged() {
    illustrationResultsCtrl.resultats = IllustrationResultsService.getResults();
    illustrationResultsCtrl.allSelected = IllustrationResultsService.areAllSelected();
    illustrationResultsCtrl.atLeastOneSelected = IllustrationResultsService.isAtLeastOneSelected();
    illustrationResultsCtrl.numberDisplayedResults = IllustrationResultsService.getNumberDisplayedResults();
    illustrationResultsCtrl.totalResults = IllustrationResultsService.getTotalResults();
  }

  function handleResultsLoaded() {
    illustrationResultsCtrl.loading = false;
    illustrationResultsCtrl.loaded = true;
  }

  function handleResultsLoading() {
    illustrationResultsCtrl.loading = true;
  }

  function reloadMosaicMode() {
    illustrationResultsCtrl.mosaic = IllustrationResultsService.isMosaicEnabled();
  }
}
