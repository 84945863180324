(function(module) {
try {
  module = angular.module('app');
} catch (e) {
  module = angular.module('app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('illustration/illustration-with-error-tab-bar.html',
    '<div><mediatheque-collapse-button data-mediatheque-btn-label=Lot data-mediatheque-btn-class="btn btn-default" class=collapse--width-auto><div ng-if=illustrationWithErrorTabBarCtrl.atLeastOneSelected><div class=form-group><h3>À partir de la sélection</h3></div><div class=form-group><button ng-disabled=!illustrationWithErrorTabBarCtrl.atLeastOneSelected class="btn btn-block btn-default" type=submit>Créer un lot</button></div><div class="form-group divider"></div><div class=form-group><mediatheque-lot-input data-mediatheque-name=lot ng-init="modelLotId = { libelle: \'\' };" ng-model=modelLotId data-mediatheque-type-lot=ILLUSTRATION data-mediatheque-placeholder="Sélectionner un lot" data-mediatheque-disabled=!illustrationWithErrorTabBarCtrl.atLeastOneSelected></mediatheque-lot-input></div><div class=form-group><button ng-disabled="!illustrationWithErrorTabBarCtrl.atLeastOneSelected || !modelLotId.id" class="btn btn-block btn-default" type=submit>Compléter ce lot</button></div></div><div ng-if="! illustrationWithErrorTabBarCtrl.atLeastOneSelected"><div class=form-group><h3>À partir de tous les résultats</h3></div><div class=form-group><button class="btn btn-block btn-default" type=submit form=createLotAll>Créer un lot</button></div><div class="form-group divider"></div><div class=form-group><mediatheque-lot-input data-mediatheque-name=lot ng-init="modelLotIdSimple = { libelle: \'\' };" ng-model=modelLotIdSimple data-mediatheque-type-lot=ILLUSTRATION data-mediatheque-form=createLotAll data-mediatheque-placeholder="Sélectionner un lot"></mediatheque-lot-input></div><div class=form-group><button ng-disabled=!modelLotIdSimple.id class="btn btn-block btn-default" type=submit form=createLotAll>Compléter ce lot</button></div></div></mediatheque-collapse-button></div>');
}]);
})();
