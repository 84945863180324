(function(module) {
try {
  module = angular.module('app');
} catch (e) {
  module = angular.module('app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('template/datepicker/month.html',
    '<table role=grid aria-labelledby={{uniqueId}}-title aria-activedescendant={{activeDateId}}><thead><tr><th><button type=button class="btn btn-link btn-sm pull-left" ng-click=move(-1) tabindex=-1><i class="fa fa-chevron-left"></i></button></th><th><button id={{uniqueId}}-title role=heading aria-live=assertive aria-atomic=true type=button class="btn btn-link btn-sm" ng-click=toggleMode() ng-disabled="datepickerMode === maxMode" tabindex=-1 style=width:100%;><strong>{{title}}</strong></button></th><th><button type=button class="btn btn-link btn-sm pull-right" ng-click=move(1) tabindex=-1><i class="fa fa-chevron-right"></i></button></th></tr></thead><tbody><tr ng-repeat="row in rows track by $index"><td ng-repeat="dt in row track by dt.date" class=text-center role=gridcell id={{dt.uid}} aria-disabled={{!!dt.disabled}}><button type=button style=width:100%; class=btn ng-class="{\'btn-default\': dt.selected, \'btn-link\': !dt.selected, active: isActive(dt)}" ng-click=select(dt.date) ng-disabled=dt.disabled tabindex=-1><span ng-class="{\'text-info\': dt.current}">{{dt.label}}</span></button></td></tr></tbody></table>');
}]);
})();
