angular.module('app')
  .directive('mediathequeAppNotification', AppNotificationDirective);

/*@ngInject*/
function AppNotificationDirective($q, $http, $compile, $templateCache) {
  return {
    require: '^mediathequeAppNotifications',
    restrict: 'E',
    replace: true,
    scope: {
      'notification': '&mediathequeNotification'
    },
    template: '<div></div>',
    link: function($scope, element) {
      if ($scope.notification().content) {
        element.append(content);
        return;
      }

      var contentTemplate = $scope.notification().contentTemplate;
      $q.when($templateCache.get(contentTemplate) || $http.get(contentTemplate))
        .then(function(res) {
          if(angular.isObject(res)) {
            $templateCache.put(contentTemplate, res.data);
            return res.data;
          }
          return res;
        }).then(function(template) {
          var compiledTemplate = $compile(template)($scope);
          element.append(compiledTemplate);
        });
    }
  };
}
