angular.module('app')
  .directive('mediathequeMultivaluedThesaurusInput', MultivaluedThesaurusInputDirective);

function MultivaluedThesaurusInputDirective() {
  return {
    restrict: 'E',
    scope: {
      name: '@mediathequeName',
      id: '@mediathequeId',
      addElementTitle: '@mediathequeAddElementTitle',
      removeElementTitle: '@mediathequeRemoveElementTitle',
      inputPlaceholder: '@mediathequeInputPlaceholder',
      form: '@mediathequeForm',
      disabled: '&mediathequeDisabled',
      initialValues: '&mediathequeInitialValues',
      typesThesaurus: '&mediathequeTypesThesaurus',
      model: '=ngModel',
      onChange: '&mediathequeOnChange'
    },
    replace: true,
    controller: MultivaluedThesaurusInputController,
    controllerAs: 'inputsCtrl',
    templateUrl: 'common/multivalued-thesaurus-input.html'
  };
}

/*@ngInject*/
function MultivaluedThesaurusInputController($scope, $timeout) {
  var inputsCtrl = this;

  inputsCtrl.dirty = false;

  inputsCtrl.onChange = $scope.onChange;

  inputsCtrl.addValue = addValue;
  inputsCtrl.removeValue = removeValue;

  init();

  $scope.$watch(() => $scope.model, () => $timeout(init, 0));

  function addValue() {
    inputsCtrl.values.push({ libelle: "" });
    inputsCtrl.dirty = true;
    inputsCtrl.newlyAdded = true;
  }

  function removeValue(valueToRemove) {
    inputsCtrl.values = $scope.model = _.without(inputsCtrl.values, valueToRemove);

    // Il faut au moins un champs affiché
    if (inputsCtrl.values.length === 0) {
      // { libelle: "" } par défaut pour satisfaire UiBootstrap#Select
      inputsCtrl.values = $scope.model = [{ libelle: "" }];
    }
    inputsCtrl.dirty = true;
    inputsCtrl.newlyAdded = false;
  }

  function init() {
    inputsCtrl.newlyAdded = false;
    if ($scope.model) {
      inputsCtrl.values = $scope.model = $scope.model.length > 0 ? $scope.model : [{ libelle: "" }];
      return;
    }
    // { libelle: "" } par défaut pour satisfaire UiBootstrap#Select
    inputsCtrl.values = $scope.model = ($scope.initialValues() && $scope.initialValues().length !== 0) ? $scope.initialValues() : [{ libelle: "" }];
  }
}
