(function(module) {
try {
  module = angular.module('app');
} catch (e) {
  module = angular.module('app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('common/collapse-button.html',
    '<div class="collapse-button {{ containerClass }}"><button ng-click=collapseButtonCtrl.toggle() class="{{ btnClass }}" ng-class="{ \'active\': !isCollapsed }" type=button ng-disabled=disabled()><span ng-if=btnIcon class="fa fa-{{::btnIcon}}"></span><span ng-if=btnLabel>{{ btnLabel }}</span> <span class="fa fa-caret-down"></span></button><div ng-transclude collapse="isCollapsed || disabled()" class=collapse-button-panel></div></div>');
}]);
})();
