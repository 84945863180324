angular.module('app')
  .factory('StringFromPathSuggest', StringFromPathSuggest);

/*@ngInject*/
function StringFromPathSuggest($http, $q, SuggestApiConfiguration) {
  return { search };

  function search(query, entityType, fieldPath) {

    return $http({
      method: 'GET',
      cache: true,
      url: SuggestApiConfiguration.stringFromPathUri,
      params: { entityType: entityType, fieldPath: fieldPath, query: query }
    });
  }
}
