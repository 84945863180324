(function(module) {
try {
  module = angular.module('app');
} catch (e) {
  module = angular.module('app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('common/expert-search-options.html',
    '<div class=well><div class="form-group form-group--flex"><label class="control-label col-sm-12">Afficher les champs suivants</label><div class=col-sm-12><mediatheque-multivalued-select-input ng-model=expertSearchOptionsCtrl.visibleColumns mediatheque-class=tag-input mediatheque-suggestions=expertSearchOptionsCtrl.getChampsAffichables mediatheque-on-change=expertSearchOptionsCtrl.updateChampsAffichables() mediatheque-add-element-title="Ajouter un champ" mediatheque-remove-element-title="Supprimer un champ"></mediatheque-multivalued-select-input></div><div class=col-sm-12><button type=button class="btn btn-default" ng-click=expertSearchOptionsCtrl.selectAll()>Tous les autres champs</button></div><div class=clearfix></div></div><div class=form-group><label class="control-label col-sm-12">Trier sur le champ</label><div class=col-sm-10><mediatheque-select-input ng-model=expertSearchOptionsCtrl.champTri mediatheque-suggestions=expertSearchOptionsCtrl.getChampsTriables mediatheque-on-change=expertSearchOptionsCtrl.updateChampTri()></mediatheque-select-input></div><div class=col-sm-4><select class=form-control ng-model=expertSearchOptionsCtrl.ordreTri ng-change=expertSearchOptionsCtrl.updateChampTri() ng-options="ordreTri.value as ordreTri.libelle for ordreTri in [ { \'value\': \'Asc\', \'libelle\': \'Ascendant\' }, { \'value\': \'Desc\', \'libelle\': \'Descendant\' } ]"></select></div><div class=clearfix></div></div></div>');
}]);
})();
