angular.module('app')
  .directive('mediathequeTaskCheckboxInput', TaskCheckboxInputDirective);

function TaskCheckboxInputDirective() {
  return {
    restrict: 'E',
    scope: {
      id: '@mediathequeId',
      name: '@mediathequeName',
      form: '@mediathequeForm'
    },
    replace: false,
    controller: TaskCheckboxInputController,
    controllerAs: 'taskCheckboxInputCtrl',
    templateUrl: 'task/task-checkbox-input.html'
  };
}

/*@ngInject*/
function TaskCheckboxInputController($scope, $rootScope, TasksService) {
  var taskCheckboxInputCtrl = this;

  taskCheckboxInputCtrl.id = $scope.id;
  taskCheckboxInputCtrl.name = $scope.name;
  taskCheckboxInputCtrl.form = $scope.form;
  taskCheckboxInputCtrl.onSelect = onSelect;

  $scope.$on('tasks-select-changed', onChange);

  function onChange() {
    taskCheckboxInputCtrl.selected = TasksService.getSelected(taskCheckboxInputCtrl.id);
  }

  function onSelect() {
    if (!taskCheckboxInputCtrl.selected) {
      $rootScope.$emit('tasks-unselect', taskCheckboxInputCtrl.id);
    } else {
      $rootScope.$emit('tasks-select', taskCheckboxInputCtrl.id);
    }
  }
}
