(function(module) {
try {
  module = angular.module('app');
} catch (e) {
  module = angular.module('app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('illustration/illustration-input.html',
    '<div class=has-feedback><input type=hidden name="{{ inputCtrl.name }}" value="{{ inputCtrl.model.id }}"> <input type=text id="{{ id }}" class=form-control autocomplete=off ng-model=inputCtrl.model ng-change=inputCtrl.onChange() typeahead="suggestion as suggestion.libelle for suggestion in inputCtrl.getSuggestions($viewValue)" typeahead-show-on-click=true typeahead-template-url=common/entity-suggest.html typeahead-on-select=inputCtrl.onChange() typeahead-wait-ms=300 typeahead-loading=inputCtrl.loading> <span class="fa suggest-feedback" ng-class="{\'fa-caret-down\': !inputCtrl.loading, \'fa-gear\': inputCtrl.loading, \'text-danger\': !inputCtrl.hasValidValue() }"></span></div>');
}]);
})();
