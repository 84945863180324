(function(module) {
try {
  module = angular.module('app');
} catch (e) {
  module = angular.module('app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('common/multivalued-dossier-field-input.html',
    '<div class=multivalued-input><input type=hidden data-mediatheque-default-value=false value="{{ inputsCtrl.dirty }}" ng-attr-form="{{ form }}"><div ng-repeat="value in inputsCtrl.values"><div class=row--flex><div class=col-sm-10><div ng-class="{ \'input-group\': ! disabled() }"><mediatheque-dossier-field-input mediatheque-name="{{ name + \'[\' + $index + \']\' }}" mediatheque-id="{{ id + \'-\' + $index }}" ng-attr-mediatheque-form="{{ form }}" mediatheque-placeholder="{{ inputPlaceholder }}" mediatheque-disabled=disabled() mediatheque-initial-value=value mediatheque-apply-focus="$last && inputsCtrl.newlyAdded"></mediatheque-dossier-field-input><span class=input-group-btn ng-if="! disabled()"><button type=button class="btn btn-addon" ng-click=inputsCtrl.removeValue(value) title="Supprimer un dossier à rapprocher"><span class="fa fa-times-circle"></span></button></span></div></div><div ng-if="! disabled() && $last"><button type=button class="btn btn-transparent btn-input-actions" ng-click=inputsCtrl.addValue() title="Ajouter un dossier à rapprocher"><span class="fa fa-plus"></span></button></div></div></div></div>');
}]);
})();
