angular.module('app')
  .factory('RefDocService', RefDocService);

/*@ngInject*/
function RefDocService($rootScope, $http, XWwwFormUrlEncoded, RefDocApiConfiguration, initData) {
  var refDoc = validate(initData.initialReferenceDocumentaire);

  return { load, lockAndGet, create, update, cancel, remove, get };

  function load(refDocId, illustrationId) {
    return $http({
      method: 'GET',
      cache: false,
      url: RefDocApiConfiguration.loadUri,
      params: {
        'refDocId': refDocId,
        'illustrationId': illustrationId
      }
    }).then((response) => {
      refDoc = validate(response.data);
      $rootScope.$broadcast('illustration.refDoc.changed');
    });
  }

  function lockAndGet(refDocId, illustrationId) {
    return $http({
      method: 'POST',
      cache: false,
      url: RefDocApiConfiguration.lockAndGetUri,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      transformRequest: XWwwFormUrlEncoded.transform,
      data: {
        'refDocId': refDocId,
        'illustrationId': illustrationId
      }
    }).then((response) => {
      refDoc = validate(response.data);
      $rootScope.$broadcast('illustration.refDoc.changed');
    }, (error) => {
      throw error.data.model;
    });
  }

  function create(newRefDoc, illustrationId) {
    var lieuConservationLibelle = newRefDoc.lieuConservation;
    if (_.isObject(lieuConservationLibelle)) {
      lieuConservationLibelle = lieuConservationLibelle.libelle;
    }
    return $http({
      method: 'POST',
      cache: false,
      url: RefDocApiConfiguration.saveUri,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      transformRequest: XWwwFormUrlEncoded.transform,
      data: _.assign({
        'illustrationId': illustrationId,
        'titre': newRefDoc.titre,
        'type': newRefDoc.type,
        'cote': newRefDoc.cote,
        'commentaire': newRefDoc.commentaire,
        'richTextCommentaire': newRefDoc.richTextCommentaire,
        'lieuConservation.thesaurusId': newRefDoc.lieuConservation && newRefDoc.lieuConservation.thesaurusId,
        'lieuConservation.libelle': lieuConservationLibelle,
      }, generateContributionsData(newRefDoc))
    }).then((response) => {
      refDoc = validate(response.data);
      $rootScope.$broadcast('illustration.refDoc.changed');
    }, (error) => {
      throw error.data.model;
    });
  }

  function update(updatedRefDoc, illustrationId) {
    var lieuConservationLibelle = updatedRefDoc.lieuConservation;
    if (_.isObject(lieuConservationLibelle)) {
      lieuConservationLibelle = lieuConservationLibelle.libelle;
    }
    return $http({
      method: 'POST',
      cache: false,
      url: RefDocApiConfiguration.saveUri,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      transformRequest: XWwwFormUrlEncoded.transform,
      data: _.assign({
        'refDocId': updatedRefDoc.id,
        'illustrationId': illustrationId,
        'version': updatedRefDoc.version,
        'titre': updatedRefDoc.titre,
        'type': updatedRefDoc.type,
        'cote': updatedRefDoc.cote,
        'commentaire': updatedRefDoc.commentaire,
        'richTextCommentaire': updatedRefDoc.richTextCommentaire,
        'lieuConservation.thesaurusId': updatedRefDoc.lieuConservation && updatedRefDoc.lieuConservation.thesaurusId,
        'lieuConservation.libelle': lieuConservationLibelle
      }, generateContributionsData(updatedRefDoc))
    }).then((response) => {
      refDoc = validate(response.data);
      $rootScope.$broadcast('illustration.refDoc.changed');
    }, (error) => {
      throw error.data.model;
    });
  }

  function cancel(refDocId, illustrationId) {
    return $http({
      method: 'POST',
      cache: false,
      url: RefDocApiConfiguration.cancelUri,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      transformRequest: XWwwFormUrlEncoded.transform,
      data: {
        'refDocId': refDocId,
        'illustrationId': illustrationId
      }
    }).then(null, (error) => {
      throw error.data.model;
    });
  }

  function remove() {
    refDoc = null;
    $rootScope.$broadcast('illustration.refDoc.changed', {});
  }

  function get() {
    if (_.isNull(refDoc) || _.isUndefined(refDoc) || refDoc === false) {
      return null;
    }
    return _.cloneDeep(refDoc);
  }

  function generateContributionsData(refDoc) {
    if (!refDoc.contributions) {
      return;
    }

    var contributionsData = {};
    for (var i = 0; i < refDoc.contributions.length; i++) {
      contributionsData[`contribution[${i}].personneId`] = refDoc.contributions[i].personne && refDoc.contributions[i].personne.id;
      contributionsData[`contribution[${i}].role`] = refDoc.contributions[i].role;
      contributionsData[`contribution[${i}].dateIntervention`] = _.map(refDoc.contributions[i].datesIntervention, 'libelle');
      contributionsData[`contribution[${i}].originalIndex`] = refDoc.contributions[i].originalIndex;
    }
    return contributionsData;
  }

  function validate(refDoc) {
    if (!refDoc) {
      return refDoc;
    }

    refDoc.contributions = _.map(refDoc.contributions, (contribution) => {
      var datesIntervention = [{ 'libelle': '' }];
      if (contribution.datesIntervention && contribution.datesIntervention.length > 0) {
        datesIntervention = _.map(contribution.datesIntervention, (date) => {
          return { 'libelle': date };
        });
      }
      return {
        datesIntervention: datesIntervention,
        personne: contribution.auteur,
        role: contribution.role,
        originalIndex: contribution.originalIndex
      };
    });
    return refDoc;
  }
}
