angular.module('app')
  .factory('NoticeNavigationService', NoticeNavigationService);

/*@ngInject*/
function NoticeNavigationService($rootScope, $window) {

  const MEDIATHEQUE_NAVIGATION = 'MEDIATHEQUE_NAVIGATION';

  return { setContext, clearContext, getNotices };

  function setContext(context) {
    if (!$window.localStorage) {
      return;
    }
    $window.localStorage.setItem(MEDIATHEQUE_NAVIGATION, JSON.stringify(context));
  }

  function clearContext() {
    if (!$window.localStorage) {
      return;
    }
    $window.localStorage.removeItem(MEDIATHEQUE_NAVIGATION);
  }

  function getNotices(entityId) {
    if (!$window.localStorage || !$window.localStorage.getItem(MEDIATHEQUE_NAVIGATION) || !entityId) {
      return null;
    }
    let entities = JSON.parse($window.localStorage.getItem(MEDIATHEQUE_NAVIGATION));
    let currentNoticeIndex = _.findIndex(entities, e => e.id === entityId);
    if (currentNoticeIndex === -1) {
      return null
    }
    let notices = {};
    if (currentNoticeIndex > 0) {
      notices.previous = entities[currentNoticeIndex - 1];
    }
    if (currentNoticeIndex + 1 < _.size(entities)) {
      notices.next = entities[currentNoticeIndex  + 1];
    }
    return notices;
  }
}
