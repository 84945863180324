(function(module) {
try {
  module = angular.module('app');
} catch (e) {
  module = angular.module('app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('common/lock-status.html',
    '<span class="fa fa-fw lock-status" ng-class="{ \'fa-lock\': status == \'LOCKED\' || status == \'WORKING_COPY\', \'text-danger\': status == \'LOCKED\', \'text-success\': status == \'WORKING_COPY\' }" title="{{ status, select, FREE { {{ \'lockStatus.free\' | messageFormat }} } LOCKED { {{ \'lockStatus.locked\' | messageFormat }} } WORKING_COPY { {{ \'lockStatus.workingCopy\' | messageFormat }} } other { } }}"></span>');
}]);
})();
