angular.module('app')
  .directive('mediathequeRechercheExperteInput', RechercheExperteInputDirective);

function RechercheExperteInputDirective() {
  return {
    restrict: 'E',
    scope: {
      name: '@mediathequeName',
      id: '@mediathequeId',
      form: '@mediathequeForm',
      placeholder: '@mediathequePlaceholder',
      disabled: '&mediathequeDisabled',
      onChange: '&mediathequeOnChange',
      model: '=ngModel',
      typeRecherche: '@mediathequeTypeRecherche',
      initialValue: '&mediathequeInitialValue',
      allowCreation: '&mediathequeAllowCreation',
      applyFocus: '&mediathequeApplyFocus'
    },
    replace: true,
    controller: RechercheExperteInputController,
    controllerAs: 'inputCtrl',
    templateUrl: 'common/recherche-experte-input.html'
  };
}

/*@ngInject*/
function RechercheExperteInputController($scope, RechercheExperteSuggest) {
  var inputCtrl = this;

  inputCtrl.getSuggestions = getSuggestions;
  inputCtrl.hasValidValue = hasValidValue;
  inputCtrl.onChange = $scope.onChange;

  init();

  // TODO: Ajouter un watcher sur $scope.model (couplé à un $timeout pour s'assurer que le scope est bien à jour)
  // si le model initial est modifié par le composant parent

  function init() {
    if ($scope.model) {
      return;
    }
    $scope.model = $scope.initialValue() || { libelle: "" };
  }

  function hasValidValue() {
    if ($scope.model === '') {
      return true;
    }
    if (!$scope.model) {
      $scope.model = {libelle: ""}
    }
    if ($scope.model.id) {
      return true;
    }
    if (!$scope.model.id && $scope.model.libelle === '') {
      // valeur vide par défaut, on considère que c'est bon
      return true;
    }
    if ($scope.allowCreation()) {
      return _.isString($scope.model) || !!$scope.model.libelle;
    }
    return false;
  }

  inputCtrl.loading = false;
  function getSuggestions(currentValue) {
    if ($scope.disabled()) {
      return;
    }
    inputCtrl.loading = true;
    return RechercheExperteSuggest.search($scope.typeRecherche, currentValue)
      .then((result) => {
        inputCtrl.loading = false;
        if ($scope.allowCreation()) {
          $scope.model = { libelle: currentValue };
          $scope.onChange();
        }
        return result.data;
      });
  }
}
