(function(module) {
try {
  module = angular.module('app');
} catch (e) {
  module = angular.module('app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('common/text-input.html',
    '<div class=has-feedback><input type=text name="{{ name }}" id="{{ id }}" class="form-control {{ inputClass }}" autocomplete=off ng-readonly=disabled() ng-attr-form="{{ form }}" placeholder="{{ placeholder }}" data-mediatheque-default-value="{{ ::model }}" ng-model=model ng-change=inputCtrl.onChange() typeahead="suggestion as suggestion for suggestion in inputCtrl.getSuggestions($viewValue)" typeahead-on-select=inputCtrl.onChange() typeahead-wait-ms=300 mediatheque-autofocus=applyFocus()> <span ng-if=inputCtrl.withSuggestion() class="fa suggest-feedback" ng-class="{\'fa-caret-down\': !inputCtrl.loading, \'fa-gear\': inputCtrl.loading}"></span></div>');
}]);
})();
