(function(module) {
try {
  module = angular.module('app');
} catch (e) {
  module = angular.module('app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('common/lieudit-input.html',
    '<div class=row><div class=col-sm-8><mediatheque-thesaurus-input mediatheque-name="{{ name + \'.nom\' }}" mediatheque-id="{{ id + \'-nom\' }}" mediatheque-disabled=disabled() mediatheque-placeholder=nom ng-attr-mediatheque-form="{{ form }}" ng-model=inputCtrl.lieudit.nom mediatheque-on-change=inputCtrl.onChange() mediatheque-disable-suggest=true mediatheque-types-thesaurus="[\'LieuDitNom\']" mediatheque-apply-focus=applyFocus()></mediatheque-thesaurus-input></div><div class=col-sm-4><mediatheque-thesaurus-input mediatheque-name="{{ name + \'.precision\' }}" mediatheque-id="{{ id + \'-precision\' }}" mediatheque-disabled=disabled() mediatheque-placeholder=precision ng-attr-mediatheque-form="{{ form }}" ng-model=inputCtrl.lieudit.precision mediatheque-on-change=inputCtrl.onChange() mediatheque-disable-suggest=true mediatheque-types-thesaurus="[\'LieuDitPrecision\']"></mediatheque-thesaurus-input></div></div>');
}]);
})();
