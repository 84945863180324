angular.module('app')
  .directive('mediathequeMultivaluedSelectInput', MultivaluedSelectInputDirective);

function MultivaluedSelectInputDirective() {
  return {
    restrict: 'E',
    scope: {
      cssClasses: '@mediathequeClass',
      placeholder: '@mediathequePlaceholder',
      addElementTitle: '@mediathequeAddElementTitle',
      removeElementTitle: '@mediathequeRemoveElementTitle',
      inputClass: '@mediathequeInputClass',
      suggestions: '&mediathequeSuggestions',
      model: '=ngModel',
      onChange: '&mediathequeOnChange'
    },
    replace: true,
    controller: MultivaluedSelectInputController,
    controllerAs: 'inputsCtrl',
    templateUrl: 'common/multivalued-select-input.html'
  };
}

/*@ngInject*/
function MultivaluedSelectInputController($scope, $timeout) {
  var inputsCtrl = this;

  inputsCtrl.addValue = addValue;
  inputsCtrl.removeValue = removeValue;

  init();

  // Watcher sur $scope.model (couplé à un $timeout pour s'assurer que le scope est bien à jour)
  // si le model initial est modifié par le composant parent

  $scope.$watch('model', () => {
    $timeout(() => {
      if ($scope.model && $scope.model.length === 0) {
        $scope.model = [{ libelle:"" }];
        $scope.onChange && $scope.onChange();

        inputsCtrl.newlyAdded = false;
        inputsCtrl.dirty = true;
      }
    });
  });

  function addValue() {
    $scope.model.push({ libelle: '' });
    inputsCtrl.dirty = true;
    inputsCtrl.newlyAdded = true;
  }

  function removeValue(valueToRemove) {
    $scope.model = _.without($scope.model, valueToRemove);

    // Il faut au moins un champs affiché
    if ($scope.model.length === 0) {
      $scope.model = [{ libelle:"" }];
    }
    inputsCtrl.dirty = true;
    inputsCtrl.newlyAdded = false;

    $scope.onChange && $scope.onChange();
  }

  function init() {
    inputsCtrl.newlyAdded = false;
    inputsCtrl.dirty = false;

    if ($scope.model) {
      if ($scope.model.length === 0) {
        $scope.model = [{ libelle:"" }];
      }
      return;
    }
  }
}
