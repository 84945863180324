(function(module) {
try {
  module = angular.module('app');
} catch (e) {
  module = angular.module('app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('common/thesaurus-input.html',
    '<div class=has-feedback><input type=hidden name="{{ name }}.thesaurusId" value="{{ inputCtrl.model.thesaurusId }}" data-mediatheque-default-value="{{ ::inputCtrl.model.thesaurusId }}" ng-required=idRequired() ng-attr-form="{{ form }}"> <input type=text name="{{ name }}.libelle" id="{{ id }}" class=form-control ng-if=::(!disableSuggest()) autocomplete=off ng-readonly=disabled() ng-attr-form="{{ form }}" placeholder="{{ placeholder }}" data-mediatheque-default-value="{{ ::inputCtrl.model.libelle }}" ng-model=inputCtrl.model ng-change=inputCtrl.onChange() typeahead="suggestion as suggestion.libelle for suggestion in inputCtrl.getSuggestions($viewValue)" typeahead-on-select=inputCtrl.onChange() typeahead-template-url=common/thesaurus-suggest.html typeahead-wait-ms=300 typeahead-loading=inputCtrl.loading mediatheque-autofocus=applyFocus()> <input type=text name="{{ name }}.libelle" id="{{ id }}" class=form-control ng-if=::disableSuggest() ng-readonly=disabled() ng-attr-form="{{ form }}" placeholder="{{ placeholder }}" data-mediatheque-default-value="{{ ::inputCtrl.model.libelle }}" ng-model=inputCtrl.model.libelle ng-change=inputCtrl.onChange() mediatheque-autofocus=applyFocus()> <span class="fa suggest-feedback" ng-class="{\'fa-caret-down\': !inputCtrl.loading, \'fa-gear\': inputCtrl.loading, \'text-danger\': !inputCtrl.hasValidValue() }" ng-if=::(!disableSuggest())></span></div>');
}]);
})();
