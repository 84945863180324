(function(module) {
try {
  module = angular.module('app');
} catch (e) {
  module = angular.module('app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('common/multivalued-editeurs-input.html',
    '<div class=multivalued-input><input type=hidden data-mediatheque-default-value=false value="{{ inputsCtrl.dirty }}" ng-attr-form="{{ form }}"><div ng-repeat="editeur in inputsCtrl.editeurs"><div class="row--flex row--flex--wrap"><input type=hidden name="{{ name + \'.originalIndex\' }}" value="{{ editeur.originalIndex }}" ng-attr-form="{{ form }}"><div class="col-sm-6 col-lg-6"><mediatheque-text-input mediatheque-name="{{ name + \'[\' + $index + \'].\' + \'nom\' }}" mediatheque-id="{{ id + \'-\' + $index + \'-nom\'}}" mediatheque-disabled=disabled() mediatheque-placeholder=nom mediatheque-field-path=biblio.editeurs.nom mediatheque-entity-type=Biblio ng-attr-mediatheque-form="{{ form }}" mediatheque-value="{{ editeur.nom }}"></mediatheque-text-input></div><div class="col-sm-5 col-lg-5"><mediatheque-text-input mediatheque-name="{{ name + \'[\' + $index + \'].\' + \'lieu\' }}" mediatheque-id="{{ id + \'-\' + $index + \'-lieu\'}}" mediatheque-disabled=disabled() mediatheque-placeholder=lieu mediatheque-field-path=biblio.editeurs.lieu mediatheque-entity-type=Biblio ng-attr-mediatheque-form="{{ form }}" mediatheque-value="{{ editeur.lieu }}"></mediatheque-text-input></div><div ng-if="! disabled()"><button type=button class="btn btn-transparent btn-input-actions" ng-click=inputsCtrl.removeEditeur(editeur) title="Supprimer un editeur"><span class="fa fa-times-circle"></span></button></div><div ng-if="! disabled() && $last"><button type=button class="btn btn-transparent btn-input-actions" ng-click=inputsCtrl.addEditeur() title="Ajouter un editeur"><span class="fa fa-plus"></span></button></div></div></div></div>');
}]);
})();
