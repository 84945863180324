angular.module('app')
  .factory('ImageIllustrationGet', ImageIllustrationGet);

/*@ngInject*/
function ImageIllustrationGet($rootScope) {

  var images = {};

  return { put, get };

  function getCacheKey(illustrationId, format) {
    return illustrationId+"##"+format;
  }

  function get(illustrationId, format) {
    return _.get(images, getCacheKey(illustrationId, format));
  }

  function put(illustrationId, format, id, uri) {
    _.set(images, getCacheKey(illustrationId, format), {id, uri});
    $rootScope.$broadcast('illustration.image.changed', illustrationId, format);
  }

}
