angular.module('app')
  .factory('LocationService', LocationService);

/*@ngInject*/
function LocationService($rootScope, $window) {
  var oldHash = getHash();

  $rootScope.$on('location-hash-set', handleSetHash);

  window.onhashchange = handleHashChanged;
  // Force Firefox to not cache the page when leaving a page
  // and to reload entirely the page when going back to this page
  window.onunload = () => {};

  return { getHash, getSearch };

  function getSearch(param) {
    return parseQueryString($window.location.search)[param];
  }

  function parseQueryString(queryString) {
    var params = {};
    // Split into key/value pairs
    var queries = (queryString.startsWith('?') ? queryString.substring(1) : queryString).split("&");
    var l = queries.length;
    // Convert the array of strings into an object
    for (var i = 0; i < l; i++) {
      var temp = queries[i].split('=');
      params[temp[0]] = temp[1];
    }
    return params;
  }

  function handleSetHash(event, newHash) {
    if (newHash === oldHash) {
      return;
    }

    $window.location.hash = '#/' + newHash;
    oldHash = newHash;
    $rootScope.$broadcast('location-hash-changed');
  }

  function getHash() {
    return $window.location.hash.substring(2);
  }

  function handleHashChanged() {
    var newHash = getHash();
    if (newHash === oldHash) {
      return;
    }

    oldHash = newHash;

    $rootScope.$apply(() => {
      $rootScope.$broadcast('location-hash-changed');
    });
  }
}
