angular.module('app')
  .directive('mediathequeRefDocForm', RefDocFormDirective);

function RefDocFormDirective() {
  return {
    restrict: 'E',
    replace: true,
    controller: RefDocFormController,
    controllerAs: 'refDocFormCtrl',
    scope: {
      id: '@mediathequeId',
      disabled: '&mediathequeDisabled',
      refDoc: '=ngModel',
      formHorizontal: '&mediathequeFormHorizontal',
      applyFocus: '&mediathequeApplyFocus'
    },
    templateUrl: 'illustration/ref-doc-form.html'
  };
}

/*@ngInject*/
function RefDocFormController($scope, $rootScope) {
  var refDocFormCtrl = this;

  refDocFormCtrl.isDisabled = $scope.disabled;
  refDocFormCtrl.formHorizontal = $scope.formHorizontal();

  init();

  function init() {
    refDocFormCtrl.refDoc = $scope.refDoc;
  }
}
