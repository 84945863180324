(function(module) {
try {
  module = angular.module('app');
} catch (e) {
  module = angular.module('app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('common/modification-en-masse.html',
    '<div><div class=form-group><button type=button class="btn btn-default btn-block" ng-click=modificationEnMasseCtrl.displayRechercherRemplacerForm()>Rechercher / remplacer</button></div><div class=form-group><button type=button class="btn btn-default btn-block" ng-click=modificationEnMasseCtrl.displayValoriserForm()>Définir une valeur</button></div></div>');
}]);
})();
