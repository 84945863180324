(function(module) {
try {
  module = angular.module('app');
} catch (e) {
  module = angular.module('app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('common/app-notification-success.html',
    '<strong><span class=icon-ok></span></strong> Opération effectuée avec succès.');
}]);
})();
