angular.module('app')
  .directive('mediathequeIllustrationWithErrorTabBar', IllustrationWithErrorTabBarDirective);

function IllustrationWithErrorTabBarDirective() {
  return {
    restrict: 'E',
    replace: true,
    controller: IllustrationWithErrorTabBarController,
    controllerAs: 'illustrationWithErrorTabBarCtrl',
    templateUrl: 'illustration/illustration-with-error-tab-bar.html'
  };
}

/*@ngInject*/
function IllustrationWithErrorTabBarController($scope, IllustrationWithErrorService) {
  var illustrationWithErrorTabBarCtrl = this;

  $scope.$on('illustration-with-error-changed', handleResultsChanged);

  init();

  function init() {
    handleResultsChanged();
  }

  function handleResultsChanged() {
    illustrationWithErrorTabBarCtrl.atLeastOneSelected = IllustrationWithErrorService.isAtLeastOneSelected();
  }

}
