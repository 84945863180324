(function(module) {
try {
  module = angular.module('app');
} catch (e) {
  module = angular.module('app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('common/multivalued-collection-biblio-input.html',
    '<div class=multivalued-input><input type=hidden data-mediatheque-default-value=false value="{{ inputsCtrl.dirty }}" ng-attr-form="{{ form }}"><div ng-repeat="collectionBiblio in inputsCtrl.collectionsBiblio"><div class="row--flex row--flex--wrap"><input type=hidden name="{{ name + \'.originalIndex\' }}" value="{{ collectionBiblio.originalIndex }}" ng-attr-form="{{ form }}"><div class="col-sm-6 col-lg-6"><mediatheque-text-input mediatheque-name="{{ name + \'[\' + $index + \'].\' + \'nom\' }}" mediatheque-id="{{ id + \'-\' + $index + \'-nom\'}}" mediatheque-disabled=disabled() mediatheque-placeholder=nom mediatheque-field-path=collectionsBiblio.nom ng-attr-mediatheque-form="{{ form }}" mediatheque-value="{{ collectionBiblio.nom }}"></mediatheque-text-input></div><div class="col-sm-5 col-lg-5"><mediatheque-text-input mediatheque-name="{{ name + \'[\' + $index + \'].\' + \'numero\' }}" mediatheque-id="{{ id + \'-\' + $index + \'-numero\'}}" mediatheque-disabled=disabled() mediatheque-placeholder=numéro mediatheque-field-path=collectionsBiblio.numero ng-attr-mediatheque-form="{{ form }}" mediatheque-value="{{ collectionBiblio.numero }}"></mediatheque-text-input></div><div ng-if="! disabled()"><button type=button class="btn btn-transparent btn-input-actions" ng-click=inputsCtrl.removeCollectionBiblio(collectionBiblio) title="Supprimer une collection"><span class="fa fa-times-circle"></span></button></div><div ng-if="! disabled() && $last"><button type=button class="btn btn-transparent btn-input-actions" ng-click=inputsCtrl.addCollectionBiblio() title="Ajouter une collection"><span class="fa fa-plus"></span></button></div></div></div></div>');
}]);
})();
