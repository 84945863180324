angular.module('app')
  .factory('AuteurbiblioService', AuteurbiblioService);

/*@ngInject*/
function AuteurbiblioService($rootScope, $http, XWwwFormUrlEncoded, AuteurBiblioApiConfiguration, initData) {
  var auteurbiblios = {};

  return { getByTemporaryId, load, save };

  function getByTemporaryId(id) {
    return _.cloneDeep(_.get(auteurbiblios, id));
  }

  function load(auteurId) {
    return $http({
      method: 'GET',
      cache: false,
      url: AuteurBiblioApiConfiguration.loadUri,
      params: { id: auteurId}
    }).then((response) => {
      return response.data;
    }, (error) => {
      throw error.data.model;
    });
  }

  function save(auteurbiblio, illustrationId) {
    return $http({
      method: 'POST',
      cache: false,
      url: AuteurBiblioApiConfiguration.saveUri,
      params: {
        id: auteurbiblio.id
      },
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      transformRequest: XWwwFormUrlEncoded.transform,
      data: {
        nom: auteurbiblio.nom,
        prenom: auteurbiblio.prenom,
        commentaire: auteurbiblio.commentaire,
        version: auteurbiblio.version
      }
    }).then((response) => {
      _.set(auteurbiblios, response.data.id, response.data);
      return response.data;
    }, (error) => {
      throw error.data.model;
    });
  }
}
