(function(module) {
try {
  module = angular.module('app');
} catch (e) {
  module = angular.module('app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('common/thesaurus-suggest.html',
    '<a href=# ng-click=$event.preventDefault() tabindex=-1><ol class="breadcrumb breadcrumb-thesaurus" ng-if=match.model.ancetreLibelles><li ng-repeat="ancetreLibelle in match.model.ancetreLibelles" ng-class="{ active: $last }">{{ ancetreLibelle }}</li></ol><span ng-class="{\'interdit\': match.model.interdit }" bind-html-unsafe="match.label | typeaheadHighlight:query"></span></a>');
}]);
})();
