angular.module('app')
  .factory('appHttpInterceptor', AppHttpInterceptor);

/*@ngInject*/
function AppHttpInterceptor($q, NotificationService) {
  var ID = 'httpState';

  return {
    'request': function(config) {
      if (config.method.toUpperCase() === 'POST' || config.method.toUpperCase() === 'PUT' || config.method.toUpperCase() === 'DELETE' ) {
        NotificationService.notify({
          'id': ID,
          'type': 'info',
          'fadeOn': {
            'enter': true,
            'leave': false
          },
          'delay': 100,
          'contentTemplate': 'common/app-notification-loading.html',
          'replace': ID
        });
      }

      return config || $q.when(config);
    },
   'requestError': function(rejection) {
      if (rejection.config.method.toUpperCase() === 'POST' || rejection.config.method.toUpperCase() === 'PUT' || rejection.config.method.toUpperCase() === 'DELETE' ) {
        NotificationService.notify({
          'id': ID,
          'type': 'danger',
          'fadeOn': {
            'enter': false,
            'leave': true
          },
          'contentTemplate': 'common/app-notification-error.html',
          'replace': ID,
          'scope': rejection
        });
      }

      return $q.reject(rejection);
    },
    'response': function(response) {
      if (response.config.method.toUpperCase() === 'POST' || response.config.method.toUpperCase() === 'PUT' || response.config.method.toUpperCase() === 'DELETE' ) {
        NotificationService.notify({
          'id': ID,
          'type': 'success',
          'fadeOn': {
            'enter': false,
            'leave': true
          },
          'contentTemplate': 'common/app-notification-success.html',
          'replace': ID
        });
      }

      return response || $q.when(response);
    },
   'responseError': function(rejection) {
      if (rejection.config.method.toUpperCase() === 'POST' || rejection.config.method.toUpperCase() === 'PUT' || rejection.config.method.toUpperCase() === 'DELETE' ) {
        NotificationService.notify({
          'id': ID,
          'type': 'danger',
          'fadeOn': {
            'enter': false,
            'leave': true
          },
          'contentTemplate': 'common/app-notification-error.html',
          'replace': ID,
          'scope': rejection
        });
      }

      return $q.reject(rejection);
    }
  };
}

angular.module('app')
  .config(/*@ngInject*/ function($httpProvider) {
    $httpProvider.interceptors.push('appHttpInterceptor');
  });
