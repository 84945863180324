angular.module('app')
  .directive('mediathequeFileInput', FileInputDirective);

function FileInputDirective() {
  return {
    restrict: 'E',
    scope: {
      name: '@mediathequeName',
      id: '@mediathequeId',
      form: '@mediathequeForm',
      btnSelectClass: '@mediathequeBtnSelectClass',
      btnRemoveClass: '@mediathequeBtnRemoveClass',
      btnAbortClass: '@mediathequeBtnAbortClass',
      illustrationId: '@mediathequeIllustrationId',
      format: '@mediathequeFormat',
      generateFormats: '&mediathequeGenerateFormats',
      generateTooltip: '@mediathequeGenerateTooltip',
      initialValue: '@mediathequeInitialValue',
      initialValueUri: '@mediathequeInitialValueUri'
    },
    replace: true,
    controller: FileInputController,
    controllerAs: 'inputCtrl',
    templateUrl: 'common/file-input.html'
  };
}

/*@ngInject*/
function FileInputController($scope, $timeout, $window, FichierPost, ImageIllustrationGet, GenerateMiniature) {
  $scope.$on('illustration.image.changed', updateResource);

  var inputCtrl = this;

  inputCtrl.upload = upload;
  inputCtrl.remove = remove;
  inputCtrl.abort = abort;
  inputCtrl.generate = generate;
  inputCtrl.hasNewImage = hasNewImage;
  inputCtrl.hasNoImage = hasNoImage;
  inputCtrl.hasImage = hasImage;
  inputCtrl.canGenerateFormats = canGenerateFormats;

  init();

  function init() {
    ImageIllustrationGet.put($scope.illustrationId, $scope.format, $scope.initialValue, $scope.initialValueUri);
    inputCtrl.loading = false;
    inputCtrl.progressPercentage = 0;
    $scope.fileObject = null;
  }

  function generate() {
    inputCtrl.loading = true;
    inputCtrl.progressType = "default";
    inputCtrl.progressPercentage = 100;
    inputCtrl.progressActive = true;

    GenerateMiniature.generate(
      $scope.illustrationId,
      inputCtrl.resourceId,
      $scope.format,
      $scope.generateFormats()
    ).then(function (result) {
      $timeout(function() {
          inputCtrl.progressActive = false;
          inputCtrl.loading = false;
          inputCtrl.progressType = "success";
      });
      resetProgressTimeout();
    }, function (reason) {
      $timeout(function() {
          inputCtrl.progressActive = false;
          inputCtrl.loading = false;
          inputCtrl.progressType = "danger";
      });
      resetProgressTimeout();
    });
  }

  function updateResource(event, illustrationId, format) {
    if (illustrationId !== $scope.illustrationId || format !== $scope.format) {
      return;
    }
    var cache = ImageIllustrationGet.get($scope.illustrationId, $scope.format);
    inputCtrl.resourceId = cache.id;
    inputCtrl.resourceUri = cache.uri;
  }

  function setLoading(loading) {
    inputCtrl.loading = loading;
    if (! loading) {
      inputCtrl.uploadObject = null;
    }
  }

  function canGenerateFormats() {
    return hasImage() && ! _.isEmpty($scope.generateFormats());
  }

  function hasImage() {
    return inputCtrl.resourceId != null && ! inputCtrl.loading;
  }

  function hasNewImage() {
    return $scope.fileObject !== null;
  }

  function hasNoImage() {
    return $scope.fileObject === null && inputCtrl.resourceId == null;
  }

  function remove() {
    if (! $window.confirm("Voulez-vous vraiment effectuer cette opération ?")) {
      return;
    }
    inputCtrl.resourceId= null;
    $scope.fileObject = null;
    ImageIllustrationGet.put($scope.illustrationId, $scope.format, null, null)
  }

  function abort() {
    if (! inputCtrl.loading) {
      return;
    }
    inputCtrl.uploadObject.abort();
    inputCtrl.progressType = "warning";
    resetProgressTimeout();
  }

  function resetProgress() {
    inputCtrl.progressPercentage = 0;
    inputCtrl.progressType = "default";
  }

  function resetProgressTimeout() {
    $timeout(resetProgress, 2000)
  }

  function upload(files, file, event, rejectedFiles) {
    if (file === null) {
      return;
    }
    $scope.fileObject = file;
    resetProgress();
    setLoading(true);
    inputCtrl.uploadObject = FichierPost.postImage(file, $scope.format)
    .progress(function (evt) {
        inputCtrl.progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
    }).success(function (data, status, headers, config) {
        $timeout(function() {
            ImageIllustrationGet.put($scope.illustrationId, $scope.format, data.id, data.uri);
            $scope.fileObject = null;
            setLoading(false);
            inputCtrl.progressType = "success";
        });
        resetProgressTimeout();
    }).error(function (data, status, headers, config) {
        $timeout(function() {
            $scope.fileObject = null;
            setLoading(false);
            inputCtrl.progressType = "danger";
        });
        resetProgressTimeout();
    });
  }
}
