(function(module) {
try {
  module = angular.module('app');
} catch (e) {
  module = angular.module('app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('common/multivalued-personne-input.html',
    '<div class="multivalued-input row--flex row--flex--wrap"><input type=hidden data-mediatheque-default-value=false value="{{ inputsCtrl.dirty }}" ng-attr-form="{{ form }}"><div ng-repeat="auteur in inputsCtrl.auteurs" class=col-sm-7><div class="row--flex row--flex--wrap"><div class=col-sm-8><mediatheque-personne-input mediatheque-name="{{ name + \'[\' + $index + \'].personne\' }}" mediatheque-id="{{ id + \'-\' + $index }}" ng-attr-mediatheque-form="{{ form }}" mediatheque-disabled=disabled() mediatheque-initial-value=auteur mediatheque-apply-focus="$last && inputsCtrl.newlyAdded"></mediatheque-personne-input></div><div class="col-sm-1 fix-croix"><span class=input-group-btn ng-if="! disabled()"><button type=button class="btn btn-addon" ng-click=inputsCtrl.removeAuteur(auteur) title="{{ removeElementTitle }}"><span class="fa fa-times-circle"></span></button></span></div><div class="col-sm-2 checkbox-block"><input type=checkbox name="{{ name + \'[\' + $index + \']\' }}.reproduction" ng-model=auteur.reproduction value=true> <label class=checkbox-block-label>Reproduction</label></div></div></div><button type=button class="btn btn-transparent btn-input-actions" ng-click=inputsCtrl.addAuteur() ng-if="! disabled()" title="{{ addElementTitle }}"><span class="fa fa-plus"></span></button></div>');
}]);
})();
