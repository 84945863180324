angular.module('app')
  .directive('mediathequeLotPictureSliderWrapper', LotPictureSliderWrapperDirective);

function LotPictureSliderWrapperDirective() {
  return {
    restrict: 'E',
    replace: true,
    transclude: true,
    scope: {
      illustrationId: '@mediathequeIllustrationId'
    },
    controller: LotPictureSliderWrapperController,
    controllerAs: 'pictureSliderWrapperCtrl',
    templateUrl: 'illustration/picture-slider-wrapper.html'
  };
}

/*@ngInject*/
function LotPictureSliderWrapperController($scope, PictureSliderModalService, LotPictureSliderSupplier) {
  var pictureSliderWrapperCtrl = this;

  pictureSliderWrapperCtrl.displaySlider = displaySlider;

  function displaySlider() {
    PictureSliderModalService.displaySlider($scope.illustrationId, LotPictureSliderSupplier);
  }
}
