angular.module('app')
  .factory('IllustrationWithErrorService', IllustrationWithErrorService);

/*@ngInject*/
function IllustrationWithErrorService($rootScope, $http, initData) {
  var results = angular.copy(initData.results);

  $rootScope.$on('illustration-with-error-select', handleSelectedResult);
  $rootScope.$on('illustration-with-error-unselect', handleUnselectedResult);

  return { getResults, isAtLeastOneSelected };

  function handleSelectedResult(event, resultId) {
    var result = _.find(results, function(r) {
      return r.illustration.id === resultId;
    });
    result.selected = true;

    $rootScope.$broadcast('illustration-with-error-changed');
  }

  function handleUnselectedResult(event, resultId) {
    var result = _.find(results, function(r) {
      return r.illustration.id === resultId;
    });
    result.selected = false;

    $rootScope.$broadcast('illustration-with-error-changed');
  }

  function getResults() {
    return _.cloneDeep(results);
  }

  function isAtLeastOneSelected() {
    return _.some(_.cloneDeep(results), 'selected');
  }
}
