angular.module('app')
  .directive('mediathequeContributionRefDocInput', ContributionRefDocInputDirective);

function ContributionRefDocInputDirective() {
  return {
    restrict: 'E',
    scope: {
      name: '@mediathequeName',
      id: '@mediathequeId',
      placeholder: '@mediathequePlaceholder',
      disabled: '&mediathequeDisabled',
      model: '=ngModel',
      applyFocus: '&mediathequeApplyFocus'
    },
    replace: true,
    templateUrl: 'common/contribution-ref-doc-input.html'
  };
}
