(function(module) {
try {
  module = angular.module('app');
} catch (e) {
  module = angular.module('app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('common/bookmark-input.html',
    '<button type=button class=btn-transparent ng-disabled=inputCtrl.processing ng-click=inputCtrl.toggleBookmark() title="{{inputCtrl.isBookmark ? \'Supprimer des favoris\' : \'Marquer comme favori\'}}"><span class="fa bookmark-icon" ng-class="{\'fa-heart\': inputCtrl.isBookmark, \'fa-heart-o\': !inputCtrl.isBookmark}"></span></button>');
}]);
})();
