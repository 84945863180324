angular.module('app')
  .directive('mediathequeRefDoc', RefDocDirective);

function RefDocDirective() {
  return {
    restrict: 'E',
    replace: true,
    controller: RefDocController,
    controllerAs: 'refDocCtrl',
    scope: {
      editable: '&mediathequeEditable',
      illustrationId: '@mediathequeIllustrationId'
    },
    templateUrl: 'illustration/ref-doc.html'
  };
}

/*@ngInject*/
function RefDocController($scope, $rootScope, $window, RefDocFormModal, RefDocService, EntitySuggest) {
  var refDocCtrl = this;

  refDocCtrl.isEmpty = isEmpty;
  refDocCtrl.isEditable = $scope.editable;
  refDocCtrl.createNewRefDoc = createNewRefDoc;
  refDocCtrl.updateRefDoc = updateRefDoc;
  refDocCtrl.removeRefDoc = removeRefDoc;
  refDocCtrl.getRefDocSuggestions = getRefDocSuggestions;
  refDocCtrl.rattacherRefDoc = rattacherRefDoc;

  init();

  $scope.$on('illustration.refDoc.changed', (event) => {
    var refDoc = RefDocService.get();
    if (refDoc === null) {
      refDocCtrl.empty = true;
      refDoc = {};
    } else {
      refDocCtrl.empty = false;
    }

    refDocCtrl.refDoc.id = refDoc.id;
    refDocCtrl.refDoc.version = refDoc.version;
    refDocCtrl.refDoc.titre = refDoc.titre;
    refDocCtrl.refDoc.type = refDoc.type;
    refDocCtrl.refDoc.lieuConservation = refDoc.lieuConservation || { libelle: '' };
    refDocCtrl.refDoc.cote = refDoc.cote;
    refDocCtrl.refDoc.commentaire = refDoc.commentaire;
    refDocCtrl.refDoc.richTextCommentaire = !!refDoc.richTextCommentaire;
    refDocCtrl.refDoc.contributions = refDoc.contributions;
  });

  function init() {
    refDocCtrl.refDoc = RefDocService.get();
    if (refDocCtrl.refDoc === null) {
      refDocCtrl.empty = true;
      refDocCtrl.refDoc = {};
    }
  }

  function createNewRefDoc() {
    RefDocFormModal.createNewRefDoc($scope.illustrationId);
  }

  function updateRefDoc() {
    RefDocFormModal.updateRefDoc($scope.illustrationId);
  }

  function removeRefDoc() {
    if ($window.confirm("Voulez-vous vraiment effectuer cette opération ?")) {
      RefDocService.remove();
    }
  }

  function getRefDocSuggestions(value) {
    refDocCtrl.suggestionLoading = true;
    return EntitySuggest.search('ReferenceDocumentaire', value)
        .then((result) => {
            refDocCtrl.suggestionLoading = false;
            return result.data;
        });
  }

  function rattacherRefDoc() {
    RefDocService.load(refDocCtrl.selectedRefDoc.id, $scope.illustrationId);
  }

  function isEmpty() {
    return refDocCtrl.empty;
  }
}
