angular.module('app')
  .directive('mediathequeMultivaluedContributionRefDocInput', MultivaluedContributionRefDocInputDirective);

function MultivaluedContributionRefDocInputDirective() {
  return {
    restrict: 'E',
    scope: {
      name: '@mediathequeName',
      id: '@mediathequeId',
      placeholder: '@mediathequePlaceholder',
      disabled: '&mediathequeDisabled',
      model: '=ngModel'
    },
    replace: true,
    controller: MultivaluedContributionRefDocInputController,
    controllerAs: 'inputsCtrl',
    templateUrl: 'common/multivalued-contribution-ref-doc-input.html'
  };
}

/*@ngInject*/
function MultivaluedContributionRefDocInputController($scope) {
  var inputsCtrl = this;

  inputsCtrl.addContribution = addContribution;
  inputsCtrl.removeContribution = removeContribution;

  init();

  // TODO: Ajouter un watcher sur $scope.model (couplé à un $timeout pour s'assurer que le scope est bien à jour)
  // si le model initial est modifié par le composant parent

  function addContribution() {
    $scope.model.push({});
    inputsCtrl.dirty = true;
    inputsCtrl.newlyAdded = true;
  }

  function removeContribution(contributionToRemove) {
    $scope.model = _.without($scope.model, contributionToRemove);

    // Il faut au moins un champs affiché
    if ($scope.model.length === 0) {
      $scope.model = [{}];
    }
    inputsCtrl.dirty = true;
    inputsCtrl.newlyAdded = false;
  }

  function init() {
    if (!$scope.model || $scope.model.length === 0) {
      $scope.model = [{}];
    }
    inputsCtrl.dirty = false;
    inputsCtrl.newlyAdded = false;
  }
}
