(function(module) {
try {
  module = angular.module('app');
} catch (e) {
  module = angular.module('app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('common/bookmark-status.html',
    '<span class="fa fa-fw bookmark-icon" ng-class="{ \'fa-heart\': bookmark()}"></span>');
}]);
})();
