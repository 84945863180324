angular.module('app')
  .directive('mediathequeMultivaluedEditeursInput', MultivaluedEditeursInputDirective);

function MultivaluedEditeursInputDirective() {
  return {
    restrict: 'E',
    scope: {
      name: '@mediathequeName',
      id: '@mediathequeId',
      form: '@mediathequeForm',
      disabled: '&mediathequeDisabled',
      initialValues: '&mediathequeInitialValues'
    },
    replace: true,
    controller: MultivaluedEditeursInputController,
    controllerAs: 'inputsCtrl',
    templateUrl: 'common/multivalued-editeurs-input.html'
  };
}

/*@ngInject*/
function MultivaluedEditeursInputController($scope) {
  var inputsCtrl = this;

  inputsCtrl.removeEditeur = removeEditeur;
  inputsCtrl.addEditeur = addEditeur;

  init();

  // TODO: Ajouter un watcher sur $scope.model (couplé à un $timeout pour s'assurer que le scope est bien à jour)
  // si le model initial est modifié par le composant parent

  function addEditeur() {
    inputsCtrl.editeurs.push({ nom: null, lieu: null });
    inputsCtrl.dirty = true;
    inputsCtrl.newlyAdded = true;
  }

  function removeEditeur(exemplaireToRemove) {
    inputsCtrl.editeurs = _.without(inputsCtrl.editeurs, exemplaireToRemove);

    // Il faut au moins un champs affiché
    if (inputsCtrl.editeurs.length === 0) {
      inputsCtrl.editeurs = [{ nom: null, lieu: null }];
    }
    inputsCtrl.dirty = true;
    inputsCtrl.newlyAdded = false;
  }

  function init() {
    inputsCtrl.editeurs = ($scope.initialValues() && $scope.initialValues().length !== 0) ? $scope.initialValues() : [{ nom: null, lieu: null}];
    inputsCtrl.dirty = false;
    inputsCtrl.newlyAdded = false;
  }
}
