(function(module) {
try {
  module = angular.module('app');
} catch (e) {
  module = angular.module('app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('common/biblio-file-input.html',
    '<div><input type=hidden name="{{ inputCtrl.name }}" ng-attr-form="{{ inputCtrl.form || undefined }}" value="{{ inputCtrl.file.id }}"><div class=input-group><input type=text class=form-control id="{{ inputCtrl.id }}" readonly value="{{ inputCtrl.file.name }}"> <span class=input-group-btn><a ng-href="{{ inputCtrl.file.uri }}" ng-disabled=!inputCtrl.hasFile() target=_blank class="btn btn-addon" title="Télécharger le fichier"><span class="fa fa-download"></span></a> <button type=button class="btn btn-addon" title="{{ inputCtrl.hasFile() ? \'Charger un autre fichier\' : \'Charger un fichier\' }}" ngf-select ng-disabled="inputCtrl.isDisabled() || inputCtrl.loading" ngf-multiple=false ngf-change="inputCtrl.upload($files, $file, $event, $rejectedFiles)"><span class="fa fa-upload"></span></button> <button type=button class="btn btn-addon" title=Annuler ng-if=inputCtrl.loading ng-click=inputCtrl.abort()><span class="fa fa-times text-warning"></span></button></span></div></div>');
}]);
})();
