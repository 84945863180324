(function(module) {
try {
  module = angular.module('app');
} catch (e) {
  module = angular.module('app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('common/app-notification-loading.html',
    '<strong><span class="icon-spinner icon-spin"></span></strong> Chargement en cours...');
}]);
})();
