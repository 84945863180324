angular.module('app')
  .directive('mediathequeIllustrationInput', IllustrationInputDirective);

function IllustrationInputDirective() {
  return {
    restrict: 'E',
    scope: {
      name: '@mediathequeName',
      id: '@mediathequeId',
      model: '=ngModel'
    },
    replace: true,
    controller: IllustrationInputController,
    controllerAs: 'inputCtrl',
    templateUrl: 'illustration/illustration-input.html'
  };
}

/*@ngInject*/
function IllustrationInputController($scope, EntitySuggest, $timeout) {
  var inputCtrl = this;

  inputCtrl.id = $scope.id;
  inputCtrl.name = $scope.name;

  inputCtrl.getSuggestions = getSuggestions;
  inputCtrl.hasValidValue = hasValidValue;
  inputCtrl.onChange = onChange;

  init();

  function hasValidValue() {
    if (!inputCtrl.model) {
      return true;
    }
    if (inputCtrl.model.illustrationId) {
      return true;
    }
    return inputCtrl.model.libelle === '' || inputCtrl.model.libelle === null;
  }

  inputCtrl.loading = false;
  function getSuggestions(currentValue) {
    inputCtrl.loading = true;
    return EntitySuggest.search('Illustration', currentValue)
      .then((result) => {
        inputCtrl.loading = false;
        return result.data;
      });
  }

  function onChange() {
    if (!inputCtrl.model || _.isString(inputCtrl.model)) {
      _.assign($scope.model, { illustrationId: null });
      return;
    }

    _.assign($scope.model, inputCtrl.model);
  }

  function init() {
    if ($scope.model) {
      inputCtrl.model = $scope.model;
      return;
    }
    inputCtrl.model = $scope.model = { libelle: '' };
  }
}
