angular.module('app')
  .directive('mediathequeDossiersRattaches', DossiersRattachesDirective);

function DossiersRattachesDirective() {
  return {
    restrict: 'E',
    replace: true,
    controller: DossiersRattachesController,
    controllerAs: 'dossiersRattachesCtrl',
    scope: {
      editable: '&mediathequeEditable',
      illustrationId: '@mediathequeIllustrationId',
      dossiers: '&mediathequeDossiers'
    },
    templateUrl: 'illustration/dossiers-rattaches.html'
  };
}

/*@ngInject*/
function DossiersRattachesController($scope, $rootScope, DossierRattacheEditModalService) {
  var dossiersRattachesCtrl = this;

  dossiersRattachesCtrl.editable = $scope.editable();
  dossiersRattachesCtrl.illustrationId = $scope.illustrationId;
  dossiersRattachesCtrl.dossiers = [];

  dossiersRattachesCtrl.edit = edit;

  $scope.$watch($scope.dossiers, (dossiers) => dossiersRattachesCtrl.dossiers = dossiers);

  function edit(dossier) {
    DossierRattacheEditModalService.editDossier(dossier.id, dossiersRattachesCtrl.illustrationId);
  }
}
