angular.module('app')
  .directive('mediathequeTabHash', TabHashDirective);

/*@ngInject*/
function TabHashDirective($rootScope, $timeout, LocationService) {
  return {
    restrict: 'A',
    link: function(scope, jqElement, attrs, tabsetCtrl) {
      var hash = attrs['mediathequeTabHash'];
      var isSelected = false;

      $timeout(() => {
        var currentHash = LocationService.getHash();

        if (!currentHash && jqElement.hasClass('active')) {
          $rootScope.$emit('location-hash-set', hash);
        }

        var anchor = angular.element(jqElement[0].querySelector('a[tab-heading-transclude]'));
        if (currentHash && currentHash === hash) {
          isSelected = true;
          anchor.triggerHandler('click');
        }
        anchor.on('click', () => {
          isSelected = true;
          $rootScope.$emit('location-hash-set', hash);
        });
        scope.$on('location-hash-changed', () => {
          if (LocationService.getHash() === hash) {
            if (!isSelected) {
              isSelected = true;
              anchor.triggerHandler('click');
            }
          } else {
            isSelected = false;
          }
        });
      }, 0, false);
    }
  };
}
