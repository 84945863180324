angular.module('app')
  .directive('mediathequeMultivaluedTextInput', MultivaluedTextInputDirective);

function MultivaluedTextInputDirective() {
  return {
    restrict: 'E',
    scope: {
      name: '@mediathequeName',
      id: '@mediathequeId',
      placeholder: '@mediathequePlaceholder',
      addElementTitle: '@mediathequeAddElementTitle',
      removeElementTitle: '@mediathequeRemoveElementTitle',
      form: '@mediathequeForm',
      cssClasses: '@mediathequeClass',
      inputClass: '@mediathequeInputClass',
      elementsPerRow: '&mediathequeElementsPerRow',
      inputGroupClass: '@mediathequeInputGroupClass',
      prefix: '@mediathequePrefix',
      disabled: '&mediathequeDisabled',
      fieldPath: '@mediathequeFieldPath',
      entityType: '@mediathequeEntityType',
      notEmpty: '&mediathequeNotEmpty',
      initialValues: '&mediathequeInitialValues',
      model: '=ngModel',
      onChange: '&mediathequeOnChange'
    },
    replace: true,
    controller: MultivaluedTextInputController,
    controllerAs: 'inputsCtrl',
    templateUrl: 'common/multivalued-text-input.html'
  };
}

/*@ngInject*/
function MultivaluedTextInputController($scope, $timeout) {
  var inputsCtrl = this;

  inputsCtrl.dirty = false;

  inputsCtrl.onChange = $scope.onChange;

  inputsCtrl.inputSize = inputSize;
  inputsCtrl.oneElementPerRow = oneElementPerRow;
  inputsCtrl.addValue = addValue;
  inputsCtrl.removeValue = removeValue;

  init();

  $scope.$watch(() => $scope.model, () => $timeout(init, 0));

  function addValue() {
    $scope.model.push({});
    inputsCtrl.dirty = true;
    inputsCtrl.newlyAdded = true;
  }

  function removeValue(valueToRemove) {
    $scope.model = _.without($scope.model, valueToRemove);

    // Il faut au moins un champs affiché
    if ($scope.notEmpty() && $scope.model.length === 0) {
      $scope.model = [{ libelle:"" }];
    }
    inputsCtrl.dirty = true;
    inputsCtrl.newlyAdded = false;
  }

  function inputSize() {
    var elementsPerRow = $scope.elementsPerRow() || 1;
    var defaultSize = $scope.disabled() ? 12 : 10;

    return Math.ceil(defaultSize / elementsPerRow);
  }

  function oneElementPerRow() {
    return $scope.elementsPerRow() === 1;
  }

  function init() {
    inputsCtrl.newlyAdded = false;

    if ($scope.model) {
      if ($scope.notEmpty() && $scope.model.length === 0) {
        $scope.model = [{ libelle:"" }];
      }
      return;
    }
    if (! $scope.initialValues() || _.isEmpty($scope.initialValues())) {
      if ($scope.notEmpty()) {
        $scope.model = [{ libelle:"" }];
      } else {
        $scope.model = [];
      }
      return;
    }

    $scope.model = _.map($scope.initialValues(), (value) => {
      return { libelle: value };
    });
  }
}
