angular.module('app')
  .factory('ThesaurusSuggest', ThesaurusSuggest);

/*@ngInject*/
function ThesaurusSuggest($http, $q, SuggestApiConfiguration) {
  return { search };

  function search(query, typesThesaurus) {

    return $http({
      method: 'GET',
      cache: true,
      url: SuggestApiConfiguration.thesaurusUri,
      params: {
        'query': query,
        'typesThesaurus': typesThesaurus
      }
    });
  }
}
