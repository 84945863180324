angular.module('app')
  .directive('mediathequeDatepickerInput', DatepickerInputDirective);

function DatepickerInputDirective() {
  return {
    restrict: 'E',
    scope: {
      name: '@mediathequeName',
      id: '@mediathequeId',
      form: '@mediathequeForm',
      model: '=ngModel',
      onChange: '&mediathequeOnChange',
      disabled: '&mediathequeDisabled',
      placeholder: '@mediathequePlaceholder',
      options: '@mediathequeDatepickerOptions'
    },
    replace: true,
    controller: DatepickerInputController,
    controllerAs: 'inputCtrl',
    templateUrl: 'common/datepicker-input.html'
  };
}

/*@ngInject*/
function DatepickerInputController($scope) {
  var inputCtrl = this;

  inputCtrl.openDatepicker = openDatepicker;
  inputCtrl.onChange = $scope.onChange;

  init();

  // TODO: Ajouter un watcher sur $scope.model (couplé à un $timeout pour s'assurer que le scope est bien à jour)
  // si le model initial est modifié par le composant parent

  function init() {
    inputCtrl.opened = false;
  }

  function openDatepicker($event) {
    $event.preventDefault();
    $event.stopPropagation();
    inputCtrl.opened = true;
  }
}
