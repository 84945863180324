angular.module('app')
  .factory('RattachementDossierModalService', RattachementDossierModalService);

/*@ngInject*/
function RattachementDossierModalService($modal) {
  return {
    rattacherDossier: rattacherDossier
  };

  function rattacherDossier(illustrationId) {
    return $modal.open({
      animation: true,
      size: 'lg',
      templateUrl: 'illustration/rattachement-dossier-modal.html',
      controller: RattachementDossierModalCtrl,
      controllerAs: 'rattachementDossierModalCtrl',
      resolve: { illustrationId: () => illustrationId }
    }).result;
  }
}

function RattachementDossierModalCtrl($modalInstance, illustrationId, DossiersRattachesService) {
  var rattachementDossierModalCtrl = this;

  rattachementDossierModalCtrl.submit = submit;
  rattachementDossierModalCtrl.cancel = cancel;

  rattachementDossierModalCtrl.dossier = { libelle: '' };
  rattachementDossierModalCtrl.updateIllustration = false;
  rattachementDossierModalCtrl.error = null;

  function submit() {
    rattachementDossierModalCtrl.error = null;
    rattachementDossierModalCtrl.closing = true;
    DossiersRattachesService.rattacher(illustrationId, rattachementDossierModalCtrl.dossier.id, rattachementDossierModalCtrl.updateIllustration)
      .then(() => {
        rattachementDossierModalCtrl.closing = false;
        $modalInstance.close();
      }, (error) => {
        rattachementDossierModalCtrl.closing = false;
        rattachementDossierModalCtrl.error = error;
      });
  }

  function cancel() {
    $modalInstance.dismiss();
  }
}
