angular.module('app')
  .directive('mediathequeAutofocus', AutofocusDirective);

/*@ngInject*/
function AutofocusDirective($parse, $timeout) {
  return {
    restrict: 'A',
    link: function(scope, element, attrs) {
      var autofocusExprGetter = $parse(attrs['mediathequeAutofocus']);

      // Le watcher est utile dans le cas où l'élément peut être caché et réaffiché (comme dans un élément collapse)
      scope.$watch(() => autofocusExprGetter(scope), () => {
        if (autofocusExprGetter(scope)) {
          // On attend que l'élément soit bien affiché
          $timeout(() => element[0].focus(), 0);
        }
      });
    }
  };
}
