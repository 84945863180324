angular.module('app')
  .factory('TasksService', TasksService);

/*@ngInject*/
function TasksService($rootScope, initData) {
  var tasks = _.zipObject(_.map(initData.tasks, t => t.id), _.fill(Array(initData.tasks.length), false));

  $rootScope.$on('tasks-select', handleSelectedTask);
  $rootScope.$on('tasks-unselect', handleUnselectedTask);
  $rootScope.$on('tasks-select-all', handleAllSelectedTasks);
  $rootScope.$on('tasks-unselect-all', handleAllUnselectedTasks);

  return { getSelected, areAllSelected, isAtLeastOneSelected };

  function getSelected(taskId) {
    return tasks[taskId];
  }

  function handleSelectedTask(event, taskId) {
    tasks[taskId] = true;
    $rootScope.$broadcast('tasks-select-changed');
  }

  function handleUnselectedTask(event, taskId) {
    tasks[taskId] = false;
    $rootScope.$broadcast('tasks-select-changed');
  }

  function handleAllSelectedTasks(event) {
    tasks = _.zipObject(_.map(initData.tasks, t => t.id), _.fill(Array(initData.tasks.length), true));
    $rootScope.$broadcast('tasks-select-changed');
  }

  function handleAllUnselectedTasks(event) {
    tasks = _.zipObject(_.map(initData.tasks, t => t.id), _.fill(Array(initData.tasks.length), false));
    $rootScope.$broadcast('tasks-select-changed');
  }

  function areAllSelected() {
    return _.every(_.values(tasks), t => t === true);
  }

  function isAtLeastOneSelected() {
    return _.some(_.values(tasks), t => t === true);
  }
}
