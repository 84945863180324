angular.module('app')
  .directive('mediathequeDefaultChecked', DefaultCheckedDirective);

/*@ngInject*/
function DefaultCheckedDirective() {
  return {
    restrict: 'A',
    link: function(scope, element, attrs) {
      // The scope parameter is not used here in order to not interfere with another directive with it own scope (like typeahead)
      var defaultChecked = attrs.mediathequeDefaultChecked;
      // element variable is a JQLite instance defined as an array of DOM elements
      _.forEach(element, (input) => {
        // Seuls les champs de type radio et checkbox sont gérés par cette directive.
        input.defaultChecked = (defaultChecked === 'true');
      });
    }
  };
}
