angular.module('app')
  .factory('DossierRattacheEditModalService', DossierRattacheEditModalService);

/*@ngInject*/
function DossierRattacheEditModalService($modal, DossiersRattachesService) {
  return { editDossier };

  function editDossier(dossierId, illustrationId) {
    return $modal.open({
      animation: true,
      size: 'lg',
      templateUrl: 'illustration/dossier-rattache-edit-modal.html',
      controller: DossierRattacheEditModalCtrl,
      controllerAs: 'dossierRattacheEditModalCtrl',
      resolve: {
        initialDossier: () => {
          return DossiersRattachesService.lockAndGet(dossierId, illustrationId);
        },
        illustrationId: () => illustrationId
      }
    }).result;
  }
}

function DossierRattacheEditModalCtrl($scope, $modalInstance, initialDossier, illustrationId, DossiersRattachesService) {
  var dossierRattacheEditModalCtrl = this;

  dossierRattacheEditModalCtrl.submit = submit;
  dossierRattacheEditModalCtrl.remove = remove;
  dossierRattacheEditModalCtrl.cancel = cancel;
  dossierRattacheEditModalCtrl.canEdit = canEdit;
  dossierRattacheEditModalCtrl.notProcessing = notProcessing;
  dossierRattacheEditModalCtrl.isDossierLocked = isDossierLocked;

  dossierRattacheEditModalCtrl.dossier = _.cloneDeep(initialDossier.dossier || {});

  $scope.$on('modal.closing', (event, reason, closed) => {
    if (closed || !canEdit() || dossierRattacheEditModalCtrl.fullyCancelled) {
      return;
    }

    // Il faut veiller à ce que le dossier soit correctement annulé
    event.preventDefault();

    cancel();
  });

  function canEdit() {
    return initialDossier.editable;
  }

  function isDossierLocked() {
    return dossierRattacheEditModalCtrl.dossier.locked;
  }

  function notProcessing() {
    return !dossierRattacheEditModalCtrl.dismissing && !dossierRattacheEditModalCtrl.closing;
  }

  function submit() {
    dossierRattacheEditModalCtrl.closing = true;
    dossierRattacheEditModalCtrl.error = null;
    DossiersRattachesService.edit(dossierRattacheEditModalCtrl.dossier, illustrationId)
      .then(() => {
        dossierRattacheEditModalCtrl.closing = false;
        $modalInstance.close();
      }, (error) => {
        dossierRattacheEditModalCtrl.closing = false;
        dossierRattacheEditModalCtrl.error = error;
      });
  }

  function remove() {
    dossierRattacheEditModalCtrl.closing = true;
    dossierRattacheEditModalCtrl.error = null;

    DossiersRattachesService.remove(initialDossier.dossier.id, illustrationId, initialDossier.dossier.version)
      .then(() => {
        dossierRattacheEditModalCtrl.closing = false;
        $modalInstance.close();
      }, (error) => {
        dossierRattacheEditModalCtrl.closing = false;
        dossierRattacheEditModalCtrl.error = error;
      })
  }

  function cancel() {
    if (!canEdit()) {
      $modalInstance.dismiss();
      return;
    }

    dossierRattacheEditModalCtrl.dismissing = true;
    dossierRattacheEditModalCtrl.error = null;

    DossiersRattachesService.cancel(initialDossier.dossier.id)
      .then(() => {
        dossierRattacheEditModalCtrl.fullyCancelled = true;
        $modalInstance.dismiss();
      }, (error) => {
        dossierRattacheEditModalCtrl.dismissing = false;
        dossierRattacheEditModalCtrl.error = error;
        dossierRattacheEditModalCtrl.error.cancel = true;
      })
  }
}
