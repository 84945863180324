(function(module) {
try {
  module = angular.module('app');
} catch (e) {
  module = angular.module('app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('common/select-popup.html',
    '<ul class=dropdown-menu ng-show="isOpen() && !moveInProgress" ng-style="{top: position().top+\'px\', left: position().left+\'px\'}" style="display: block;" role=listbox aria-hidden={{!isOpen()}}><li ng-repeat="match in matches track by $index" ng-class="{active: isActive($index) }" ng-mouseenter=selectActive($index) ng-click=selectMatch($index) role=option id={{::match.id}}><div ng-if="! $first && match.model.groupe != matches[$index-1].model.groupe" class=select-separator></div><div typeahead-match index=$index match=match query=query template-url=templateUrl></div></li></ul>');
}]);
})();
